import { useState } from "react";

import { UUID } from "crypto";

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useFetchTasks from "../../../../hooks/getBackgroundTaskStatus";

interface ListPendingDocumentsI {
  collection_id: UUID | undefined;
}

const ListPendingDocuments = ({ collection_id }: ListPendingDocumentsI) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const { backgroundTasks } = useFetchTasks(`${window.REACT_APP_API_ENDPOINT}/api/document/ingest-status/collection/${collection_id}`, openCollapse);

  const statusTextColor = (status: string) => {
    if (status === 'PENDING') return 'text-yellow-400';
    if (status === 'SUCCESS') return 'text-green-400';
    return 'text-red-400';
  }

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <div className="my-4 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Documentos pendentes</h3>
            </div>
            <div>
              <IconButton onClick={handleCollapse}>
                <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openCollapse}>
            <span className="text-base font-normal text-gray-500">Listagem dos documentos que estão na fila para serem ingeridos</span>

            <div className="flex flex-col mt-8">

              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Background Task Id
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Documento
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Error
                          </th>
                          <th scope="col" className="tracking-wider" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {backgroundTasks?.running_tasks !== undefined ? backgroundTasks?.running_tasks.length !== 0 ? backgroundTasks?.running_tasks.map((document, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className={`p-4 whitespace-nowrap text-sm font-normal ${statusTextColor(document.status)}`}>
                              {document.status}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {document.task_id}
                            </td>
                            <td className={`p-4 whitespace-nowrap text-sm font-normal`}>
                              {typeof document.document_name === 'string' ? document.document_name : null}
                            </td>
                            <td className={`p-4 whitespace-nowrap text-sm font-normal`}>
                              {document.status === 'ERROR' && document.error ? document.error : document.status === 'FAILURE' && document.exc ? document.exc : null}
                            </td>
                          </tr>
                        )) :
                          <tr>
                            <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                              <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690257804/jjqw2hfv0t6karxdeq1s.svg" alt="empty states" />
                              <p className="text-gray-700 font-medium text-lg text-center">Nenhum documento em espera.</p>
                              <p className="text-gray-500 text-center">Documento aparecerá aqui se ele estiver sendo ingerido ou se ele já concluiu ou falhou o processo.</p>
                            </td>
                          </tr> : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default ListPendingDocuments;
