import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from '../../../contexts/authContext';

import { Toggle } from '../../../components/Toogle';

import { toastErrors } from '../../../utils/toastErrors';
import { LoadingDots } from '../../../components/LoadingDots';
import { ConfirmationModal } from '../../../components';

import { ClientInterface } from "../../../utils/types";
import { formatDateTime } from '../../../utils/formatDate';
import { formatPhoneNumber } from '../../../utils/formatPhonenumber';

interface ClientsFormInterface {
  client?: ClientInterface,
  formName: string
}

const ClientsForm = ({ client, formName }: ClientsFormInterface) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [isEditting] = useState<boolean>(formName === 'Editar');

  const [twilioAccountSid, setTwilioAccountSid] = useState(client?.twilio_account_sid);
  const [twilioAuthToken, setTwilioAuthToken] = useState(client?.twilio_auth_secret);
  const [twilioMessagingServiceSid, setTwilioMessagingServiceSid] = useState(client?.twilio_messaging_service_sid);
  const [twilioFlexUrl, setTwilioFlexUrl] = useState(client?.twilio_flex_url);

  const [phoneNumber, setPhoneNumber] = useState(client?.phone_number);
  const [metaNumberId, setMetaNumberId] = useState(client?.meta_number_id);

  const [metabaseUrl, setMetabaseUrl] = useState(client?.metabase_url);

  const [name, setName] = useState(client?.name);
  const [description, setDescription] = useState(client?.description);
  const [email, setEmail] = useState(client?.email);
  const [enabled, setEnabled] = useState(isEditting ? client!.enabled : true);

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [openCollapse, setOpenCollapse] = useState(isEditting && (client?.twilio_account_sid || client?.twilio_auth_secret) ? true : false);
  const [openMetricsCollapse, setOpenMetricsCollapse] = useState(isEditting && client?.metabase_url ? true : false);
  const [openMetaCollapse, setOpenMetaCollapse] = useState(isEditting && (client?.phone_number && client.meta_number_id) ? true : false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/clients">Clientes</Link>,
    <Typography key="3" color="text.primary">{isEditting ? client?.name : 'Cadastrar client'}</Typography>
  ];

  const formatPhoneNumberEdit = (phone: string) => {
    let formatted = phone.replace(/[^0-9+]/g, '');
    if (formatted.length === 10 || formatted.length === 11) {
      return formatPhoneNumber(formatted);
    }
    return phone; // Return original if it doesn't match expected format
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedNumber);
  };

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client/${client?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/clients');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    if ((metaNumberId && !phoneNumber) || (!metaNumberId && phoneNumber)) {
      toast.update(toastId, {
        render: 'Telefone e ID do número devem ser cadastrados juntos.',
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true
      });
      setLoading(false);
      return;
    } 

    const form = {
      twilio_account_sid: twilioAccountSid,
      twilio_auth_secret: twilioAuthToken,
      twilio_messaging_service_sid: twilioMessagingServiceSid,
      twilio_flex_url: twilioFlexUrl,
      phone_number: phoneNumber ? `${phoneNumber?.includes("+55") ? '' : '+55'}${phoneNumber?.replace(/[^0-9+]/g, '')}` : null,
      meta_number_id: metaNumberId,
      client_phone_number_id: client?.client_phone_number_id,
      metabase_url: metabaseUrl,
      name,
      email,
      description,
      enabled
    };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client${isEditting ? `/${client?.id}` : ''}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/clients');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleMetricsCollapse = () => {
    setOpenMetricsCollapse(!openMetricsCollapse);
  };

  const handleMetaCollapse = () => {
    setOpenMetaCollapse(!openMetaCollapse);
  };

  return (
    <div className="pt-6 px-4">

      <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      <div className="py-4 md:flex items-center justify-between">
        <div className="w-full md:w-1/2 flex flex-col">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">{formName} cliente</h3>
          <span className="text-base font-normal text-gray-500">{`Certifique-se de que todos os dados estão corretos antes de ${formName.toLowerCase()}.`}</span>
        </div>

        <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
          <Toggle enabled={enabled} setEnabled={(newVal) => setEnabled(newVal)} />
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Configurações Twilio</h3>
            </div>
            <div>
              <IconButton onClick={handleCollapse}>
                <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openCollapse}>
            <div className="flex flex-col mt-8 pb-6">
              <div className="align-middle inline-block min-w-full">

                <div className="md:flex items-center">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <label className="font-semibold leading-none">Twilio Account Sid</label>
                    <input
                      type="text"
                      maxLength={56}
                      className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                      value={!twilioAccountSid ? '' : twilioAccountSid}
                      onChange={e => setTwilioAccountSid(e.target.value)}
                    />
                  </div>

                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-8 md:mt-0">
                    <label className="font-semibold leading-none">Twilio Auth Token</label>
                    <input
                      type="text"
                      maxLength={56}
                      className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                      value={!twilioAuthToken ? '' : twilioAuthToken}
                      onChange={e => setTwilioAuthToken(e.target.value)}
                    />
                  </div>
                </div>

                <div className="md:flex items-center mt-8">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <label className="font-semibold leading-none">Twilio Messaging Service Sid</label>
                    <input
                      type="text"
                      maxLength={56}
                      className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                      value={!twilioMessagingServiceSid ? '' : twilioMessagingServiceSid}
                      onChange={e => setTwilioMessagingServiceSid(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-8 md:mt-0">
                    <label className="font-semibold leading-none">Twilio Flex URL</label>
                    <input
                      type="text"
                      className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                      value={!twilioFlexUrl ? '' : twilioFlexUrl}
                      onChange={e => setTwilioFlexUrl(e.target.value)}
                    />
                  </div>
                </div>

              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Configurações Meta</h3>
            </div>
            <div>
              <IconButton onClick={handleMetaCollapse}>
                <ExpandMoreIcon className={openMetaCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openMetaCollapse}>
            <div className="flex flex-col mt-8 pb-6">
              <div className="align-middle inline-block min-w-full">

                <div className="md:flex items-center md:mr-6">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <label className="font-semibold leading-none">Meta Number Id</label>
                    <input
                      type="text"
                      maxLength={56}
                      className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                      value={!metaNumberId ? '' : metaNumberId}
                      onChange={e => setMetaNumberId(e.target.value)}
                    />
                  </div>

                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-8 md:mt-0">
                    <div className="flex flex-col">
                      <label className="font-semibold leading-none">Telefone</label>
                      <div className="flex mt-4">
                        <input
                          type="text"
                          value="+55"
                          className="w-1/6 flex flex-col leading-none text-gray-900 p-3 shadow bg-gray-200 cursor-not-allowed border rounded border-gray-200 rounded-r-none"
                          disabled
                        />
                        <input
                          type="text"
                          maxLength={16}
                          className={`w-full md:w-5/6 flex flex-col leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 border rounded border-gray-200 rounded-l-none`}
                          value={!phoneNumber ? '' : formatPhoneNumberEdit(phoneNumber.replace('+55', ''))}
                          onChange={handlePhoneNumberChange}
                          placeholder="(xx) 9xxxx-xxxx"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Métricas</h3>
            </div>
            <div>
              <IconButton onClick={handleMetricsCollapse}>
                <ExpandMoreIcon className={openMetricsCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openMetricsCollapse}>
            <div className="flex flex-col mt-8 pb-6">
              <div className="align-middle inline-block min-w-full">

                <div className="md:flex items-center">
                  <div className="w-full flex flex-col">
                    <div className="flex flex-col">
                      <label className="font-semibold leading-none">URL Metabase</label>
                      <input
                        type="text"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!metabaseUrl ? '' : metabaseUrl}
                        onChange={e => setMetabaseUrl(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 pb-6 xl:p-8">
          <div className="md:flex items-center justify-between">
            <div className="w-full md:w-1/2 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900">Informações gerais</h3>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">

                  <ConfirmationModal action={action} entity='cliente' open={open} setOpen={setOpen} handleAction={action === 'Deletar' ? handleDelete : handleForm} />

                  <div className="md:flex items-center mt-3">
                    <div className="w-full md:w-1/2 flex flex-col">
                      <div className="flex flex-col">
                        <label className="font-semibold leading-none">Nome</label>
                        <input
                          type="text"
                          className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                          value={!name ? '' : name}
                          onChange={e => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-8 md:mt-0">
                      <label className="font-semibold leading-none">Email</label>
                      <input
                        type="email"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!email ? '' : email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Descrição</label>
                      <textarea
                        className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!description ? '' : description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  {isEditting ? <div className="mt-4">
                    <span className="text-sm opacity-50">Última atualização: {client?.updated_at ? formatDateTime(client?.updated_at!) : 'não teve atualização!'}</span>
                  </div> : null}

                  <div className="flex items-center justify-end w-full">
                    {isEditting ? <button type="submit" onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none" disabled={loading}>Deletar</button> : null}

                    <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                      {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ClientsForm;