import MetricsForm from "./Form";
import { useLocation } from 'react-router-dom';

const EditMetric = () => {
  const location = useLocation()
  const { metric } = location.state

  return (
    <MetricsForm metric={metric} formName="Editar" />
  )
}

export default EditMetric;
