import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/authContext';

interface BackgroundTask {
  running_tasks: RunningTask[] | [];
}

enum TaskStatus {
  PENDING = "PENDING",
  ERROR = "ERROR",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS"
}

interface RunningTask {
  task_id: string;
  status: TaskStatus;
  collection_id: string;
  document_name: string;
  error?: string;
  exc?: string;
}

const useFetchTasks = (url: string, shouldFetch: boolean) => {
  const { token } = useAuth();

  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask>();
  const [error, setError] = useState<null | Error>(null);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchTasks = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        setBackgroundTasks(data);
      } catch (error: any) {
        setError(error);
      }
    };

    if (shouldFetch && token) {
      fetchTasks(); // Initial call
      intervalId = setInterval(fetchTasks, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [url, token, shouldFetch]);

  return { backgroundTasks, error };
};

export default useFetchTasks;
