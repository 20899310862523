import ClientsForm from "./Form";
import { useLocation } from 'react-router-dom';

const EditClient = () => {
  const location = useLocation()
  const { client } = location.state

  return (
    <ClientsForm client={client} formName="Editar" />
  )
}

export default EditClient;
