import * as React from 'react';
import { ReactNode, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
// import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';

import { useAuth } from '../contexts/authContext';
import { useAdmin } from '../contexts/adminContext';
import { useArea } from '../contexts/areaContext';
import LogoutButton from './Auth0LogoutBtn';
import CustomListbox from './CustomListbox';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'white',
  color: "black",
  boxShadow: 'unset',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type Area = 'backoffice' | 'platform';

interface AreaInterface {
  id: string;
  name: string;
  value: Area;
}

interface AppDrawerI {
  children: ReactNode;
}

const AppDrawer = ({ children }: AppDrawerI) => {
  const theme = useTheme();
  const { isAuthenticated, logout, user } = useAuth0();
  const { isAdmin } = useAdmin();
  const { currentArea, switchArea } = useArea();
  const location = useLocation();

  const [areaList] = useState<AreaInterface[]>([{ id: '1', name: 'Backoffice', value: 'backoffice' }, { id: '2', name: 'Plataforma', value: 'platform' }]);
  const [selectedArea, setSelectedArea] = useState<AreaInterface | null>(
    currentArea ? areaList.find(areaL => areaL.value === currentArea) || null : null
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const primaryLinks = [
    {
      text: 'Home',
      icon: <HomeOutlinedIcon fontSize='medium' />,
      link: '/',
      admin: false,
      area: 'commom'
    },
    {
      text: 'Disparo ativo',
      icon: <CampaignOutlinedIcon fontSize='medium' />,
      link: '/active-messages',
      admin: false,
      area: 'platform'
    },
    {
      text: 'Atendimento',
      icon: <MarkUnreadChatAltOutlinedIcon fontSize='medium' />,
      link: '/contact-center',
      admin: false,
      area: 'platform'
    },
    {
      text: 'Conversas',
      icon: <HistoryOutlinedIcon fontSize='medium' />,
      link: '/conversations',
      admin: false,
      area: 'platform'
    },
    {
      text: 'Clientes',
      icon: <GroupOutlinedIcon fontSize='medium' />,
      link: '/clients',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Collections',
      icon: <FileCopyOutlinedIcon fontSize='medium' />,
      link: '/collections',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Pipelines',
      icon: <AssignmentOutlinedIcon fontSize='medium' />,
      link: '/pipelines',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Modelos LLM',
      icon: <IntegrationInstructionsOutlinedIcon fontSize='medium' />,
      link: 'llm-models',
      admin: true,
      area: 'backoffice'
    }
  ]

  const secondaryLinks = [
    {
      text: 'Evaluations',
      icon: <PestControlOutlinedIcon fontSize='medium' />,
      link: '/evaluations',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Métricas',
      icon: <QueryStatsOutlinedIcon fontSize='medium' />,
      link: '/metrics',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Conversas',
      icon: <HistoryOutlinedIcon fontSize='medium' />,
      link: '/bo-conversations',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Doc. API',
      icon: <DnsOutlinedIcon fontSize='medium' />,
      link: 'https://api.arkspace-api.com/api/docs',
      admin: true,
      area: 'backoffice'
    },
    {
      text: 'Configurações',
      icon: <SettingsOutlinedIcon fontSize='medium' />,
      link: '/settings',
      admin: false,
      area: 'platform'
    },
    {
      text: 'Ajuda',
      icon: <HelpCenterOutlinedIcon fontSize='medium' />,
      link: '/help',
      admin: false,
      area: 'platform'
    },
    {
      text: 'Sair',
      icon: <LogoutOutlinedIcon fontSize='medium' />,
      link: '/logout',
      admin: false,
      area: 'commom'
    }
  ]

  React.useEffect(() => {
    if (isAuthenticated) { switchArea(selectedArea!.value); }
  }, [selectedArea]);

  React.useEffect(() => {
    if (selectedArea?.value !== currentArea) {
      const area = currentArea ? areaList.find(areaL => areaL.value === currentArea) || null : null
      setSelectedArea(area);
    }
  }, [currentArea]);

  const handleClickSwitchArea = () => {
    if (currentArea === 'backoffice') {
      setSelectedArea(areaList[1]);
      return switchArea('platform');
    };

    setSelectedArea(areaList[0]);
    return switchArea('backoffice');
  }

  const displayLink = (itemAdmin: boolean) => {
    if (!itemAdmin) return true;
    if (itemAdmin && !isAdmin) return false;

    return true;
  }

  const displayArea = (itemArea: string) => {
    if (itemArea === 'commom') return true;
    if (itemArea === currentArea) return true;

    return false;
  }

  const displayBackgroundColor = (itemText: string, itemLocation: string) => {
    if (itemText === 'Home' && itemLocation === location.pathname) return 'rgba(0, 0, 0, 0.1)';
    if (itemLocation !== '/' && itemLocation === location.pathname) return 'rgba(0, 0, 0, 0.1)';
    if (itemLocation !== '/' && location.pathname.includes(itemLocation)) return 'rgba(0, 0, 0, 0.1)';
  }

  const { idTokenClaims } = useAuth();

  const adminProtection = currentArea === 'backoffice' && !isAdmin;
  const platformProtect = currentArea === 'platform' && !idTokenClaims?.client_id;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ '&.MuiToolbar-root': { display: 'grid', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } }}>
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              {!isAuthenticated || adminProtection || platformProtect ? null : <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 2,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>}
              <a href="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                <img src="/assets/brand.png" className="h-6 mr-2" alt="Arkspace Logo" />
                <span className="self-center whitespace-nowrap">Chatspace {isAuthenticated && isAdmin && currentArea === 'backoffice' ? '- BO' : ''}</span>
              </a>
            </div>
            <div className="flex items-center hidden sm:inline-flex">
              {isAdmin ?
                <div className='mr-3 w-40'>
                  <CustomListbox className='py-[unset] mt-auto h-10' list={areaList} selected={selectedArea} setSelected={setSelectedArea} placeholder='Selecione uma área' />
                </div>
                : null
              }
              {isAuthenticated ? <LogoutButton /> : null}
            </div>
            <div className="flex items-center sm:hidden">
              {isAdmin ? <button onClick={handleClickSwitchArea}>
                <CompareArrowsOutlinedIcon fontSize='medium' />
              </button> : null}
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {!isAuthenticated || adminProtection || platformProtect ? null :
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          {open ? <Divider sx={{ '&.MuiDivider-root': { marginTop: '-1px' } }} /> : null}
          <Box display="flex" flexDirection="column" height="100%">
            <List>
              {primaryLinks.map((item, index) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block', backgroundColor: displayBackgroundColor(item.text, item.link) }}>

                  {displayLink(item.admin) ?
                    displayArea(item.area) ?
                      <Link to={item.link}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              color: 'black'
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={<span className="text-base font-['Lexend']">{item.text}</span>} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </Link>
                      : null
                    : null}
                </ListItem>
              ))}
            </List>
            <Divider />
            <List sx={{ flexGrow: 1 }}>
              {secondaryLinks.map((item, index) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block', backgroundColor: displayBackgroundColor(item.text, item.link) }}>

                  {displayLink(item.admin) ?
                    displayArea(item.area) ?
                      <Link to={item.link} onClick={() => {
                        if (item.text === 'Sair') {
                          localStorage.removeItem("currentArea");
                          logout({ logoutParams: { returnTo: window.location.origin } });
                        }
                      }} target={`${item.text === 'Doc. API' ? '_blank' : '_self'}`} rel={`${item.text === 'Doc. API' ? 'noopener noreferrer' : undefined}`}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              color: 'black'
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={<span className="text-base font-['Lexend']">{item.text}</span>} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </Link>
                      : null
                    : null}

                </ListItem>
              ))}
            </List>

            <List>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      cursor: 'default'
                    },
                    '&:focus-visible': {
                      backgroundColor: 'transparent',
                      cursor: 'default'
                    },
                    '&:focus-within': {
                      backgroundColor: 'transparent',
                      cursor: 'default'
                    },
                  }}
                >
                  <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'black',
                    opacity: '1'
                  }}
                  >
                    <img className='rounded-3xl h-6 w-6 opacity-1' src={user?.picture} alt='user-profile' />
                  </ListItemIcon>
                  {/* <ListItemText primary={<span className={`text-base font-['Lexend'] ${open ? 'truncate opacity-1' : 'opacity-0'}`}>{user?.name}</span>} sx={{ opacity: open ? 1 : 0 }} /> */}
                  <span className={`text-base font-['Lexend'] ${open ? 'truncate' : 'hidden'}`}>{user?.name}</span>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>

        </Drawer>
      }
      <Box component="main" sx={{ flexGrow: 1, p: 3, '&.MuiBox-root': { padding: "unset" } }}>
        <DrawerHeader />

        {children}
      </Box>
    </Box >
  );
}

export default AppDrawer;
