import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Alert from "@mui/material/Alert";

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';

import { CustomListbox } from "../../../../components";
import { LoadingDots } from '../../../../components/LoadingDots';

import { toastErrors } from '../../../../utils/toastErrors';
import { useAuth } from "../../../../contexts/authContext";

const whatsappVerticals = [
  {
    id: '1',
    name: 'Automotivo',
    value: 'AUTO'
  },
  {
    id: '2',
    name: 'Beleza, spa e salão de beleza',
    value: 'BEAUTY'
  },
  {
    id: '3',
    name: 'Roupas e acessórios',
    value: 'APPAREL'
  },
  {
    id: '4',
    name: 'Educação',
    value: 'EDU'
  },
  {
    id: '5',
    name: 'Entretenimento',
    value: 'ENTERTAIN'
  },
  {
    id: '6',
    name: 'Serviços e planejamento de eventos',
    value: 'EVENT_PLAN'
  },
  {
    id: '7',
    name: 'Finanças e bancos',
    value: 'FINANCE'
  },
  {
    id: '8',
    name: 'Alimentação e mercearia',
    value: 'GROCERY'
  },
  {
    id: '9',
    name: 'Serviço público',
    value: 'GOVT'
  },
  {
    id: '10',
    name: 'Hotel e alojamento',
    value: 'HOTEL'
  },
  {
    id: '11',
    name: 'Medicina e saúde',
    value: 'HEALTH'
  },
  {
    id: '12',
    name: 'Organização sem fins lucrativos',
    value: 'NONPROFIT'
  },
  {
    id: '13',
    name: 'Serviços profissionais',
    value: 'PROF_SERVICES'
  },
  {
    id: '14',
    name: 'Compras e varejo',
    value: 'RETAIL'
  },
  {
    id: '15',
    name: 'Viagem e transporte',
    value: 'TRAVEL'
  },
  {
    id: '16',
    name: 'Restaurante',
    value: 'RESTAURANT'
  },
  {
    id: '17',
    name: 'Outro',
    value: 'OTHER'
  }
]

interface CategoryInterface {
  id: string,
  name: string,
  value: string
}

const WhatsappSettings = () => {
  const location = useLocation()
  const { token } = useAuth();

  const { whatsappInfo } = location.state;
  const profileInfo = whatsappInfo.profile_info[0];

  const [name] = useState(whatsappInfo.number_info.verified_name);
  const [phoneNumber] = useState(whatsappInfo.number_info.display_phone_number);
  const [profilePicture] = useState(profileInfo.profile_picture_url);
  const [profilePictureHandle, setProfilePictureHandle] = useState('');
  const [email, setEmail] = useState(profileInfo.email);
  const [address, setAddress] = useState(profileInfo.address);
  const [website1, setWebsite1] = useState(profileInfo.websites[0]);
  const [website2, setWebsite2] = useState(profileInfo.websites[1]);
  const [description, setDescription] = useState(profileInfo.description);

  const [categoryList] = useState<CategoryInterface[]>(whatsappVerticals);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface | null>(
    profileInfo.vertical ? categoryList.find(categoryL => categoryL.value === profileInfo.vertical) || null : null
  );

  const [showWebsite2, setShowWebsite2] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/settings/integrations">Integrações</Link>,
    <Typography key="2" color="text.primary">Whatsapp</Typography>
  ];

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/phone-numbers/upload-profile-picture`, {
      headers: { 'Authorization': `Bearer ${token}` },
      method: 'POST',
      body: formData
    });

    const data = await response.json();

    if (response.ok) {
      toast.update(toastId, {
        render: 'Upload concluído!',
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true
      });
      setLoading(false);
      setProfilePictureHandle(data.handle);
    } else {
      toastErrors(toastId, response, response.status);
      setLoading(false);
      setError(data.detail);
      console.error('Error uploading file:', data.detail);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const websites = []
    if (website1) websites.push(website1);
    if (website2) websites.push(website2);

    const form = {
      messaging_product: 'whatsapp',
      vertical: selectedCategory?.value,
      description,
      email,
      websites,
      address,
      profile_picture_handle: profilePictureHandle ? profilePictureHandle : null
    }

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/phone-numbers/update/phone-number/${whatsappInfo.number_info.id}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: 'Atualizado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/settings/integrations');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="py-4 px-6">
      <div className="pb-8">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      {!profilePicture && !profilePictureHandle ? <div className='pb-4'>
        <Alert severity={'warning'}>Seu perfil está sem foto de perfil, adicione-a abaixo!</Alert>
      </div> : null}

      {profilePictureHandle ? <div className='pb-4'>
        <Alert severity={'info'}>Upload da foto concluído. Ao atualizar o perfil, você será capaz de visualizar a nova foto de perfil!</Alert>
      </div> : null}

      <div className="mb-4 flex flex-col md:flex-row justify-between">

        <div className='flex flex-col w-full md:w-1/3 pr-4'>
          <span className="text-base font-bold text-gray-900 mb-1">Configuração do perfil do Whatsapp</span>
          <span className="text-sm font-normal text-gray-500">Aqui é possível configurar os dados que seus clientes irão ver quando conversarem com a sua empresa no WhatsApp.</span>
        </div>

        <div className='flex flex-col w-full md:w-2/3 mt-8 md:mt-0'>
          <div className="flex flex-row items-center">
            {profilePicture ? <img className='h-24 w-24 rounded-lg' src={profilePicture} alt='user-profile' /> : null}

            <div className={`${profilePicture ? 'ml-8' : ''}`}>
              <label htmlFor="file-upload" className="flex sm:inline-flex text-[#11111f] bg-white border border-[#11111f] hover:bg-[#11111f] hover:text-white font-medium rounded-md text-sm px-5 py-2.5 text-center items-center cursor-pointer">
                <CloudUploadOutlinedIcon className="mr-2" fontSize="small" />
                {profilePicture ? 'Mudar' : 'Adicionar'} foto
              </label>
              <input id="file-upload" type="file" accept="image/*" onChange={handleFileChange} className="sr-only" />
              <div className="pt-1">
                <span className="text-xs font-normal text-gray-500 mt-2">JPG, JPEG ou PNG. 1MB máx.</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="align-middle inline-block min-w-full">

              <div className="md:flex items-center">
                <div className="w-full md:w-1/2 flex flex-col mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Número</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow mt-4 bg-gray-200 cursor-not-allowed border-gray-200 border rounded`}
                      value={!phoneNumber ? '' : phoneNumber}
                      disabled
                    />
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Nome exibição</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow mt-4 bg-gray-200 cursor-not-allowed border-gray-200 border rounded`}
                      value={!name ? '' : name}
                      maxLength={128}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="md:flex items-center mt-6">
                <div className="w-full md:w-1/2 flex flex-col mt-3 md:mt-0">
                  <label className="font-semibold leading-none">Categoria</label>

                  <div className="mt-3 z-10">
                    <CustomListbox className="bg-white" list={categoryList} selected={selectedCategory} setSelected={setSelectedCategory} placeholder='Categoria do seu serviço' />
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Email</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                      value={!email ? '' : email}
                      maxLength={128}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Insira o email comercial"
                    />
                  </div>
                </div>
              </div>

              <div className="md:flex items-center mt-6">
                <div className="w-full flex flex-col mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Endereço</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                      value={!address ? '' : address}
                      maxLength={256}
                      onChange={e => setAddress(e.target.value)}
                      placeholder="Insira o endereço comercial"
                    />
                  </div>
                </div>
              </div>

              <div className="md:flex items-center mt-6">
                <div className="w-full flex flex-col mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Site</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                      value={!website1 ? '' : website1}
                      maxLength={256}
                      onChange={e => setWebsite1(e.target.value)}
                      placeholder="https://www.exemplo.com"
                    />
                  </div>
                  <div className="pt-2">
                    {!website2 && !showWebsite2 ?
                      <button onClick={() => setShowWebsite2(!showWebsite2)} className="flex sm:inline-flex text-[#11111f] bg-white border border-[#11111f] hover:bg-[#11111f] hover:text-white font-medium rounded-md text-sm px-5 py-2 text-center items-center">
                        <AddOutlinedIcon className="-ml-2 mr-2" fontSize="small" />
                        Adicionar outro site
                      </button> :
                      <div className="flex flex-col">
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow bg-white border-gray-200 border rounded`}
                          value={!website2 ? '' : website2}
                          maxLength={256}
                          onChange={e => setWebsite2(e.target.value)}
                          placeholder="https://www.exemplo.com"
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col mt-6">
                <label className="font-semibold leading-none">Descrição</label>
                <textarea
                  className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                  value={!description ? '' : description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>

              <button onClick={handleUpdate} className="mt-6 font-normal leading-none text-white py-3 px-6 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : 'Atualizar'}
              </button>
            </div>
          </div>
        </div>

        <div className="hidden lg:block lg:w-[22%] pl-4">
          <div className="sticky top-0">
            <div className="relative bg-white shadow-lg">
              <div className="p-4">
                <span className="text-sm font-semibold">Prévia do perfil da empresa</span>
              </div>
              <div className="bg-gray-300 relative">
                {profilePicture ?
                  <img
                    className="w-full h-52 object-cover"
                    src={profilePicture}
                    alt="Company"
                  /> :
                  <div className="w-full h-52 object-cover bg-[#29d4b9]" />
                }

                <div className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t ${profilePicture ? 'from-[#11111f]' : 'from-[#064E3B]'} to-transparent p-4`}>
                  <p className="text-white text-[18px]">{name}</p>
                </div>

                {/* Icons on top right */}
                <div className="absolute top-3 w-full flex space-x-2">
                  <div className="flex-1 pl-4">
                    <ArrowBackIcon htmlColor="white" fontSize="medium" />
                  </div>

                  <div className="pr-4">
                    <MoreVertIcon htmlColor="white" fontSize="medium" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start justify-start bg-white shadow-sm p-4 my-2">
              <span className="text-sm">Conta empresarial</span>
            </div>

            {website2 || website1 || email || address || selectedCategory || description ?
              <div className="bg-white shadow-sm p-4 my-2">
                {description ?
                  <div className="flex items-start justify-start">
                    <StoreIcon color="disabled" fontSize="small" />
                    <span className="text-sm ml-2">{description}</span>
                  </div>
                  : null}
                {selectedCategory ?
                  <div className={`flex items-start justify-start ${description ? 'mt-4' : 'mt-0'}`}>
                    <LocalOfferIcon color="disabled" fontSize="small" />
                    <span className="text-sm ml-2">{selectedCategory?.name}</span>
                  </div>
                  : null}
                {address ?
                  <div className={`flex items-start justify-start ${selectedCategory || description ? 'mt-4' : 'mt-0'}`}>
                    <LocationOnIcon color="disabled" fontSize="small" />
                    <span className="text-sm text-[#128c7e] ml-2">{address}</span>
                  </div>
                  : null}
                {email ?
                  <div className={`flex items-start justify-start ${address || selectedCategory || description ? 'mt-4' : 'mt-0'}`}>
                    <EmailIcon color="disabled" fontSize="small" />
                    <span className="text-sm text-[#128c7e] ml-2">{email}</span>
                  </div>
                  : null}
                {website1 ?
                  <div className={`flex items-start justify-start ${email || address || selectedCategory || description ? 'mt-4' : 'mt-0'}`}>
                    <PublicIcon color="disabled" fontSize="small" />
                    <span className="text-sm text-[#128c7e] ml-2">{website1}</span>
                  </div>
                  : null}
                {website2 ?
                  <div className={`flex items-start justify-start ${website1 || email || address || selectedCategory || description ? 'mt-4' : 'mt-0'}`}>
                    <PublicIcon color="disabled" fontSize="small" />
                    <span className="text-sm text-[#128c7e] ml-2">{website2}</span>
                  </div>
                  : null}
              </div>
              : null}
          </div>
        </div>

      </div>
    </div>
  );
}

export default WhatsappSettings;
