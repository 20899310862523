import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Divider } from '@mui/material';

import { LoadingDots } from '../../../../components/LoadingDots';

interface VariableObject {
  id: number;
  name: string;
  value: string;
}

interface FormDialogI {
  open: boolean;
  handleClickOpen: React.MouseEventHandler<HTMLButtonElement>;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  message: string;
  variables: VariableObject[];
  handleVariableChange: (id: number, newValue: string) => void;
  handleForm: React.MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
}

const FormDialog = ({ open, handleClickOpen, handleClose, message, variables, handleVariableChange, handleForm, isLoading }: FormDialogI) => {
  const onVariableValueChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    handleVariableChange(id, e.target.value);
  };

  return (
    <React.Fragment>
      <button className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" onClick={handleClickOpen} disabled={isLoading}>
        {isLoading ? <span><LoadingDots color="white" dotStyle="small" /></span> : 'Cadastrar template para aprovação'}
      </button>
      <Dialog
        open={open}
        onClose={(event: React.SyntheticEvent, reason: string) => {
          if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && isLoading) {
            event.stopPropagation();
          } else {
            handleClose(event as React.MouseEvent<HTMLButtonElement>);
          }
        }}>
        <div className="flex flex-col pt-6 px-6">
          <h3 className="text-xl font-bold text-gray-900 ">Adicione exemplos para suas variáveis</h3>
          <span className="text-sm font-normal text-gray-500 mt-2">
            Para enviar para aprovação, o WhatsApp exige que você adicione uma amostra do conteúdo que pretende enviar para cada variável
            espaço reservado.
          </span>
        </div>
        <div className="pt-6">
          <Divider />
        </div>
        <DialogContent>
          <div className="flex flex-col">
            <p className="text-base font-bold">Mensagem:</p>
            <p className="text-sm font-normal mt-2">{message}</p>
          </div>
          <div className="mt-8">
            {variables.map((variable, index) => (
              <div className="w-full flex flex-col mt-6" key={index}>
                <label className="text-sm font-semibold leading-none">Exemplo para {variable.name}</label>
                <input
                  className={`text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 ${isLoading ? 'bg-gray-200 cursor-not-allowed border-gray-300' : 'bg-white border-gray-200'} border rounded`}
                  value={variable.value}
                  onChange={(e) => onVariableValueChange(e, variable.id)}
                  disabled={isLoading}
                />
              </div>
            ))}
          </div>
        </DialogContent>
        <div className="flex flex-row p-6 justify-end">
          <button className={`font-normal leading-none text-black px-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={isLoading ? undefined : handleClose}>Cancelar</button>
          <button className="font-normal leading-none text-white ml-4 py-3 px-7 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" onClick={handleForm} disabled={isLoading}>
            {isLoading ? <span><LoadingDots color="white" dotStyle="small" /></span> : 'Cadastrar'}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default FormDialog;