// import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// import { useAuth } from "../../../../contexts/authContext";

import { Auth0User } from "../../../../utils/types";
import PersonalInformation from "./PersonalInformation";

const UserSettings = () => {
  const { user } = useAuth0<Auth0User>();
  // const { token, idTokenClaims } = useAuth();

  // const [client, setClient] = useState<ClientInterface | null>(null);

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!client) getClient();
  // });

  // const getClient = async () => {
  //   if (token) {
  //     try {
  //       setIsLoading(true);

  //       const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client/${idTokenClaims?.client_id}`, {
  //         method: 'GET',
  //         headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
  //       })

  //       const resJson = await res.json();

  //       setClient(resJson);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return <PersonalInformation user={user} />;
}

export default UserSettings;
