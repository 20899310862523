import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import * as yaml from 'js-yaml';
import { UUID } from "crypto";

import { useAuth } from '../../../contexts/authContext';

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Collapse from '@mui/material/Collapse';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { toastErrors } from '../../../utils/toastErrors';
import { LoadingDots } from '../../../components/LoadingDots';
import { ConfirmationModal } from '../../../components';
import { Toggle } from "../../../components/Toogle";

import { CustomListbox, CustomCombobox } from "../../../components";

import { TaskMetricsInterface, ClientInterface, LLMModelInterface } from '../../../utils/types';
import { formatDateTime } from '../../../utils/formatDate';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai.js";
import "ace-builds/src-noconflict/mode-yaml.js";
import "ace-builds/src-noconflict/keybinding-vscode.js";
import "ace-builds/src-noconflict/ext-language_tools.js";

const ace = require("ace-builds/src-noconflict/ace");
ace.config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/"
);
ace.config.setModuleUrl(
  "ace/mode/yaml_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-yaml.js"
);

interface MetricFormInterface {
  metric?: TaskMetricsInterface,
  formName: string
}

interface MetricTypelInterface {
  id: string,
  name: string,
  value: string
}

interface PromptTemplatesInterface {
  prompt_template_id: UUID,
  task_name: string,
  pipeline_name: string
}

const MetricsForm = ({ metric, formName }: MetricFormInterface) => {
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');

  const [name, setName] = useState(metric?.name);
  const [evalPrompt, setEvalPrompt] = useState(metric?.eval_prompt);
  const [sampleSize, setSampleSize] = useState(metric?.sample_size ? metric?.sample_size.toString() : '');
  const [enabled, setEnabled] = useState(isEditting ? metric!.enabled : true)

  const [clients, setClients] = useState<ClientInterface[] | null>(null);
  const [llmModels, setLlmModels] = useState<LLMModelInterface[] | null>(null);
  const [promptTemplates, setPromptTemplates] = useState<PromptTemplatesInterface[] | null>(null);

  const [metricTypeList] = useState<MetricTypelInterface[]>([{ id: '1', name: 'Sentimento', value: 'sentiment' }, { id: '2', name: 'Categoria do assunto', value: 'subject_category' }, { id: '3', name: 'Confiabilidade', value: 'reliability' }]);
  const [selectedMetricType, setSelectedMetricType] = useState<MetricTypelInterface | null>(
    metric?.metric_type ? metricTypeList.find(metricL => metricL.value === metric?.metric_type) || null : null
  );

  const [selectedClient, setSelectedClient] = useState(isEditting ? metric?.client : null);
  const [clientQuery, setClientQuery] = useState('');

  const [selectedLlmModel, setSelectedLlmModel] = useState(isEditting ? metric?.llm_model : null);
  const [llmModelQuery, setLlmModelQuery] = useState('');

  const [selectedPromptTemplate, setSelectedPromptTemplate] = useState<PromptTemplatesInterface | null>(
    metric?.prompt_template_id ? promptTemplates?.find(templatesL => templatesL.prompt_template_id === metric?.prompt_template_id) || null : null
  );

  const [promptTemplateIdQuery, setPromptTemplateIdQuery] = useState('');

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');
  const [openCollapse, setOpenCollapse] = useState(isEditting && metric?.eval_prompt ? true : false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [yamlError, setYamlError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/metrics">Métricas</Link>,
    <Typography key="3" color="text.primary">{isEditting ? metric?.id : 'Cadastrar métrica'}</Typography>
  ];

  useEffect(() => {
    const newSelectedPromptTemplate = metric?.prompt_template_id
      ? promptTemplates?.find(template => template.prompt_template_id === metric?.prompt_template_id) || null
      : null;
    setSelectedPromptTemplate(newSelectedPromptTemplate);
  }, [metric, promptTemplates]);

  useEffect(() => {
    if (!clients) getClients();
    if (!llmModels) getLLMModels();
    if (isEditting && metric?.client && !promptTemplates) getPromptTemplates(metric.client.id);
  });

  useEffect(() => {
    if (!isEditting && selectedClient) getPromptTemplates(selectedClient.id);
  }, [selectedClient])

  const getClients = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setClients(resJson.clients);
    } catch (error) {
      console.log(error);
    }
  };

  const getLLMModels = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/llm-model`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setLlmModels(resJson.llm_models);
    } catch (error) {
      console.log(error);
    }
  };

  const getPromptTemplates = async (client_id: UUID) => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task/prompt-template/client/${client_id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setPromptTemplates(resJson.prompt_templates);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task-metric/delete/${metric?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/metrics');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    try {
      yaml.load(evalPrompt!);
    } catch (err: any) {
      setYamlError(err.message);
      setLoading(false);
      toast.dismiss();
      return;
    }

    const form = { name, eval_prompt: evalPrompt, metric_type: selectedMetricType?.value, sample_size: parseInt(sampleSize), enabled, prompt_template_id: selectedPromptTemplate?.prompt_template_id, client_id: selectedClient?.id, llm_model_id: selectedLlmModel?.id };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task-metric${isEditting ? `/${metric?.id}` : ''}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/metrics');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <div className="pt-6 px-4">

      <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      <div className="py-4 md:flex items-center justify-between">
        <div className="w-full md:w-1/2 flex flex-col">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">{formName} métrica</h3>
          <span className="text-base font-normal text-gray-500">Certifique-se de que a métrica está funcionando corretamente antes de {formName.toLowerCase()}</span>
        </div>

        <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
          <Toggle enabled={enabled} setEnabled={(newVal) => setEnabled(newVal)} />
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 pb-8 xl:p-8 xl:pb-12">
          <div className="md:flex items-center justify-between">
            <div className="w-full md:w-1/2 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Informações gerais</h3>
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <div className="align-middle inline-block min-w-full">

              <ConfirmationModal action={action} entity='modelo LLM' open={open} setOpen={setOpen} handleAction={handleForm} />

              <div className="md:flex items-center mt-3">
                <div className="w-full md:w-1/2 flex flex-col">
                  <label className="font-semibold leading-none">Nome</label>
                  <input
                    type="text"
                    className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                    value={!name ? '' : name}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                  <label className="font-semibold leading-none">Modelo LLM</label>
                  <div className="mt-3 z-30">
                    <CustomCombobox entity="Modelo LLM" list={llmModels} selected={selectedLlmModel} setSelected={setSelectedLlmModel} query={llmModelQuery} setQuery={setLlmModelQuery} emptyState={false} />
                  </div>
                </div>
              </div>

              <div className="md:flex items-center mt-8">
                <div className="w-full md:w-1/2 flex flex-col">
                  <label className="font-semibold leading-none mb-3">Tipo da métrica</label>
                  <div className="z-20">
                    <CustomListbox list={metricTypeList} selected={selectedMetricType} setSelected={setSelectedMetricType} placeholder='Selecione o tipo da métrica' />
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                  <label className="font-semibold leading-none">Tamanho da amostra</label>
                  <input
                    type="text"
                    className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                    value={sampleSize ? sampleSize.toString() : ''}
                    onChange={e => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        // Update state only if the input is empty or matches the pattern
                        setSampleSize(value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Eval Prompt</h3>
            </div>
            <div>
              <IconButton onClick={handleCollapse}>
                <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openCollapse}>
            <div className="flex flex-col mt-8 pb-6">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    <div className="w-full flex flex-col">
                      <AceEditor
                        placeholder="..."
                        mode="yaml"
                        theme="monokai"
                        name="_unique_"
                        fontSize={14}
                        width="100%"
                        keyboardHandler="vscode"
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={!evalPrompt ? '' : evalPrompt}
                        onChange={(e) => setEvalPrompt(e)}
                        editorProps={{ $blockScrolling: true }}
                        minLines={35}
                        maxLines={35}
                        setOptions={{
                          useWorker: false,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true,
                          tabSize: 2,
                        }} />
                    </div>

                    {yamlError ? <div className="flex flex-col p-8 my-4 bg-red-400 rounded-md">
                      {yamlError}
                    </div> : null}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg pb-7 px-6 xl:pb-8 xl:px-8">

          <div className="flex flex-col">
            <div className="align-middle inline-block min-w-full">

              <ConfirmationModal action={action} entity='modelo LLM' open={open} setOpen={setOpen} handleAction={action === 'Deletar' ? handleDelete : handleForm} />

              <div className="md:flex items-center mt-8">
                <div className="w-full md:w-1/2 flex flex-col">
                  <label className="font-semibold leading-none">Cliente</label>

                  {isEditting ?
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                      value={metric?.client.name}
                      disabled
                    />
                    :
                    <div className="mt-3 z-10">
                      <CustomCombobox entity="cliente" list={clients} selected={selectedClient} setSelected={setSelectedClient} query={clientQuery} setQuery={setClientQuery} emptyState={false} />
                    </div>
                  }
                </div>

                <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                  <label className="font-semibold leading-none">Prompt template Id</label>
                  {/* <input
                    type="text"
                    className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 ${selectedClient === null ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200`}
                    value={promptTemplateId ? promptTemplateId : ''}
                    onChange={e => setPromptTemplateId(e.target.value)}
                    required
                    disabled={selectedClient === null}
                  /> */}

                  {!selectedClient ?
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                      disabled
                    />
                    :
                    <div className="mt-3 z-10">
                      <CustomCombobox entity="prompt template id" list={promptTemplates} selected={selectedPromptTemplate} setSelected={setSelectedPromptTemplate} query={promptTemplateIdQuery} setQuery={setPromptTemplateIdQuery} emptyState={false} displayProperty='prompt_template_id' />
                    </div>
                  }
                </div>
              </div>

              {isEditting ? <div className="mt-4">
                <span className="text-sm opacity-50">Última atualização: {metric?.updated_at ? formatDateTime(metric?.updated_at!) : 'não teve atualização!'}</span>
              </div> : null}

              <div className="flex items-center justify-end w-full">
                {isEditting ? <button onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none">Deletar</button> : null}

                <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                  {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default MetricsForm;
