import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Pagination from '@mui/material/Pagination';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { useAuth } from "../../../../contexts/authContext";

import { formatDateTime } from "../../../../utils/formatDate";
import { CampaignInterface } from "../../../../utils/types";

const List = () => {
  const { token, idTokenClaims } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [campaigns, setCampaigns] = useState<CampaignInterface[]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filteredName, setFilteredName] = useState<string | null>(null);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages">Disparo ativo</Link>,
    <Typography key="3" color="text.primary">Campanhas de mensagens ativas</Typography>
  ];

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/campaign?client_id=${idTokenClaims?.client_id}&offset=${offset}&limit=10`;
    if (filteredName) url = url + `&name=${filteredName}`;
    return url;
  }, [filteredName, idTokenClaims?.client_id]);

  const getCampaigns = useCallback(async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        const resCampaigns = resJson.campaigns;
        setCampaigns(resCampaigns);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getCampaigns((value - 1) * 10);
  };

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 flex flex-col justify-between items-start">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Campanhas de mensagens ativas</h3>
              <span className="text-base font-normal text-gray-500"><span className="italic">O que é uma campanha de mensagens ativas?</span> É toda comunicação que você inicia pelo menos 24 horas depois da última mensagem trocada com clientes. E não esqueça: esse recurso deve seguir as regras do WhatsApp.</span>
            </div>
            <Link to="/active-messages/message-broadcast/send-message" className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
              Enviar mensagens ativas
              <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
              </svg>
            </Link>

            <div className="mt-4 flex flex-col justify-between items-center md:flex-row">
              <div className="flex flex-col md:flex-row w-full">
                <div className="relative w-full">
                  <input
                    type="search"
                    className={`w-full leading-none text-gray-900 pl-11 py-3 shadow focus:outline-none focus:border-[#11111f] bg-white border rounded border-gray-200 font-medium`}
                    placeholder="Pesquisar por nome..."
                    value={!filteredName ? '' : filteredName}
                    onChange={(e) => setFilteredName(e.target.value)}
                  />
                  <div className="absolute top-0 left-0 inline-flex items-center p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                      strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                      strokeLinejoin="round">
                      <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-5">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nome campanha
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Público
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Enviadas
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Recebidas
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Lidas
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Falhas
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider">
                            Agendamento
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data de envio
                          </th>
                          <th scope="col" className="tracking-wider" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {campaigns?.length !== 0 ? campaigns?.map((campaign, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {campaign.name}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.audience_count}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.status_counts.sent_count}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.status_counts.delivered_count}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.status_counts.read_count}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.status_counts.failed_count}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {campaign.schedule ? 'Sim' : 'Não'}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {campaign.schedule ? formatDateTime(campaign.schedule ? campaign.schedule : '') : formatDateTime(campaign.created_at ? campaign.created_at : '')}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-center">
                              <Link to={`/active-messages/message-broadcast/${campaign.id}`} state={{ campaign: campaign }} className="px-2.5 p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                                <VisibilityOutlinedIcon fontSize='small' />
                              </Link>
                            </td>
                          </tr>
                        )) : <tr>
                          <td className="w-full text-center mx-auto pb-12" colSpan={8}>
                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                            <p className="text-gray-700 font-medium text-lg text-center">Nenhuma campanha iniciada.</p>
                            <p className="text-gray-500 text-center">Inicie uma campanha para que você possa acompanhar as métricas por aqui!</p>
                            <Link to="/active-messages/message-broadcast/send-message" className="flex items-center w-fit px-4 py-1.5 border border-gray-400 mt-4 text-gray-600 rounded text-sm hover:bg-[#11111f] hover:text-white mx-auto">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-6 h-6  mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              Iniciar campanha!
                            </Link>
                          </td>
                        </tr>}
                      </tbody>
                    </table> :
                      <>
                        <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                        <Skeleton variant="rounded" height={340} />
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
              {campaigns?.length !== 0 ? <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="hidden sm:contents">
                  <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
                </div>

                <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
              </div> : null}
            </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

          </div>
        </div>
      </div>
    </>
  )
}

export default List;
