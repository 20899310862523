import { useState } from "react";

import { ClientInterface, Auth0User } from "../../../../utils/types";

interface PersonalInformationI {
  user: Auth0User | undefined;
  client?: ClientInterface | null;
}


/**
 * @todo: after handled updating user info auth0 information, I'll need to remove `cursor-default`
 * from inputs className's
 */
const PersonalInformation = ({ user, client }: PersonalInformationI) => {
  const [firstName, setFirstName] = useState(user?.given_name);
  const [lastName, setLastName] = useState(user?.family_name);
  const [email, setEmail] = useState(user?.email);
  const [userName, setUserName] = useState(user?.nickname);

  return (
    <div className="mb-4 flex flex-col md:flex-row justify-between py-4 px-6">
      <div className='flex flex-col w-full md:w-1/3 pr-4'>
        <span className="text-base font-bold text-gray-900 mb-1">Informações pessoais</span>
        <span className="text-sm font-normal text-gray-500">As informações apresentadas são apenas para leitura.</span>
      </div>

      <div className='flex flex-col w-full md:w-2/3 mt-8 md:mt-0'>
        <div className="flex flex-row items-center">
          <img className='h-24 w-24 rounded-lg' src={user?.picture} alt='user-profile' />
          {/* <div className="ml-8 ">
            <button className="flex sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center">
              Mudar foto
            </button>
            <div className="pt-1">
              <span className="text-xs font-normal text-gray-500 mt-2">JPG, JPEG ou PNG. 1MB máx.</span>
            </div>
          </div> */}
          <div className="ml-8 ">
            <span className="text-base font-bold text-gray-900 mb-1">{user?.name}</span>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="align-middle inline-block min-w-full">

            <div className="md:flex items-center">
              <div className="w-full md:w-1/2 flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Primeiro nome</label>
                  <input
                    type="text"
                    className={`cursor-default leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                    value={!firstName ? '' : firstName}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Último nome</label>
                  <input
                    type="text"
                    className={`cursor-default leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                    value={!lastName ? '' : lastName}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mt-6">
              <div className="w-full flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Email</label>
                  <input
                    type="text"
                    className={`cursor-default leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                    value={!email ? '' : email}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mt-6">
              <div className="w-full flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Username</label>
                  <input
                    type="text"
                    className={`cursor-default leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                    value={!userName ? '' : userName}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            {/* <div className="md:flex items-center mt-6">
                <div className="w-full flex flex-col mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <label className="font-semibold leading-none">Empresa</label>
                    <input
                      type="text"
                      className={`cursor-default leading-none text-gray-900 p-3 shadow mt-4 bg-white border-gray-200 border rounded`}
                      value={!client ? '' : client.name}
                      required
                      readOnly
                    />
                  </div>
                </div>
              </div> */}

          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
