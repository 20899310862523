import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom'; // Outlet is used to render the matched child route

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import structure from './structure.json';

interface FilesI {
  name: string;
  path: string;
}

interface FolderI {
  name: string;
  path: string;
  files: FilesI[]
}

const HelpLayout: React.FC = () => {
  const { folderPath, filePath } = useParams<{ folderPath: string; filePath: string }>();

  const findFolder = useCallback(() => (
    folderPath ? structure.find(folder => folder.path === folderPath) ?? null : null
  ), [folderPath])

  const findFile = useCallback((folder: FolderI | null) => (
    filePath ? folder?.files.find(file => file.path === `${filePath}.md`) ?? null : null
  ), [filePath])

  const [folder, setFolder] = useState(findFolder());
  const [file, setFile] = useState(findFile(folder));

  useEffect(() => {
    setFolder(findFolder());
    setFile(findFile(folder));
  }, [folderPath, filePath, findFolder, findFile, folder]);


  const renderBreadcrumb = () => {
    if (folderPath && filePath) {
      return [
        <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
        <Link className="hover:underline" key="1" color="inherit" to="/help">Ajuda</Link>,
        <Typography key="3" color="text.primary">{`${folder?.name} - ${file?.name}`}</Typography>
      ];
    }
  }

  return (
    <div className="pt-6 px-4">
      {folderPath && filePath ? <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {renderBreadcrumb()}
        </Breadcrumbs>
      </div> : null}

      <Outlet />
    </div>
  );
};

export default HelpLayout;
