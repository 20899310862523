import { useLocation } from "react-router-dom";
import DocumentsForm from "./Form";

const AddDocument = () => {
  const location = useLocation();
  const { collection } = location.state;

  return(<DocumentsForm collection={collection} formName="Cadastrar" />);
}

export default AddDocument;