import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Divider } from '@mui/material';

import { useAuth } from '../../../../contexts/authContext';

import { toastErrors } from '../../../../utils/toastErrors';

import { ConfirmationModal, CustomListbox } from '../../../../components';

import { MessageTemplateInterface } from '../../../../utils/types';
import { showApprovalStatus } from '../../../../utils/showApprovalStatus';
import { parseVariables } from '../../../../utils/parseVariables';

import FormDialog from './FormDialog';

interface MessageTemplateFormInterface {
  messageTemplate?: MessageTemplateInterface,
  formName: string,
}

interface CategorylInterface {
  id: string,
  name: string
}

interface VariableObject {
  id: number;
  name: string;
  value: string;
}

const MessageTemplateForm = ({ messageTemplate, formName }: MessageTemplateFormInterface) => {
  const { token, idTokenClaims } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');

  const [name, setName] = useState(messageTemplate?.friendly_name);
  const [template, setTemplate] = useState<string>('');
  const [variables, setVariables] = useState<VariableObject[]>([]);

  const [isTypingVariable, setIsTypingVariable] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>('');

  const [categoryList] = useState<CategorylInterface[]>([{ id: '1', name: 'Marketing' }, { id: '2', name: 'Autenticação' }, { id: '3', name: 'Utilidade' }]);
  const [selectedCategory, setSelectedCategory] = useState<CategorylInterface | null>({ id: '1', name: 'Marketing' });

  const [open, setOpen] = useState(false);
  
  const [triggerHandleForm, setTriggerHandleForm] = useState(false);

  const [action, setAction] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages">Disparo ativo</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages/message-template">Mensagem template</Link>,
    <Typography key="3" color="text.primary">{isEditting ? 'Editar template' : 'Cadastrar template'}</Typography>
  ];

  // Function to check if the variable is in the correct format {{1}} and if
  // the variable has no non-numeric values.
  const checkVariableTyping = (input: string) => {
    // Check for the presence of at least one opening brace
    const hasOpeningBrace = /{/.test(input);

    // Extract all instances that look like variables (including incomplete ones)
    const potentialVariables = input.match(/{{[^{}]*}}?/g) || [];

    // Check each one for correct formatting
    const correctVariableRegex = /{{\s*\w+\s*}}/;
    const isAnyVariableIncorrect = potentialVariables.some(variable => !correctVariableRegex.test(variable));

    // Check for incomplete variables (e.g., "{{var" or "{{var}")
    const isIncompleteVariable = /{{[^{}]*$/.test(input);

    // Check for non-numeric variable names
    const nonNumericVariableRegex = /{{\s*[^0-9]+\s*}}|{{\s*\d*[^0-9]+\d*\s*}}|{{\s*\d*[a-zA-Z]+[^}]*\s*}}/;
    const hasNonNumericVariable = potentialVariables.some(variable => nonNumericVariableRegex.test(variable));

    const isSequential = checkVariableNumbering(input);

    if (hasNonNumericVariable) {
      setIsTypingVariable(true);
      setUserMessage("Os nomes das variáveis devem ser numéricos. Por favor, use um formato como {{1}}.");
    } else if ((hasOpeningBrace && isIncompleteVariable) || isAnyVariableIncorrect) {
      setIsTypingVariable(true);
      setUserMessage("Parece que você está começando a digitar uma variável. Por favor, use o formato {{1}}.");
    } else if (!isSequential) {
      setIsTypingVariable(true);
      setUserMessage("Os nomes das variáveis devem ser definidos em sequência, sem pular números. (1, 2, 3, n...)");
    } else {
      setIsTypingVariable(false);
      setUserMessage('');
    }
  };

  // Function to check if the numeric variable names are in sequential order.
  const checkVariableNumbering = (input: string) => {
    // Extract numbers from variable placeholders like {{1}}, {{2}}, etc.
    const numberMatches = input.match(/{{\s*(\d+)\s*}}/g);

    if (!numberMatches) {
      return true; // Return true if there are no variables
    }

    // Extract just the numbers, ensuring that match is not null
    const numbers = numberMatches
      .map(match => {
        const matchResult = match.match(/\d+/);
        return matchResult ? parseInt(matchResult[0]) : null;
      })
      .filter(number => number !== null); // Filter out any null values

    // Check if numbers are in sequence
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] !== i + 1) {
        return false; // Return false if any number is out of sequence
      }
    }

    return true; // Return true if all numbers are in sequence
  };

  const handleTemplateChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value;
    setTemplate(input);
    checkVariableTyping(input);
  };

  const handleClickOpen = () => {
    if (isTypingVariable) {
      toast.error("Existem erros na sua mensagem template. Por favor, corrija-os e prossiga com o cadastro!");
      return;
    }

    const parsedVariables = parseVariables(template);

    if (parsedVariables.length > 0) {
      setVariables(parsedVariables);
      setOpen(true);
    } else {
      setVariables([]);
      setTriggerHandleForm(true);
    }
  };

  const handleForm = useCallback(async () => {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const variablesTransformed = variables.reduce((acc, current) => {
      acc[current.name] = current.value;
      return acc;
    }, {} as Record<string, string>);

    const form = {
      friendly_name: name,
      category: selectedCategory?.name,
      client_id: idTokenClaims?.client_id,
      language: 'pt_BR',
      variables: variablesTransformed,
      types: {
        "twilio/text": {
          body: template
        }
      }
    }

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client-message-templates/create-message-template`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: 'Enviado para aprovação!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/active-messages/message-template');
      }
    } catch (error) {
      console.log(error);
    }
  }, [token, navigate, name, selectedCategory, template, variables, idTokenClaims?.client_id]);

  useEffect(() => {
    if (triggerHandleForm && variables.length === 0) {
      handleForm();
      setTriggerHandleForm(false); // Reset the trigger
    }
  }, [triggerHandleForm, variables, handleForm]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleVariableChange = (id: number, newValue: string) => {
    setVariables(prevVariables =>
      prevVariables.map(variable =>
        variable.id === id ? { ...variable, value: newValue } : variable
      )
    );
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This regular expression matches only lowercase letters, numbers, and underscores
    const regex = /^[a-z0-9_]*$/;
  
    // Get the current input value
    const { value } = e.target;
  
    // If the value matches the regex or is empty (to allow clearing the input), update the state
    if (regex.test(value) || value === "") {
      setName(value);
    }
  };

  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client-network-phone/delete/${messageTemplate?.sid}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/active-messages/message-broadcast');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="py-4 md:flex items-center justify-between">
          <div className="w-full flex flex-col">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">{formName} mensagem template</h2>
            <span className="text-base font-normal text-gray-500">As mensagens enviadas para iniciar conversas com usuários do WhatsApp devem ser enviadas usando um template de mensagem. O WhatsApp aprovará ou rejeitará seu template em 48 horas ou menos.</span>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center justify-between">
              <div className="w-full flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Informações gerais</h3>
              </div>
            </div>

            <div className="flex flex-col my-4">
              <div className="align-middle inline-block min-w-full">

                <div className="md:flex items-center my-2">
                  <div className="w-full md:w-1/2 pr-2 mt-0 md:-mt-6">
                    <div className="flex flex-col">
                      <label className="font-semibold leading-none">Nome do template</label>
                      <input
                        type="text"
                        className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 ${isEditting ? 'bg-gray-200 cursor-not-allowed border-gray-300' : 'bg-white border-gray-200'} border rounded`}
                        value={!name ? '' : name}
                        onChange={handleNameChange}
                        placeholder="mensagem_boas_vindas"
                        required
                        disabled={isEditting}
                      />
                    </div>
                    <div className="pt-2">
                      <span className="text-xs font-normal text-gray-500 mt-2">Só pode conter caracteres alfanuméricos minúsculos e underscores.</span>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 pl-0 mt-8 md:mt-0 md:pl-2">
                    <div className="flex flex-col">
                      {/* <label className={`font-semibold leading-none ${isEditting ? '' : 'mb-3'}`}>Categoria do template</label> */}
                      <label className={`font-semibold leading-none`}>Categoria do template</label>
                      {/* {!isEditting ?
                        <CustomListbox list={categoryList} selected={selectedCategory} setSelected={setSelectedCategory} placeholder='Selecione a categoria' />
                        : */}
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border-gray-300 border rounded`}
                          value={!isEditting ? selectedCategory?.name : capitalizeFirstLetter(messageTemplate!.approval_status.whatsapp.category)}
                          disabled
                        />
                      {/* } */}
                    </div>
                    <div className="pt-2">
                      <span className="text-xs font-normal text-gray-500">Esta categoria será fornecida ao WhatsApp para revisão. O WhatsApp pode mudar para uma categoria diferente com base na análise do conteúdo fornecido.</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center justify-between">
              <div className="w-full flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Mensagem template</h3>
              </div>
            </div>

            <div className="flex flex-col my-4">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">

                    <div className={`my-2`}>
                      <Alert severity={`${isEditting ? 'info' : 'warning'}`}><b>Templates não podem ser editados.</b> O WhatsApp não permite que os templates sejam editados após enviados para aprovação.</Alert>
                    </div>

                    {isEditting ? <div className="my-2">
                      <div className="bg-gray-100 p-8 mt-4 rounded-md">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-semibold">Status: </span>
                          <div className="flex flex-row items-center">
                            {showApprovalStatus(messageTemplate!)}
                          </div>
                        </div>

                        {messageTemplate?.approval_status.whatsapp.rejection_reason ? <div className="grid grid-cols-2 mt-4">
                          <span className="text-sm font-semibold">Motivo: </span>
                          <span className="text-sm font-normal">{messageTemplate?.approval_status.whatsapp.rejection_reason}</span>
                        </div> : null}

                        <div className="my-4">
                          <Divider />
                        </div>

                        <div className="grid grid-cols-2">
                          <span className="text-sm font-semibold">Idioma da mensagem: </span>
                          <span className="text-sm font-normal">{messageTemplate?.language === 'pt_BR' ? 'Português (BR)' : ''}</span>
                        </div>

                        <div className="grid grid-cols-2 mt-4">
                          <span className="text-sm font-semibold">Corpo da mensagem: </span>
                          <span className="text-sm font-normal whitespace-pre-line">{messageTemplate?.types['twilio/text'].body}</span>
                        </div>
                      </div>
                    </div> :
                      <div className="w-full flex flex-col mt-4">
                        <label className="font-semibold leading-none">Mensagem (1024 caracteres ou menos)</label>
                        <textarea
                          className={`h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border-gray-200 border rounded`}
                          value={template}
                          onChange={handleTemplateChange}
                        />
                        <div className="pt-2">
                          {isTypingVariable ?
                            <span className="text-xs font-normal text-red-500 mt-2">{userMessage}</span>
                            :
                            <span className="text-xs font-normal text-gray-500 mt-2">Use chaves duplas para indicar onde você planeja usar conteúdo dinâmico. Por exemplo, para enviar "Seu código de cupom para Arkspace é 1234", o modelo seria: "Seu código de cupom para &#123;&#123;1&#125;&#125; é &#123;&#123;2&#125;&#125;.".</span>
                          }
                        </div>
                      </div>
                    }

                    <div className="flex items-center justify-end w-full">
                      {isEditting ? <button type="submit" onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none" disabled={loading}>Deletar</button> : null}

                      {isEditting ? null : <FormDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} message={template} variables={variables} handleVariableChange={handleVariableChange} handleForm={handleForm} isLoading={loading} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageTemplateForm;
