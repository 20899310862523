import { Navigate, Routes, Route } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import * as Pages from './pages';
import * as Permissions from './utils/permissions';

import { Footer } from './components';

import ScrollToTop from './utils/scrollToTop';

import { useAuth } from './contexts/authContext';
import { useAdmin } from './contexts/adminContext';
import { useArea } from './contexts/areaContext';

const App = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const { idTokenClaims } = useAuth();
  const { isAdmin } = useAdmin();
  const { currentArea } = useArea();

  if (isLoading) {
    return <Pages.Auth.Loading height={"min-h-screen"} />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const adminProtection = !isAdmin && currentArea === 'backoffice';
  const platformProtect = currentArea === 'platform' && !idTokenClaims?.client_id;

  if (!isAuthenticated || platformProtect || adminProtection) {
    return <Pages.Auth.ProtectedPage isAuthenticated={isAuthenticated} isLoading={isLoading} />;
  }

  return (
    <ScrollToTop>
      {isLoading ? <Pages.Auth.Loading height={"min-h-screen"} /> :
        <>
          <div className="flex overflow-hidden bg-white">
            <div id="main-content" className={`h-full w-full bg-gray-50 relative overflow-y-auto min-h-[770px] transition-width duration-300`}>

              <Routes>
                <Route path="/" element={currentArea === 'backoffice' ? <Pages.BackofficeArea.Homepage /> : <Pages.PlatformArea.Homepage />} />

                {/* Platform Area */}
                <Route path="/active-messages" element={<Pages.PlatformArea.ActiveMessages />} />
                <Route path="/active-messages/message-template" element={<Pages.PlatformArea.MessageTemplate.ListMessageTemplates />} />
                <Route path="/active-messages/message-template/create" element={<Pages.PlatformArea.MessageTemplate.AddMessageTemplate />} />
                <Route path="/active-messages/message-template/:id" element={<Pages.PlatformArea.MessageTemplate.EditMessageTemplate />} />
                <Route path="/active-messages/message-broadcast" element={<Pages.PlatformArea.SendMessage.List />} />
                <Route path="/active-messages/message-broadcast/:id" element={<Pages.PlatformArea.SendMessage.Visualize />} />
                <Route path="/active-messages/message-broadcast/send-message" element={<Pages.PlatformArea.SendMessage.MessageBroadcastForm />} />

                <Route path="/conversations" element={<Pages.PlatformArea.Conversations.ListConversations />} />

                <Route path="/settings" element={<Pages.PlatformArea.Settings.SettingsLayout />}>
                  <Route index element={<Pages.PlatformArea.Settings.UserSettings />} />
                  <Route path="password" element={<Pages.PlatformArea.Settings.PasswordSettings />} />
                  <Route path="integrations" element={<Pages.PlatformArea.Settings.IntegrationsSettings />} />
                  <Route path="integrations/whatsapp" element={<Pages.PlatformArea.Settings.WhatsappSettings />} />
                </Route>

                {/* Help Area */}
                <Route path="/help" element={<Pages.HelpArea.HelpLayout />}>
                  <Route index element={<Pages.HelpArea.HelpWelcome />} />
                  <Route path="content/:folderPath/:filePath" element={<Pages.HelpArea.MarkdownContent />} />
                </Route>

                {/* BackOffice Area */}
                <Route path="/clients" element={<Permissions.ListClientsWithAccess />} />
                <Route path="/clients/create" element={<Permissions.AddClientsWithAccess />} />
                <Route path="/clients/:id" element={<Permissions.EditClientsWithAccess />} />

                <Route path="/pipelines" element={<Permissions.ListPipelinesWithAccess />} />
                <Route path="/pipelines/create" element={<Permissions.AddPipelinesWithAccess />} />
                <Route path="/pipelines/:id" element={<Permissions.EditPipelinesWithAccess />} />
                <Route path="/tasks" element={<Permissions.ListTasksWithAccess />} />
                <Route path="/tasks/create" element={<Permissions.AddTasksWithAccess />} />
                <Route path="/tasks/:id" element={<Permissions.EditTasksWithAccess />} />

                <Route path="/collections" element={<Permissions.ListCollectionsWithAccess />} />
                <Route path="/collections/create" element={<Permissions.AddCollectionsWithAccess />} />
                <Route path="/collections/:id" element={<Permissions.EditCollectionsWithAccess />} />
                <Route path="/documents" element={<Permissions.ListDocumentsWithAccess />} />
                <Route path="/documents/create" element={<Permissions.AddDocumentsWithAccess />} />
                <Route path="/documents/:id" element={<Permissions.EditDocumentsWithAccess />} />
                <Route path="/embeddings/:id" element={<Permissions.EditEmbeddingsWithAccess />} />

                <Route path="/llm-models" element={<Permissions.ListLLMModelsWithAccess />} />
                <Route path="/llm-models/create" element={<Permissions.AddLLMModelsWithAccess />} />
                <Route path="/llm-models/:id" element={<Permissions.EditLLMModelsWithAccess />} />

                <Route path="/evaluations" element={<Permissions.ListPipelineFixturesWithAccess />} />
                <Route path="/evaluations/create" element={<Permissions.AddPipelineFixturesWithAccess />} />
                <Route path="/evaluations/:id" element={<Permissions.EditPipelineFixturesWithAccess />} />

                <Route path="/metrics" element={<Permissions.ListMetricsWithAccess />} />
                <Route path="/metrics/create" element={<Permissions.AddMetricsWithAccess />} />
                <Route path="/metrics/:id" element={<Permissions.EditMetricsWithAccess />} />

                <Route path="/bo-conversations" element={<Permissions.ListConversationsWithAccess />} />
                <Route path="/bo-conversations/:sessionId" element={<Permissions.ConversationDetailsWithAccess />} />

                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </>
      }
    </ScrollToTop >
  );
}

export default App;
