import { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from 'uuid';
import * as yaml from 'js-yaml';

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Collapse from '@mui/material/Collapse';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Alert from "@mui/material/Alert";
import Typography from '@mui/material/Typography';

import { useAuth } from "../../../contexts/authContext";

import { toastErrors } from '../../../utils/toastErrors';
import { LoadingDots } from '../../../components/LoadingDots';
import { Toggle } from "../../../components/Toogle";
import { ConfirmationModal, CustomCombobox, CustomListbox, FormModal } from "../../../components";

import { ListTasks } from "./tasks";

import { PipelineInterface, ClientInterface, TaskInterface } from "../../../utils/types";
import { formatDateTime } from "../../../utils/formatDate";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai.js";
import "ace-builds/src-noconflict/mode-yaml.js";
import "ace-builds/src-noconflict/mode-json.js";
import "ace-builds/src-noconflict/keybinding-vscode.js";
import "ace-builds/src-noconflict/ext-language_tools.js";

const ace = require("ace-builds/src-noconflict/ace");
ace.config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/"
);
ace.config.setModuleUrl(
  "ace/mode/yaml_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-yaml.js"
);
ace.config.setModuleUrl(
  "ace/mode/json_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-json.js"
);

interface PipelinesFormInterface {
  pipeline?: PipelineInterface,
  formName: string,
}

interface EnvInterface {
  id: string,
  name: string,
  value: string
}

const PipelinesForm = ({ pipeline, formName }: PipelinesFormInterface) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');


  const [name, setName] = useState(pipeline?.name);
  const [cloneName, setCloneName] = useState(pipeline?.name + ' (1)');
  const [description, setDescription] = useState(pipeline?.description);
  const [functionPath, setFunctionPath] = useState(pipeline?.function_path);
  const [enabled, setEnabled] = useState(isEditting ? pipeline!.enabled : true)
  const [openAgentConfigCollapse, setOpenAgentConfigCollapse] = useState(isEditting && pipeline?.agent ? true : false);
  const [clients, setClients] = useState<ClientInterface[] | null>(null);
  const [yamlError, setYamlError] = useState<any>();
  const [agent_config, setAgentConfig] = useState(pipeline?.agent_config);
  const [agentEnabled, setAgentEnabled] = useState(isEditting ? pipeline?.agent : false);
  const [selectedClient, setSelectedClient] = useState(isEditting ? pipeline?.client : null);
  const [clientQuery, setClientQuery] = useState('');

  const [envList] = useState<EnvInterface[]>([{ id: '1', name: 'Desenvolvimento', value: 'development' }, { id: '2', name: 'Staging', value: 'staging' }, { id: '3', name: 'Produção', value: 'production' }]);
  const [selectedEnv, setSelectedEnv] = useState<EnvInterface | null>(
    pipeline?.env ? envList.find(envL => envL.value.toLowerCase() === pipeline?.env) || envList[0] : envList[0]
  );

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [openForm, setOpenForm] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/pipelines">Pipelines</Link>,
    <Typography key="3" color="text.primary">{isEditting ? pipeline?.name : 'Cadastrar pipeline'}</Typography>
  ];

  useEffect(() => {
    if (!clients) getClients();
  });

  const getClients = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setClients(resJson.clients);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/pipeline/delete/${pipeline?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/pipelines');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    if (agentEnabled) {
      try {
        yaml.load(agent_config!);
      } catch (err: any) {
        setYamlError(err.message);
        setLoading(false);
        toast.dismiss();
        return;
      }
    }

    const form = {
      name,
      function_path: functionPath,
      description,
      enabled,
      agent: agentEnabled,
      agent_config,
      env: selectedEnv?.value,
      client_id: selectedClient?.id
    };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/pipeline${isEditting ? `/${pipeline?.id}` : ''}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/pipelines');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleAgentConfigCollapse = () => {
    setOpenAgentConfigCollapse(!openAgentConfigCollapse);
  };

  const getTasks = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task?pipeline_id=${pipeline?.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      return resJson.tasks;
    } catch (error) {
      console.log(error);
    }
  };

  async function handleCloneForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    if (cloneName === name) {
      setLoading(false);
      toast.update(toastId, {
        render: 'Nome deve ser diferente do atual.',
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true
      });
      return;
    }

    const tasks = await getTasks();
    const preparedTasks = tasks.map((task: TaskInterface) => ({
      name: task.name,
      prompt: task.prompt,
      version: 1,
      prompt_template_id: uuid(),
      enabled: task.enabled,
      k_contexts: task.k_contexts,
      function_definition: task.function_definition,
      collection_id: task.collection?.id,
      pipeline_id: task.pipeline.id,
      llm_model_id: task.llm_model.id
    }));

    const form = {
      name: cloneName,
      function_path: functionPath,
      description: description + ' CLONE',
      agent: agentEnabled,
      agent_config: agent_config,
      enabled,
      client_id: selectedClient?.id,
      tasks: preparedTasks
    };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/pipeline/clone`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: 'Clonado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/pipelines');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderAlert = () => {
    const env = pipeline?.env;

    if (env === 'staging') return <Alert severity={'warning'}>Esse pipeline está sendo utilizado em ambiente de teste.</Alert>;
    if (env === 'development') return <Alert severity={'warning'} color={'info'}>Esse pipeline está sendo utilizado em ambiente de desenvolvimento.</Alert>;
    return <Alert severity={'warning'} color={'error'}>Esse pipeline está sendo utilizado em ambiente de produção.</Alert>;
  };

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="py-4 md:flex items-center justify-between">
          <div className="w-full md:w-1/2 flex flex-col">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">{formName} pipeline</h3>
            <span className="text-base font-normal text-gray-500">Certifique-se de que o pipeline está funcionando corretamente antes de {formName.toLowerCase()}</span>
          </div>

          <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
            <Toggle enabled={enabled} setEnabled={(newVal) => setEnabled(newVal)} />
          </div>
        </div>

        {isEditting ? <div className="mt-4 -mb-1">
          {renderAlert()}
        </div> : null}

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-6 xl:p-8">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-bold text-gray-900">Configurações Agent</h3>
              </div>
              <div>
                <IconButton onClick={handleAgentConfigCollapse}>
                  <ExpandMoreIcon className={openAgentConfigCollapse ? 'transform rotate-180' : ''} />
                </IconButton>
              </div>
            </div>

            <Collapse in={openAgentConfigCollapse}>
              <div className={`flex items-center mt-8 ${!agentEnabled ? 'pb-6' : 'pb-0'}`}>
                <label className="font-semibold leading-none mr-4">Agent</label>
                <Toggle enabled={agentEnabled ? agentEnabled : false} setEnabled={setAgentEnabled} />
              </div>
              {agentEnabled ? <div className="flex flex-col mt-8 pb-6">
                <div className="overflow-x-auto rounded-lg">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden sm:rounded-lg">
                      <div className="w-full flex flex-col">
                        <AceEditor
                          placeholder="..."
                          mode="yaml"
                          theme="monokai"
                          name="_unique_"
                          fontSize={14}
                          width="100%"
                          keyboardHandler="vscode"
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={!agent_config ? '' : agent_config}
                          onChange={(e) => { setAgentConfig(e) }}
                          editorProps={{ $blockScrolling: true }}
                          minLines={35}
                          maxLines={35}
                          setOptions={{
                            useWorker: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                          }} />
                      </div>

                      {yamlError ? <div className="flex flex-col p-8 my-4 bg-red-400 rounded-md">
                        {yamlError}
                      </div> : null}
                    </div>
                  </div>
                </div>
              </div> : null}
            </Collapse>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-6 xl:p-8">
            <div className="md:flex items-center justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Informações gerais</h3>
              </div>
            </div>

            <div className="flex flex-col mt-6">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">

                    <ConfirmationModal action={action} entity="pipeline" open={open} setOpen={setOpen} handleAction={action === 'Deletar' ? handleDelete : handleForm} />
                    <FormModal open={openForm} setOpen={setOpenForm} name={cloneName} handleNameChange={setCloneName} handleAction={handleCloneForm} />

                    <div className="md:flex items-center mt-3">
                      <div className="w-full md:w-1/2  flex flex-col">
                        <label className="font-semibold leading-none">Nome</label>
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200`}
                          value={!name ? '' : name}
                          onChange={e => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                        <label className="font-semibold leading-none">Ambiente</label>

                        <div className="mt-3 z-20">
                          <CustomListbox list={envList} selected={selectedEnv} setSelected={setSelectedEnv} placeholder='Selecione ambiente' />
                        </div>
                      </div>
                    </div>

                    <div className="md:flex items-center mt-8">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold leading-none">Caminho da função</label>
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200`}
                          value={!functionPath ? '' : functionPath}
                          onChange={e => setFunctionPath(e.target.value)}
                          required
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                        <label className="font-semibold leading-none">Cliente</label>

                        {isEditting ?
                          <input
                            type="text"
                            className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                            value={pipeline?.client.name}
                            disabled
                          />
                          :
                          <div className="mt-3 z-10">
                            <CustomCombobox entity="cliente" list={clients} selected={selectedClient} setSelected={setSelectedClient} query={clientQuery} setQuery={setClientQuery} emptyState={false} />
                          </div>
                        }
                      </div>
                    </div>

                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Descrição</label>
                      <textarea
                        className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!description ? '' : description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>

                    {isEditting ? <div className="mt-4">
                      <span className="text-sm opacity-50">Última atualização: {pipeline?.updated_at ? formatDateTime(pipeline?.updated_at!) : 'não teve atualização!'}</span>
                    </div> : null}

                    <div className="flex items-center justify-end w-full">
                      {isEditting ? <button onClick={() => { setOpenForm(true); }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-blue-700 rounded hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:outline-none">Clonar</button> : null}
                      {isEditting ? <button onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none">Deletar</button> : null}

                      <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                        {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditting ? <ListTasks pipeline={pipeline} /> : null}
    </>
  )
}

export default PipelinesForm;