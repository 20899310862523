import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import { useAuth } from '../../../contexts/authContext';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { toastErrors } from '../../../utils/toastErrors';
import { LoadingDots } from '../../../components/LoadingDots';
import { ConfirmationModal } from '../../../components';

import { CustomListbox } from "../../../components";

import { LLMModelInterface } from '../../../utils/types';
import { formatDateTime } from '../../../utils/formatDate';

interface LLMModelFormInterface {
  llmModel?: LLMModelInterface,
  formName: string
}

interface ProviderlInterface {
  id: string,
  name: string
}

const LLMModelsForm = ({ llmModel, formName }: LLMModelFormInterface) => {
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');

  const [name, setName] = useState(llmModel?.name);
  const [description, setDescription] = useState(llmModel?.description);
  const [baseUrl, setBaseUrl] = useState(llmModel?.base_url);
  const [inputPrice, setInputPrice] = useState(llmModel?.input_price ? llmModel?.input_price.toString() : '');
  const [outputPrice, setOutputPrice] = useState(llmModel?.output_price ? llmModel?.output_price.toString() : '');

  const [providerList] = useState<ProviderlInterface[]>([{ id: '1', name: 'azure-openai' }, { id: '2', name: 'openai' }, { id: '3', name: 'bedrock-claude' }, { id: '4', name: 'together' }, { id: '5', name: 'proxy' }]);
  const [selectedProvider, setSelectedProvider] = useState<ProviderlInterface | null>(
    llmModel?.provider ? providerList.find(providerL => providerL.name.toLowerCase() === llmModel?.provider) || null : null
  );

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/llm-models">Modelos LLM</Link>,
    <Typography key="3" color="text.primary">{isEditting ? llmModel?.name : 'Cadastrar modelo LLM'}</Typography>
  ];

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const form = { name, description, input_price: parseFloat(inputPrice) || null, output_price: parseFloat(outputPrice) || null, provider: selectedProvider?.name.toLowerCase(), base_url: baseUrl?.toLowerCase() || null};

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/llm-model${isEditting ? `/${llmModel?.id}` : ''}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/llm-models');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="pt-6 px-4">

      <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 md:flex items-center mt-3 justify-between">
            <div className="w-full md:w-1/2 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 mb-2">{formName} modelo LLM</h3>
              <span className="text-base font-normal text-gray-500">{isEditting ? 'Tenha ciência de que todas as tasks que utilizam esse modelo serão afetadas ao editar' : 'Garanta que o modelo que está sendo cadastrado de fato existe em algum provedor'}</span>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">

                  <ConfirmationModal action={action} entity='modelo LLM' open={open} setOpen={setOpen} handleAction={handleForm} />

                  <div className="md:flex items-center mt-3">
                    <div className="w-full md:w-1/3 flex flex-col">
                      <label className="font-semibold leading-none">Nome</label>
                      <input
                        type="text"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!name ? '' : name}
                        onChange={e => setName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="w-full md:w-1/3 flex flex-col md:ml-6 md:mt-0 mt-8">
                      <label className="font-semibold leading-none mb-3">Provedor</label>
                      <CustomListbox list={providerList} selected={selectedProvider} setSelected={setSelectedProvider} placeholder='Selecione provedor' />
                    </div>

                    <div className="w-full md:w-1/3 flex flex-col md:ml-6 md:mt-0 mt-8">
                      <label className="font-semibold leading-none">Base URL</label>
                      <input
                        type="text"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!baseUrl ? '' : baseUrl}
                        onChange={e => setBaseUrl(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="md:flex items-center mt-8">
                    <div className="w-full md:w-1/2 flex flex-col">
                      <label className="font-semibold leading-none">Preço input</label>
                      <input
                        type="text"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={inputPrice ? inputPrice.toString() : ''}
                        onChange={e => {
                          const value = e.target.value;
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            // Update state only if the input is empty or matches the pattern
                            setInputPrice(value);
                          }
                        }}
                        required
                      />
                    </div>

                    <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                      <label className="font-semibold leading-none">Preço output</label>
                      <input
                        type="text"
                        className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={outputPrice ? outputPrice.toString() : ''}
                        onChange={e => {
                          const value = e.target.value;
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            // Update state only if the input is empty or matches the pattern
                            setOutputPrice(value);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Descrição</label>
                      <textarea
                        className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!description ? '' : description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  {isEditting ? <div className="mt-4">
                    <span className="text-sm opacity-50">Última atualização: {llmModel?.updated_at ? formatDateTime(llmModel?.updated_at!) : 'não teve atualização!'}</span>
                  </div> : null}

                  <div className="flex items-center justify-end w-full">
                    <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                      {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LLMModelsForm;