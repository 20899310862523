import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import Skeleton from '@mui/material/Skeleton';

import { ModelRequest } from "../../../utils/types";
import { formatDateTime } from '../../../utils/formatDate';

interface ViewTracelogI {
  tracelog: ModelRequest[] | null;
  isLoading: boolean;
}

const ViewTracelog = ({ tracelog, isLoading }: ViewTracelogI) => {
  return (
    <div className='bg-white shadow border rounded-lg w-full'>
      <div className={`${tracelog?.length !== 0 ? '' : 'flex justify-center'} h-[36.5rem] p-8 overflow-y-auto`}>
        {!isLoading ? tracelog?.length !== 0 ?
          <ol className="relative border-s border-gray-200">
            {tracelog?.map((details, index) => (
              <li className={`${index === tracelog.length - 1 ? 'mb-0' : 'mb-10'} ms-4 -mr-5 md:mr-0`} key={index}>
                <div className="absolute w-3 h-3 bg-gray-300 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-500">{formatDateTime(details.created_at)}</time>
                <h3 className="text-lg font-semibold text-gray-900">{details.id}</h3>

                <div className="mt-3">
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">input_tokens:</p>
                    <p className="text-sm font-normal col-span-2">{details.input_tokens}</p>
                  </div>
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">completion_tokens:</p>
                    <p className="text-sm font-normal col-span-2">{details.completion_tokens}</p>
                  </div>
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">tokens:</p>
                    <p className="text-sm font-normal col-span-2">{details.tokens}</p>
                  </div>
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">task_id:</p>
                    <p className="text-sm font-normal col-span-2">{details.task_id}</p>
                  </div>
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">provider_response_id:</p>
                    <p className="text-sm font-normal col-span-2">{details.provider_response_id}</p>
                  </div>
                  <div className="flex flex-col sm:grid grid-cols-3 mt-1">
                    <p className="text-sm font-normal text-gray-500">message_request_id:</p>
                    <p className="text-sm font-normal col-span-2">{details.message_request_id}</p>
                  </div>
                  <div className="grid grid-cols-3 mt-4 bg-gray-200 border border-gray-400 p-4">
                    <p className="text-sm font-normal text-gray-500">input:</p>
                    <p className="text-sm font-normal col-span-2">{details.input}</p>
                  </div>
                  <div className="grid grid-cols-3 mt-4 bg-gray-200 border border-gray-400 p-4">
                    <p className="text-sm font-normal text-gray-500">output:</p>
                    <p className="text-sm font-normal col-span-2">{details.output}</p>
                  </div>
                </div>
              </li>
            ))}
          </ol>
          :
          <div className="flex flex-col items-center justify-center">
            <div className="text-center">
              <TroubleshootOutlinedIcon fontSize='large' />
              <h1 className="mt-2 text-xl font-semibold text-gray-800">Nenhuma mensagem selecionada.</h1>
              <p className="mt-2 text-base text-gray-500">Selecione uma mensagem para visualizar o Tracelog dela!</p>
            </div>
          </div>
          :
          <div className='w-full'>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', marginTop: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rounded" height={125} sx={{ marginTop: '1rem' }} />
            <Skeleton variant="rounded" height={125} sx={{ marginTop: '1rem' }} />
          </div>
        }
      </div>
    </div>
  );
}

export default ViewTracelog;
