import { useState } from "react";
import { Link } from "react-router-dom";

import { UUID } from "crypto";

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useFetchTests from "../../../hooks/getTestStatus";

interface ListRunningTestsI {
  pipeline_id: UUID | undefined;
}

const ListRunningTests = ({ pipeline_id }: ListRunningTestsI) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const { backgroundTasks } = useFetchTests(`${window.REACT_APP_API_ENDPOINT}/api/eval/evaluation-status/pipeline/${pipeline_id}`, openCollapse);

  const statusTextColor = (status: string) => {
    if (status === 'PENDING' || status === 'PROGRESS') return 'text-yellow-400';
    if (status === 'SUCCESS') return 'text-green-400';
    return 'text-red-400';
  }

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <div className="my-4 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">Testes em execução/executados</h3>
            </div>
            <div>
              <IconButton onClick={handleCollapse}>
                <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
              </IconButton>
            </div>
          </div>

          <Collapse in={openCollapse}>
            <span className="text-base font-normal text-gray-500">Listagem dos testes que já foram ou estão sendo executados</span>

            <div className="flex flex-col mt-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Background Task Id
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Pass rate
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Mlflow run_id
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Error
                          </th>
                          <th scope="col" className="tracking-wider" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {backgroundTasks?.running_tasks.length !== 0 ? backgroundTasks?.running_tasks.map((test, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className={`p-4 whitespace-nowrap text-sm font-normal ${statusTextColor(test.status)}`}>
                              {test.status}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {test.task_id}
                            </td>
                            <td className={`p-4 whitespace-nowrap text-sm font-normal`}>
                              {test.pass_rate ? test.pass_rate : null}
                            </td>
                            <td className={`p-4 whitespace-nowrap text-sm font-bold`}>
                              {test.mlflow_run_id && test.experiment_id ? <Link to={`https://mlflow.arkspace-api.com/#/experiments/${test.experiment_id}/runs/${test.mlflow_run_id}`} target="_blank" rel="noopener noreferrer" className="hover:text-[#2c2c50] hover:underline">{test.mlflow_run_id}</Link> : null}
                            </td>
                            <td className={`p-4 whitespace-nowrap text-sm font-normal`}>
                              {test.status === 'ERROR' && test.error ? test.error : test.status === 'FAILURE' && test.exc ? test.exc : null}
                            </td>
                          </tr>
                        )) :
                          <tr>
                            <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                              <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690257804/jjqw2hfv0t6karxdeq1s.svg" alt="empty states" />
                              <p className="text-gray-700 font-medium text-lg text-center">Nenhum teste em execução.</p>
                              <p className="text-gray-500 text-center">Teste aparecerá aqui se ele estiver sendo executado ou se ele já concluiu ou falhou o processo.</p>
                            </td>
                          </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default ListRunningTests;