import { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UUID } from "crypto";
import * as yaml from 'js-yaml';
import { v4 as uuid } from 'uuid';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from "../../../../contexts/authContext";

import { toastErrors } from "../../../../utils/toastErrors";
import { formatDateTime } from "../../../../utils/formatDate";
import { LoadingDots } from "../../../../components/LoadingDots";
import { Toggle } from "../../../../components/Toogle";
import { ConfirmationModal } from "../../../../components";

import { CustomCombobox } from "../../../../components";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import ListOldVersionsTasks from "./ListOldVersions";

import { PipelineInterface, TaskInterface, CollectionInterface, LLMModelInterface } from "../../../../utils/types";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai.js";
import "ace-builds/src-noconflict/mode-yaml.js";
import "ace-builds/src-noconflict/mode-json.js";
import "ace-builds/src-noconflict/keybinding-vscode.js";
import "ace-builds/src-noconflict/ext-language_tools.js";

const ace = require("ace-builds/src-noconflict/ace");
ace.config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/"
);
ace.config.setModuleUrl(
  "ace/mode/yaml_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-yaml.js"
);
ace.config.setModuleUrl(
  "ace/mode/json_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-json.js"
);

interface TasksFormInterface {
  client_id: UUID,
  pipeline: PipelineInterface,
  task?: TaskInterface,
  formName: string,
}

const TasksForm = ({ client_id, pipeline, task, formName }: TasksFormInterface) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');
  const [hasOtherChanges, setHasOtherChanges] = useState(false);

  const [name, setName] = useState(task?.name);
  const [prompt, setPrompt] = useState(task?.prompt);
  const [version] = useState(task?.version);
  const [kContexts, setKContexts] = useState(task?.k_contexts?.toString());
  const [functionDefinition, setFunctionDefinition] = useState(task?.function_definition);
  const [enabled, setEnabled] = useState<boolean>(isEditting ? task!.enabled : true);
  const [json_mode, setJsonMode] = useState<boolean>(isEditting ? task!.json_mode : true);
  

  const [collections, setCollections] = useState<CollectionInterface[] | null>(null);
  const [pipelines, setPipelines] = useState<PipelineInterface[] | null>(null);
  const [llmModels, setLLMModels] = useState<LLMModelInterface[] | null>(null);

  const [collectionSelected, setCollectionSelected] = useState(isEditting ? task?.collection : null);
  const [collectionQuery, setCollectionQuery] = useState('');

  const [pipelineSelected, setPipelineSelected] = useState<PipelineInterface | null>(pipeline);
  const [pipelineQuery, setPipelineQuery] = useState('');

  const [llmModelSelected, setLlmModelSelected] = useState(isEditting ? task?.llm_model : null);
  const [llmModelQuery, setLlmModelQuery] = useState('');

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [openPromptCollapse, setOpenPromptCollapse] = useState(isEditting && task?.prompt ? true : false);
  const [openFCCollapse, setOpenFCCollapse] = useState(isEditting && task?.function_definition ? true : false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [yamlError, setYamlError] = useState<any>();
  const [tooltipHidden, setToolTip] = useState<boolean>(true);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/pipelines">Pipelines</Link>,
    <Link className="hover:underline" key="3" color="inherit" to={`/pipelines/${pipeline.id}`} state={{ pipeline: pipeline }}>{pipeline.name}</Link>,
    <Typography key="3" color="text.primary">{isEditting ? task?.name : 'Cadastrar task'}</Typography>
  ];

  useEffect(() => {
    if (!collections) getCollections();
    if (!pipelines) getPipelines();
    if (!llmModels) getLLMModels();
  });

  const getCollections = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/collection?client_id=${client_id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setCollections(resJson.collections);
    } catch (error) {
      console.log(error);
    }
  };

  const getPipelines = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/pipeline?client_id=${client_id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setPipelines(resJson.pipelines);
    } catch (error) {
      console.log(error);
    }
  };

  const getLLMModels = async (offset = 0) => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/llm-model`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setLLMModels(resJson.llm_models);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task/delete/${task?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/pipelines/${pipeline.id}`, { state: { pipeline: pipeline } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleReset() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task/reset/${task?.prompt_template_id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Resetado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/pipelines/${pipeline.id}`, { state: { pipeline: pipeline } });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleVersion = (version: number) => {
    if (isEditting && hasOtherChanges) return version + 1;
    else if (!version) return 1;
    return version;
  };

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    if (llmModelSelected?.provider === 'openai') {
      try {
        yaml.load(prompt!);
      } catch (err: any) {
        setYamlError(err.message);
        setLoading(false);
        toast.dismiss();
        return;
      }
    }

    const form = {
      name,
      prompt,
      llm_model_id: llmModelSelected?.id,
      version: handleVersion(version!),
      enabled,
      k_contexts: kContexts && collectionSelected ? parseInt(kContexts!) : null,
      function_definition: functionDefinition,
      json_mode: json_mode,
      prompt_template_id: isEditting ? task?.prompt_template_id : uuid(),
      pipeline_id: pipelineSelected?.id,
      collection_id: collectionSelected?.id
    };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task${!hasOtherChanges ? `/${task?.id}` : ''}`, {
        method: `${!hasOtherChanges ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/pipelines/${pipeline.id}`, { state: { pipeline: pipeline } });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePromptCollapse = () => {
    setOpenPromptCollapse(!openPromptCollapse);
  };

  const handleFCCollapse = () => {
    setOpenFCCollapse(!openFCCollapse);
  };

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="py-4 md:flex items-center justify-between">
          <div className="w-full md:w-1/2 flex flex-col">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">{formName} task</h3>
            <span className="text-base font-normal text-gray-500">Certifique-se de que a task está funcionando corretamente antes de {formName.toLowerCase()}.</span>
          </div>

          <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
            <Toggle enabled={enabled} setEnabled={(newVal) => setEnabled(newVal)} />
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-6 pb-8 xl:p-8 xl:pb-12">

            <div className="md:flex items-center justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900">Informações gerais</h3>
              </div>
            </div>

            <div className="flex flex-col mt-5">
              <div className="align-middle inline-block min-w-full">

                <ConfirmationModal action={action} entity="task" open={open} setOpen={setOpen} handleAction={() => {
                  if (action === 'Resetar') {
                    handleReset();
                  } else if (action === 'Deletar') {
                    handleDelete();
                  } else if (action === 'Atualizar') {
                    handleForm();
                  }
                }} />

                <div className="md:flex items-center mt-3">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <label className="font-semibold leading-none">Nome</label>
                    <input
                      type="text"
                      className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200`}
                      value={!name ? '' : name}
                      onChange={e => { setName(e.target.value); setHasOtherChanges(true); }}
                      required
                    />
                  </div>

                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                    <label className="font-semibold leading-none">Modelo</label>
                    <div className="mt-3 z-30">
                      <CustomCombobox entity="LLM model" list={llmModels} selected={llmModelSelected} setSelected={(e) => { setLlmModelSelected(e); setHasOtherChanges(true) }} query={llmModelQuery} setQuery={setLlmModelQuery} emptyState={false} />
                    </div>
                  </div>
                </div>

                <div className="md:flex items-center mt-8">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <label className="font-semibold leading-none">Collection</label>
                    <div className="mt-3 z-20">
                      <CustomCombobox entity="collection" list={collections} selected={collectionSelected} setSelected={(e) => { setCollectionSelected(e); setHasOtherChanges(true) }} query={collectionQuery} setQuery={setCollectionQuery} />
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                    <label className="font-semibold leading-none">Pipeline</label>

                    {!isEditting ?
                      <input
                        type="text"
                        className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                        value={pipeline?.name}
                        disabled
                      />
                      :
                      <div className="mt-3 z-10">
                        <CustomCombobox entity="pipeline" list={pipelines} selected={pipelineSelected} setSelected={setPipelineSelected} query={pipelineQuery} setQuery={setPipelineQuery} emptyState={false} />
                      </div>
                    }
                  </div>
                  
                  <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                    <label className="font-semibold leading-none">Json Mode</label>
                    <div className="mt-3 z-20">
                    <Toggle enabled={json_mode} setEnabled={(newVal) => setJsonMode(newVal)} />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-6 xl:p-8">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-bold text-gray-900">Prompt</h3>
              </div>
              <div>
                <IconButton onClick={handlePromptCollapse}>
                  <ExpandMoreIcon className={openPromptCollapse ? 'transform rotate-180' : ''} />
                </IconButton>
              </div>
            </div>

            <Collapse in={openPromptCollapse}>
              <div className="flex flex-col mt-8">
                <div className="overflow-x-auto rounded-lg">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden sm:rounded-lg">
                      <div className="w-full flex flex-col">
                        <AceEditor
                          placeholder="..."
                          mode="yaml"
                          theme="monokai"
                          name="_unique_"
                          fontSize={14}
                          width="100%"
                          keyboardHandler="vscode"
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={!prompt ? '' : prompt}
                          onChange={(e) => { setPrompt(e); setHasOtherChanges(true) }}
                          editorProps={{ $blockScrolling: true }}
                          minLines={35}
                          maxLines={35}
                          setOptions={{
                            useWorker: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                          }} />
                      </div>

                      {yamlError ? <div className="flex flex-col p-8 my-4 bg-red-400 rounded-md">
                        {yamlError}
                      </div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-bold text-gray-900">Function Calling</h3>
              </div>
              <div>
                <IconButton onClick={handleFCCollapse}>
                  <ExpandMoreIcon className={openFCCollapse ? 'transform rotate-180' : ''} />
                </IconButton>
              </div>
            </div>

            <Collapse in={openFCCollapse}>
              <div className="flex flex-col mt-8">
                <div className="overflow-x-auto rounded-lg">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden sm:rounded-lg">
                      <div className="w-full flex flex-col">
                        <div className="w-full flex flex-col">
                          <AceEditor
                            placeholder="..."
                            mode="json"
                            theme="monokai"
                            name="_unique_"
                            fontSize={14}
                            width="100%"
                            height="400px"
                            keyboardHandler="vscode"
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={!functionDefinition ? '' : functionDefinition}
                            onChange={(e) => { setFunctionDefinition(e); setHasOtherChanges(true) }}
                            editorProps={{ $blockScrolling: true }}
                            // minLines={35}
                            // maxLines={35}
                            setOptions={{
                              useWorker: false,
                              enableLiveAutocompletion: true,
                              enableSnippets: true,
                              showLineNumbers: true,
                              tabSize: 2,
                            }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg pb-7 px-6 xl:pb-8 xl:px-8">
            <div className="flex flex-col mt-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    <div className="w-full flex flex-col">

                      <div className="md:flex items-center">
                        <div className="w-full md:w-2/12 flex flex-col">
                          <label className="font-semibold leading-none">K contexts</label>
                          <input
                            type="number"
                            className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 ${!collectionSelected ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200`}
                            value={!kContexts ? '' : kContexts}
                            onChange={e => { setKContexts(e.target.value); setHasOtherChanges(true); }}
                            disabled={!collectionSelected ? true : false}
                          />
                        </div>

                        <div className="w-1/2 md:w-2/12 flex flex-col md:ml-6 mt-6 md:mt-0">
                          <label className="font-semibold leading-none">Versão</label>
                          <input
                            type="number"
                            className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 border rounded border-gray-200 cursor-not-allowed"
                            disabled={true}
                            value={!version ? '' : version}
                          />
                        </div>

                        <div tabIndex={0} className="focus:outline-none focus:ring-gray-300 rounded-full focus:ring-offset-2 focus:ring-2 relative -mt-9 ml-28 md:mt-7 md:ml-3 invisible md:visible" onMouseOver={() => setToolTip(false)} onFocus={() => setToolTip(false)} onMouseOut={() => setToolTip(true)}>
                          <div className=" cursor-pointer">
                            <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <circle cx="12" cy="12" r="9" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                              <polyline points="11 12 12 12 12 16 13 16" />
                            </svg>
                          </div>
                          <div id="tooltip1" role="tooltip" className={`z-20 md:-mt-16 w-64 absolute transition duration-150 ease-in-out left-0 ml-10 shadow-lg bg-white p-4 rounded ${tooltipHidden ? 'hidden' : ''}`}>
                            <svg className="absolute left-0 -ml-2 bottom-0 top-0 h-full" width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFFFFF">
                                  <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                                    <g id="Group-2" transform="translate(24.000000, 0.000000)">
                                      <polygon id="Triangle" transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) " points="4.5 57.5 12.5 66.5 -3.5 66.5"></polygon>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <p className="text-sm font-bold text-gray-800 pb-1">Como funciona a versão?</p>
                            <p className="text-xs leading-4 text-gray-600 pb-3">Ao atualizar a task uma nova versão é gerada automaticamente.</p>
                          </div>
                        </div>
                      </div>

                      {isEditting ? <div className="mt-4">
                        <span className="text-sm opacity-50">Última atualização: {formatDateTime(task?.created_at!)}</span>
                      </div> : null}

                      <div className="flex items-center justify-end w-full">
                        {isEditting ? <button onClick={() => { setOpen(true); setAction('Resetar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-orange-700 rounded hover:bg-orange-600 focus:ring-2 focus:ring-offset-2 focus:ring-orange-700 focus:outline-none">Resetar</button> : null}
                        {isEditting ? <button onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none">Deletar</button> : null}

                        <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                          {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                        </button>
                      </div>
                    </div>
                  </div>

                  {yamlError ? <div className="flex flex-col p-8 my-4 bg-red-400 rounded-md">
                    {yamlError}
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >

      {isEditting ? <ListOldVersionsTasks prompt_template_id={task?.prompt_template_id} /> : null
      }
    </>
  )
}

export default TasksForm;