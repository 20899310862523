import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import { Alert, Tooltip } from "@mui/material";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useAuth } from "../../../contexts/authContext";

import { CustomCombobox, CustomListbox } from "../../../components";

import { CollectionInterface, ClientInterface } from "../../../utils/types";

interface EnabledInterface {
  id: string,
  name: string,
  value: boolean
}

const ListCollections = () => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [collections, setCollections] = useState<[CollectionInterface]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filteredName, setFilteredName] = useState<string | null>(null);

  const [selectedClient, setSelectedClient] = useState<ClientInterface | null>(null);
  const [query, setQuery] = useState('');
  const [clients, setClients] = useState<ClientInterface[] | null>(null);

  const [enabledList] = useState<EnabledInterface[]>([{ id: '1', name: 'Sim', value: true }, { id: '2', name: 'Não', value: false }]);
  const [selectedEnabled, setSelectedEnabled] = useState<EnabledInterface | null>(null);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/collection?offset=${offset}&limit=10`;
    if (selectedClient) url = url + `&client_id=${selectedClient?.id}`;
    if (selectedEnabled) url = url + `&enabled=${selectedEnabled?.value}`;
    if (filteredName) url = url + `&name=${filteredName}`;
    return url;
  }, [selectedClient, selectedEnabled, filteredName]);

  const getCollections = useCallback(async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        const resCollections = resJson.collections;
        setCollections(resCollections);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token]);

  const getClients = async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client?offset=${offset}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setClients(resJson.clients);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCollections();
    if (!clients) getClients();
  }, [getCollections, selectedEnabled, selectedClient, clients]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getCollections((value - 1) * 10);
  };

  return (
    <div className="pt-6 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 flex flex-col justify-between items-start">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Collections</h3>
            <span className="text-base font-normal text-gray-500">Listagem com as collections das empresas clientes do Chatspace</span>
          </div>

          <Link to="/collections/create" className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
            Cadastrar collection
            <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
            </svg>
          </Link>

          <div className="mt-4 flex flex-col justify-between items-center md:flex-row">
            <div className="flex flex-row w-full md:w-1/2 md:pr-4 lg:w-1/3">
              <div className="relative w-full">
                <input
                  type="search"
                  className={`w-full leading-none text-gray-900 pl-11 py-3 shadow focus:outline-none focus:border-[#11111f] bg-white border rounded border-gray-200 font-medium`}
                  placeholder="Pesquisar por nome..."
                  value={!filteredName ? '' : filteredName}
                  onChange={(e) => setFilteredName(e.target.value)}
                />
                <div className="absolute top-0 left-0 inline-flex items-center p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                    strokeLinejoin="round">
                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-end w-full mt-4 md:w-1/2 md:mt-0">
              <div className="w-full md:w-4/12 mr-4">
                <CustomCombobox entity="cliente" list={clients} selected={selectedClient} setSelected={setSelectedClient} query={query} setQuery={setQuery} emptyState={false} />
              </div>

              <div className="w-full md:w-4/12">
                <CustomListbox list={enabledList} selected={selectedEnabled} setSelected={setSelectedEnabled} placeholder="Habilitado" />
              </div>

              {selectedEnabled || selectedClient ? <div className="ml-2 mt-2 flex-shrink-0">
                <button onClick={() => { setSelectedClient(null); setSelectedEnabled(null); }} className="text-sm font-medium text-[#292c31] hover:bg-gray-100 rounded-lg p-2">Limpar</button>
              </div> : null}
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="tracking-wider" />
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nome
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Habilitado
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Cliente
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {collections?.map((collection, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-2 whitespace-nowrap text-sm font-normal text-gray-500 text-center">
                            <div className="flex flex-row text-center">
                              <Tooltip arrow placement="top" title={`${collection.env === 'development' ? 'Desenvolvimento' : collection.env === 'staging' ? 'Staging' : 'Produção'}`}>
                                <Alert icon={false} severity={'warning'} color={`${collection.env === 'development' ? 'info' : collection.env === 'staging' ? 'warning' : 'error'}`}>
                                  <WarningAmberOutlinedIcon fontSize="small" color={`${collection.env === 'development' ? 'primary' : collection.env === 'staging' ? 'warning' : 'error'}`} />
                                </Alert>
                              </Tooltip>
                            </div>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {collection.id}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {collection.name}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                            {collection.enabled ? 'Sim' : 'Não'}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                            <span className="font-semibold">{collection.client.name}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/collections/${collection.id}`} state={{ collection: collection }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                              <EditNoteIcon fontSize='medium' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div>
          </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

        </div>
      </div>
    </div>
  )
}

export default ListCollections;