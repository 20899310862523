import LLMModelsForm from "./Form";
import { useLocation } from 'react-router-dom';

const EditLLMModel = () => {
  const location = useLocation()
  const { llmModel } = location.state

  return (
    <LLMModelsForm llmModel={llmModel} formName="Editar" />
  )
}

export default EditLLMModel;
