import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Skeleton from '@mui/material/Skeleton';

import { TwilioEvent } from "../../../utils/types";
import { formatPhoneNumber } from "../../../utils/formatPhonenumber";
import { formatTime } from '../../../utils/formatDate';


interface MessageDetailsI {
  currentContact: TwilioEvent | null;
  contactMessages: TwilioEvent[] | null;
  isLoading: boolean;
}

const MessageDetails = ({ currentContact, contactMessages, isLoading }: MessageDetailsI) => {
  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="border border-x-transparent border-y-transparent w-2/3">
      <div className={`w-full relative ${!contactMessages ? 'bg-transparent border-b-transparent' : 'bg-white border-b'} ${!isLoading ? 'flex' : 'block'} items-center p-4 border rounded-se-lg border-s-transparent`}>
        {!isLoading ? contactMessages ?
          <>
            <img className="w-10 h-10" src="/assets/platform/anon-user.png" alt="user" />
            <span className="block ml-2 font-bold text-gray-600">{currentContact?.author_name ? currentContact.author_name : currentContact?.author ? formatPhoneNumber(currentContact.author.replace('whatsapp:', '')) : null}</span>
          </>
          : null : <Skeleton variant="text" sx={{ fontSize: '3rem', marginTop: '-1rem', marginBottom: '-1rem' }} />}
      </div>

      <div className={`${!contactMessages ? 'flex justify-center h-[39rem]' : 'relative h-[36.5rem]'} w-full pt-2 pb-6 px-6 overflow-y-auto border border-t-transparent border-s-transparent`}>
        {contactMessages ?
          <ul className="space-y-2">
            {!isLoading ? contactMessages.map((message, index) =>
              <div key={index}>
                {message.author !== 'bot' && message.author !== 'contato' && message.event_type !== 'activeMessageSent' && message.body ?
                  <li className="flex flex-col items-start justify-start">
                    <div className="relative max-w-xl py-2 text-gray-500">
                      <span className="text-xs block">{formatPhoneNumber(message.author.replace('whatsapp:', ''))} - {formatTime(message.created_at ? message.created_at : '')}</span>
                    </div>
                    <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow-md">
                      <span className="block">{message.body}</span>
                    </div>
                  </li>
                  : null}
                {message.author === 'bot' || message.author === 'contato' || message.event_type === 'activeMessageSent' ?
                  <li className="w-full flex flex-col items-end justify-end">
                    <div className="relative max-w-xl py-2 text-gray-500">
                      <span className="text-xs block">{message.event_type === 'activeMessageSent' ? `Mensagem ativa - ${formatPhoneNumber(message.author.replace('whatsapp:', ''))}` : capitalizeFirstLetter(message.author)} - {formatTime(message.created_at ? message.created_at : '')}</span>
                    </div>
                    <div className={`relative max-w-xl px-4 py-2 text-gray-700 ${message.event_type === 'activeMessageSent' ? 'bg-indigo-200' : ''} ${message.author === 'contato' ? 'bg-teal-200' : ''} ${message.author === 'bot' ? 'bg-sky-200' : ''} rounded shadow-md`}>
                      <span className="block">{message.body}</span>
                    </div>
                  </li>
                  : null}
              </div>
            ) :
              <>
                <div className='flex flex-col items-start'>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '2rem', marginBottom: '-0.5rem' }} />
                  <Skeleton variant="text" sx={{ width: '60%', fontSize: '2rem', marginBottom: '-1rem' }} />
                </div>
                <div className='flex flex-col items-end'>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '2rem', marginBottom: '-0.5rem' }} />
                  <Skeleton variant="text" sx={{ width: '60%', fontSize: '2rem', marginBottom: '-1rem' }} />
                </div>
                <div className='flex flex-col items-start'>
                  <Skeleton variant="text" sx={{ width: '70%', fontSize: '2rem', marginBottom: '-1rem' }} />
                </div>
                <div className='flex flex-col items-end'>
                  <Skeleton variant="text" sx={{ width: '40%', fontSize: '2rem', marginBottom: '-0.5rem' }} />
                  <Skeleton variant="text" sx={{ width: '80%', fontSize: '2rem', marginBottom: '-0.5rem' }} />
                  <Skeleton variant="text" sx={{ width: '30%', fontSize: '2rem', marginBottom: '-1rem' }} />
                </div>
              </>
            }
          </ul>
          :
          <div className="flex flex-col items-center justify-center -mt-12">
            <div className="text-center">
              <MessageOutlinedIcon fontSize="large" className="mx-auto" />
              <h1 className="mt-2 text-xl font-semibold text-gray-800">Nenhuma conversa selecionada</h1>
              <p className="mt-2 text-base text-gray-500">Selecione uma conversa para saber o que seus clientes estão falando! &#58;&#41;</p>
            </div>
          </div>}
      </div>
    </div>
  );
}

export default MessageDetails;
