import { useLocation } from "react-router-dom";
import PipelineFixturesForm from "./Form";

const EditPipelineFixture = () => {
  const location = useLocation();
  const { pipelineFixture } = location.state;

  return (<PipelineFixturesForm pipelineFixture={pipelineFixture} formName="Editar" />)
}

export default EditPipelineFixture;