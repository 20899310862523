import React from 'react';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import structure from './structure.json';

const HelpWelcome: React.FC = () => {
  const cardStyle = (index: number) => {
    let className = '';

    if (index !== 0) className += 'mt-4';
    if (index === 1) className += ' lg:mt-0'
    if (index % 2) className += ' lg:ml-4';

    return className;
  }

  return (
    <>
      <div className="mb-4 flex items-center justify-between py-4">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-1">Bem vindo(a) a central de ajuda!</h2>
          <span className="text-base font-normal text-gray-500">Aqui você encontrará documentos que sanarão as suas dúvidas. Caso ainda continue com dúvida, entre em contato conosco.</span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:gap-4 my-4">
        {structure.map((folder, index) => (
          <Card className={cardStyle(index)} key={index} sx={{ height: 'fit-content' }}>
            <Link to={`/help/content/${folder.path}/${folder.files[0].path.replace('.md', '')}`}>
              <CardActionArea>
                <CardContent sx={{ padding: '1.4rem' }}>
                  <h3 className="text-xl font-bold text-gray-900 mb-3">
                    {folder.name}
                  </h3>
                  <span className="text-base font-normal text-gray-500 mt-3">
                    {folder.description}
                  </span>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        ))}
      </div>
    </>
  );
};

export default HelpWelcome;
