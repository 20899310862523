import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { UUID } from "crypto";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useAuth } from "../../../contexts/authContext";

import { MessageRequest, ModelRequest } from "../../../utils/types";

import ViewConversation from "./ViewConversation";
import ViewTracelog from "./ViewTracelog";

const ConversationDetails = () => {
  const { token } = useAuth();

  const location = useLocation();
  const { messageRequest } = location.state;

  const [conversation, setConversation] = useState<MessageRequest[]>([]);
  const [tracelog, setTracelog] = useState<ModelRequest[]>([]);

  const [isLoadingConversation, setIsLoadingConversation] = useState<boolean>(false);
  const [isLoadingTracelog, setIsLoadingTracelog] = useState<boolean>(false);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/bo-conversations">Histórico conversas</Link>,
    <Typography key="3" color="text.primary">Detalhes</Typography>
  ];

  useEffect(() => {
    if (!conversation.length) getConversation();
  });

  const getConversation = async (offset = 0) => {
    if (token) {
      try {
        setIsLoadingConversation(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/message-request/details/${messageRequest.session_id}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setConversation(resJson);
        setIsLoadingConversation(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getTracelog = async (messageRequestId: UUID) => {
    if (token) {
      try {
        setIsLoadingTracelog(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/message-request/tracelog/${messageRequestId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setTracelog(resJson);
        setIsLoadingTracelog(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="pt-6 px-4">
      <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      <div className="py-2 md:flex items-center justify-between">
        <div className="w-full md:w-1/2 flex flex-col">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">Detalhes da conversa </h3>
          <span className="text-sm font-normal text-gray-500">session_id: {conversation.length ? conversation[0].session_id : ''}</span>
          <span className="text-sm font-normal text-gray-500 mt-1">pipeline_id: {conversation.length ? conversation[0].pipeline_id : ''}</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full pt-4">
        <div className="w-full lg:w-1/2">
          <ViewConversation conversation={conversation} getTracelog={getTracelog} isLoading={isLoadingConversation} />
        </div>
        <div className="w-full lg:w-1/2 pt-8 lg:pt-0 lg:pl-4">
          <ViewTracelog tracelog={tracelog} isLoading={isLoadingTracelog} />
        </div>
      </div>
    </div>
  );
}

export default ConversationDetails;
