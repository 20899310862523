import { useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";

import Papa from 'papaparse';

import Divider from "@mui/material/Divider";

import { CustomCombobox } from "../../../../../components";

import { MessageTemplateInterface } from "../../../../../utils/types";
import { showApprovalStatus } from "../../../../../utils/showApprovalStatus";
import { parseVariables } from "../../../../../utils/parseVariables";

interface ContentStepI {
  handleContentNext: React.MouseEventHandler<HTMLButtonElement>;
  handleBack: React.MouseEventHandler<HTMLButtonElement>;
  messageTemplates: MessageTemplateInterface[];
  templateSelected: MessageTemplateInterface | null;
  setTemplateSelected: React.Dispatch<React.SetStateAction<MessageTemplateInterface | null>>;
  templateQuery: string;
  setTemplateQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedFile: File;
  selectedAudience: AudienceInterface;
  variables: VariableObject[];
  setVariables: Function;
  message: string;
  setMessage: Function;
  onVariableValueChange: Function;
}

interface AudienceInterface {
  id: string,
  name: string
}

interface VariableObject {
  id: number;
  name: string;
  value: string;
}

const ContentStep = ({ handleContentNext, handleBack, messageTemplates, templateSelected, setTemplateSelected, templateQuery, setTemplateQuery, variables, setVariables, selectedAudience, selectedFile, message, setMessage, onVariableValueChange }: ContentStepI) => {
  const [error, setError] = useState<{ message: string | null; jsx?: JSX.Element | null; }>({ message: null, jsx: null });

  const statusesChecker = useMemo(() => ['rejected', 'paused', 'disabled', 'pending', 'unsubmitted', 'received', 'template_not_selected'], []);
  const [isStatusApproved, setIsApproved] = useState<boolean>(false);

  const [processedMessage, setProcessedMessage] = useState('');

  useEffect(() => {
    const validateCsv = (data: any[]) => {
      if (selectedAudience.id === "2") { setError({ message: null }); return true; }

      if (!data || data.length === 0) {
        setError({ message: 'CSV está vazio' });
        return false;
      }

      // Dynamically construct required columns based on variables
      const variableColumns = variables.map((_, index) => `variable_${index + 1}`);
      const requiredColumns = ['phone_number', ...variableColumns];

      const actualColumns = Object.keys(data[0]);
      const missingColumns = requiredColumns.filter(col => !actualColumns.includes(col));

      if (missingColumns.length === 1) {
        setError({
          message: 'Está faltando a coluna: ',
          jsx: <span className="font-semibold">{missingColumns.join(', ')}</span>
        });
        return false;
      }
      else if (missingColumns.length > 0) {
        setError({
          message: 'Estão faltando as colunas: ',
          jsx: <span className="font-semibold">{missingColumns.join(', ')}</span>
        });
        return false;
      }

      // Check for extra columns not defined in the template
      const extraColumns = actualColumns.filter(col => !requiredColumns.includes(col));

      if (requiredColumns.length === 1) { setError({ message: null }); return true; }
      else if (extraColumns.length > 0) {
        setError({
          message: 'Contêm colunas a mais do que a mensagem template: ',
          jsx: <span className="font-semibold">{extraColumns.join(', ')}</span>
        });
        return false;
      }

      // Check for null or empty values in each row
      for (const row of data) {
        if (Object.values(row).some(value => value === null || value === '')) {
          setError({ message: 'Contêm valores nulos ou vazios' });
          return false;
        }
      }

      setError({ message: null });
      return true;
    };

    const processCsvFile = async () => {
      try {
        const fileContent = await readFileContent(selectedFile);
        Papa.parse(fileContent as string, {
          complete: (result) => {
            const isValidCsv = validateCsv(result.data);
            if (isValidCsv && templateSelected) {
              const newTemplate = replaceTemplateVariables(templateSelected.types['twilio/text'].body, result.data);
              setProcessedMessage(newTemplate);
            }
          },
          header: true,
          skipEmptyLines: true
        });
      } catch (error) {
        console.error('Error reading file:', error);
      }
    };

    if (selectedFile && templateSelected) {
      processCsvFile();
    }
  }, [selectedFile, templateSelected, variables, selectedAudience.id, setMessage]);

  const readFileContent = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const replaceTemplateVariables = (template: string, csvData: any[]) => {
    let newProcessedTemplate = template;

    const firstRow = csvData[0];
    Object.keys(firstRow).forEach(columnName => {
      // Extract the number part from the column name
      const match = columnName.match(/variable_(\d+)/);
      if (match) {
        const number = match[1];
        // Replace '{{n}}' with the column name
        newProcessedTemplate = newProcessedTemplate.split(`{{${number}}}`).join(columnName);
      }
    });

    return newProcessedTemplate;
  };

  useEffect(() => {
    setIsApproved(!statusesChecker.includes(templateSelected?.approval_status.whatsapp.status || 'template_not_selected'));
  }, [templateSelected, statusesChecker]);

  useEffect(() => {
    if (templateSelected && selectedAudience?.id === '1') {
      setVariables(parseVariables(templateSelected?.types['twilio/text'].body));
      setMessage(templateSelected?.types['twilio/text'].body);
    }
  }, [templateSelected, selectedAudience?.id, setVariables, setMessage]);

  return (
    <>
      <div className="mb-4 md:flex items-center justify-between">
        <div className="w-full flex flex-col">
          <h3 className="text-xl font-bold text-gray-900 mb-2">Conteúdo</h3>
          <span className="text-base font-normal text-gray-500">Escolha o modelo de mensagem que deseja enviar para seu público. Apenas modelos <span className="font-semibold">aprovados</span> pelo WhatsApp estarão disponíveis. Se preferir, <Link to="/active-messages/message-template/create" className="flex w-fit sm:inline-flex text-[#11111f] font-medium hover:underline">crie um template de mensagem.</Link></span>
        </div>
      </div>

      <div className="flex flex-col my-4">
        <div className="align-middle inline-block min-w-full">

          <div className="md:flex items-center mt-3">
            <div className="w-full md:w-1/2 flex flex-col md:mt-0">
              <div className="z-30">
                <CustomCombobox entity="Selecione o template de mensagem" list={messageTemplates} selected={templateSelected} setSelected={setTemplateSelected!} query={templateQuery} setQuery={setTemplateQuery} displayProperty="friendly_name" emptyState={false} />
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-4 md:mt-1 md:ml-4">
              <div className="flex flex-col">
                <input
                  type="text"
                  className="leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 bg-gray-200 cursor-not-allowed border-gray-300 border rounded"
                  value={"Português (BR)"}
                  disabled
                />
              </div>
            </div>
          </div>

        </div>
      </div>

      {templateSelected ?
        <div className="flex flex-col my-4">
          <div className="min-w-full">

            <div className="md:flex">
              <div className={`w-full ${(variables.length !== 0 || (selectedAudience.id === "1" && error.message)) && isStatusApproved ? 'md:w-1/2' : ''} flex flex-col md:mt-0`}>
                <div className="my-2">
                  <div className="bg-gray-100 p-8 mt-4 rounded-md">
                    <div className="flex flex-col md:grid md:grid-cols-2">
                      <span className="text-sm font-semibold">Status: </span>
                      <div className="flex flex-row items-center mt-3 md:mt-0">
                        {showApprovalStatus(templateSelected)}
                      </div>
                    </div>

                    {templateSelected?.approval_status.whatsapp.rejection_reason ?
                      <div className="flex flex-col md:grid md:grid-cols-2 mt-4">
                        <span className="text-sm font-semibold">Motivo: </span>
                        <span className="text-sm font-normal mt-3 md:mt-0">{templateSelected?.approval_status.whatsapp.rejection_reason}</span>
                      </div> : null}

                    <div className="my-4">
                      <Divider />
                    </div>

                    <div className="flex flex-col md:grid md:grid-cols-2 mt-4">
                      <span className="text-sm font-semibold">Corpo da mensagem: </span>
                      <span className="text-sm font-normal whitespace-pre-line mt-3 md:mt-0">{message}</span>
                    </div>
                  </div>
                </div>
              </div>

              {selectedAudience.id === "2" && variables.length !== 0 && isStatusApproved ?
                <div className="w-full md:w-1/2 flex flex-col justify-center mt-4 md:mt-0 md:ml-4">
                  <div className="-my-2 md:my-2">
                    <div className="md:flex items-center justify-between mt-4">
                      <div className="w-full flex flex-col">
                        <span className="text-md font-bold text-gray-900">Variáveis</span>
                      </div>
                    </div>
                    {variables.map((variable, index) => (
                      <div className="w-full flex flex-col" key={index}>
                        <input
                          className={`text-base leading-none text-gray-900 p-3 mt-4 shadow focus:oultine-none focus:border-blue-700 bg-white border-gray-200 border rounded`}
                          value={variable.value}
                          onChange={(e) => onVariableValueChange(e, variable.id)}
                          placeholder={`{{${variable.name}}}`}
                        />
                      </div>
                    ))}
                  </div>
                </div> : null}

              {selectedAudience.id === "1" && error.message && isStatusApproved ?
                <div className="w-full md:w-1/2 flex flex-col justify-center mt-4 md:mt-0 md:ml-4">
                  <div className="my-2">
                    <div className="bg-gray-100 p-8 rounded-md md:mt-4">
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold text-[#d32f2f]">Seu documento CSV contêm erros: </span>
                        <span className="text-sm font-normal whitespace-pre-line mt-3">{error.message}{error.jsx}.</span>
                      </div>
                    </div>
                  </div>
                </div> : null}

              {selectedAudience.id === "1" && isStatusApproved && !error.message && variables.length !== 0 ?
                <div className="w-full md:w-1/2 flex flex-col justify-center mt-4 md:mt-0 md:ml-4">
                  <div className="my-2">
                    <div className="bg-gray-100 p-8 rounded-md md:mt-4">
                      <div className="flex flex-col md:grid md:grid-cols-2">
                        <span className="text-sm font-semibold">Amostra da mensagem: </span>
                        <span className="text-sm font-normal whitespace-pre-line mt-3 md:mt-0">{processedMessage}</span>
                      </div>
                    </div>
                  </div>
                </div> : null}
            </div>

          </div>
        </div> : null}

      <div className="flex flex-row justify-end pt-4">
        <button className="mr-2 font-normal leading-none text-black py-3 px-10 bg-transparent rounded hover:bg-gray-200 focus:outline-none" onClick={handleBack}>Voltar</button>
        <button className={`font-normal leading-none text-white py-3 px-10 bg-[#11111f] ${!isStatusApproved || (selectedAudience.id === "1" && error.message) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#292c31]'} rounded focus:outline-none`} onClick={!isStatusApproved || (selectedAudience.id === "1" && error.message) ? undefined : handleContentNext}>Próximo</button>
      </div>
    </>
  );
}

export default ContentStep;
