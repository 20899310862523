import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface IdTokenClaims {
  client_id: string | null;
}

const AuthContext = createContext<{
  token: string | null;
  idTokenClaims: IdTokenClaims | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}>({
  token: null,
  idTokenClaims: null,
  setToken: () => { },
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [idTokenClaims, setIdTokenClaims] = useState<IdTokenClaims | null>(null);
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const fetchTokenAndIdTokenClaims = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://chatspace-backoffice.us.auth0.com/api/v2/",
            }
          });
          setToken(accessToken);
    
          const claims = await getIdTokenClaims();
          const clientMetadata = claims?.['https://painel.arkspace.ai/user_metadata']
          setIdTokenClaims(clientMetadata);
        } catch (error) {
          console.error('Error fetching access token or ID token claims', error);
        }
      }
    };

    fetchTokenAndIdTokenClaims();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  return (
    <AuthContext.Provider value={{ token, idTokenClaims, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

