import * as React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const Settings = () => {
  const location = useLocation();

  const [tabActive, setTabActive] = React.useState(0);

  React.useEffect(() => {
    if (location.pathname.includes('password')) return setTabActive(1);
    if (location.pathname.includes('integrations')) return setTabActive(2);

    return setTabActive(0);
  }, [setTabActive, location.pathname]);

  const handleChange = (newValue: number) => {
    setTabActive(newValue);
  };

  return (
    <>
      <div className='mx-4 flex justify-start border-b'>
        <Link to={'/settings'} className={`px-4 py-4 mr-4 cursor-pointer ${tabActive === 0 ? 'border-b-2 border-b-[#11111f]' : 'hover:border-b-2 hover:border-b-gray-300'}`} onClick={() => handleChange(0)}>
          <span className={`${tabActive === 0 ? 'text-[#11111f]' : 'text-gray-500'} font-semibold text-sm`}>Perfil</span>
        </Link>
        <Link to={'/settings/password'} className={`px-4 py-4 mr-4 cursor-pointer ${tabActive === 1 ? 'border-b-2 border-b-[#11111f]' : 'hover:border-b-2 hover:border-b-gray-300'}`} onClick={() => handleChange(1)}>
          <span className={`${tabActive === 1 ? 'text-[#11111f]' : 'text-gray-500'} font-semibold text-sm`}>Senha</span>
        </Link>
        <Link to={'/settings/integrations'} className={`px-4 py-4 cursor-pointer ${tabActive === 2 ? 'border-b-2 border-b-[#11111f]' : 'hover:border-b-2 hover:border-b-gray-300'}`} onClick={() => handleChange(2)}>
          <span className={`${tabActive === 2 ? 'text-[#11111f]' : 'text-gray-500'} font-semibold text-sm`}>Integrações</span>
        </Link>
      </div>

      <div className="pt-6 pb-2">
        <Outlet />
      </div>
    </>
  );
}

export default Settings;
