import { useLocation } from "react-router-dom";
import TasksForm from "./Form";

const AddTask = () => {
  const location = useLocation();
  const { pipeline, client_id } = location.state;

  return (<TasksForm pipeline={pipeline} client_id={client_id} formName="Cadastrar" />)
}

export default AddTask;