import IframeResizer from 'iframe-resizer-react'

const Homepage = () => {
  return (
    <div className="pt-6 px-4">
      <div className="w-full grid grid-cols-1 gap-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
          <div className="flex items-center justify-between mb-4 overflow-x-auto">
            <div className="h-full flex items-center flex-1 text-green-500 text-base font-bold">
              {/* (old IframResizer className configuration) w-full h-full min-h-[180vh] md:min-h-[120vh]  */}
              <IframeResizer
                id="api-usage"
                className="w-full overflow-hidden rounded-lg ring-1 ring-slate-900/10"
                title="This is a unique title"
                src="https://metabase.arkspace-api.com/public/dashboard/fae171a0-5883-498e-8153-893dcbea5a51"
                style={{ width: '1px', minWidth: '100%' }}
                sandbox="allow-same-origin allow-scripts"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
