import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button className="flex m-auto sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center" onClick={() => loginWithRedirect()}>
      <svg className="flex-shrink-0 -ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
      </svg>
      Entrar ou cadastrar
    </button>
  );
};

export default LoginButton;
