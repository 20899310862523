import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';

import { useAuth } from "../../../../contexts/authContext";

import { Toggle } from "../../../../components/Toogle";
import { LoadingDots } from "../../../../components/LoadingDots";
import { ConfirmationModal } from "../../../../components";

import { toast } from "react-toastify";
import { toastErrors } from "../../../../utils/toastErrors";

import { DocumentInterface, CollectionInterface } from "../../../../utils/types";
import { formatDateTime } from "../../../../utils/formatDate";
import { ListEmbeddings } from "./embeddings";

interface DocumentsFormInterface {
  document?: DocumentInterface,
  collection: CollectionInterface,
  formName: string
}

const DocumentsForm = ({ document, collection, formName }: DocumentsFormInterface) => {
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');

  const [description, setDescription] = useState(isEditting ? document?.description : null);
  const [chunkSize, setChunkSize] = useState(isEditting ? document?.chunk_size?.toString() : '500');
  const [chunkOverlap, setChunkOverlap] = useState(isEditting ? document?.chunk_overlap?.toString() : '100');
  const [enabled, setEnabled] = useState<boolean | null>(isEditting ? document!.enabled : true);
  const [smartIngested, setSmartIngested] = useState<boolean | null>(isEditting ? document!.smart_ingested : false);
  const [smartPrompt, setSmartPrompt] = useState<string>('Extraia pares de Pergunta-Resposta do documento de FAQ fornecido e retorne-os como uma lista de strings. Cada elemento da lista deve ser uma string contendo tanto a pergunta quanto a resposta concatenadas. Garanta que o conteúdo seja preservado com precisão, e se a pergunta contiver um número, mantenha esse número na saída. A saída deve estar sempre em um formato de lista, pronta para ser analisada. \n\nExemplo de output: ["Pergunta-Resposta 1", "Pergunta-Resposta 2", "Pergunta-Resposta 3"] \n\nInput FAQ: {text} \n\nOutput (formato de lista):');

  const [selectedFile, setSelectedFile] = useState<File | null>();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/collections">Collections</Link>,
    <Link className="hover:underline" key="3" color="inherit" to={`/collections/${collection?.id}`} state={{ collection: collection }}>{collection.name}</Link>,
    <Typography key="3" color="text.primary">{isEditting ? document?.name : 'Cadastrar documento'}</Typography>
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    setSelectedFile(files![0]);
  };

  const handleRemoveSelectedFile = () => {
    setSelectedFile(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files.length === 0) return;

    const file = files[0];
    const acceptedFormats = ['application/pdf', 'text/plain', 'text/csv', 'application/json', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (acceptedFormats.includes(file.type)) {
      setSelectedFile(file);
    } else {
      // Handle invalid format (e.g., show a toast or an error message)
      toast.error("Formato do arquivo inválido. Apenas PDF, CSV, DOCX, TXT e JSON são permitidos.");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document/delete/${document?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/collections/${collection.id}`, { state: { collection: collection } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    let form;

    if (isEditting) {
      headers.append('Content-Type', 'application/json');
      form = JSON.stringify({ description, enabled, collection_id: collection?.id });
    } else {
      form = new FormData();
      form.append('description', description!);
      form.append('collection_id', collection?.id);
      form.append('document', selectedFile!);
      form.append('enabled', enabled!.toString());
      form.append('smart_ingested', smartIngested!.toString());
      if (smartIngested) form.append('prompt', smartPrompt);
      if (chunkSize?.trim().length !== 0) form.append('chunk_size', chunkSize!);
      if (chunkOverlap?.trim().length !== 0) form.append('chunk_overlap', chunkOverlap!);
    }

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document${isEditting ? `/${document?.id}` : '/ingest'}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: headers,
        body: form,
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Documento inserido na lista de ingest!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/collections/${collection.id}`, { state: { collection: collection } });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center mt-3 justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{formName} documento</h3>
                <span className="text-base font-normal text-gray-500">Certifique-se de que o documento está devidamente tratado antes de {formName.toLowerCase()}</span>
              </div>

              <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
                <Toggle enabled={enabled!} setEnabled={(newVal) => setEnabled(newVal)} />
              </div>
            </div>

            <div className="flex flex-col mt-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">

                    <ConfirmationModal action={action} entity="documento" open={open} setOpen={setOpen} handleAction={action === 'Deletar' ? handleDelete : handleForm} />

                    <div className="w-full flex flex-col mt-3">
                      <label className="font-semibold leading-none">Descrição</label>
                      <textarea
                        className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!description ? '' : description}
                        onChange={e => setDescription(e.target.value)}
                        required
                      />
                    </div>

                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Smart ingest</label>
                      <Toggle className="mt-4" enabled={smartIngested!} setEnabled={(newVal) => { setSmartIngested(newVal); setChunkSize(newVal ? '' : '500'); setChunkOverlap(newVal ? '' : '100') }} disabled={isEditting} />
                    </div>

                    {!isEditting && smartIngested ? <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Smart prompt</label>
                      <textarea
                        className={`h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 ${isEditting || !smartIngested ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200`}
                        value={smartPrompt}
                        onChange={e => setSmartPrompt(e.target.value)}
                        required
                        disabled={isEditting || !smartIngested}
                      />
                    </div> : null}

                    <div className="md:flex items-center mt-8">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold leading-none">Chunk size</label>
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 ${isEditting || smartIngested! ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200`}
                          value={smartIngested ? '' : chunkSize}
                          onChange={e => setChunkSize(e.target.value)}
                          disabled={isEditting || smartIngested!}
                        />
                      </div>

                      <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-8">
                        <label className="font-semibold leading-none">Chunk overlap</label>
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 ${isEditting || smartIngested! ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200`}
                          value={smartIngested ? '' : chunkOverlap}
                          onChange={e => setChunkOverlap(e.target.value)}
                          disabled={isEditting || smartIngested!}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Collection</label>
                      <input
                        type="text"
                        className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                        value={collection?.name}
                        required
                        disabled
                      />
                    </div>

                    {!isEditting ?
                      <div>
                        <div className="w-full flex flex-col mt-8">
                          <label className="font-semibold leading-none">Documento</label>
                          <div className="flex items-center justify-center w-full mt-4">
                            {!selectedFile ?
                              <label htmlFor="dropzone-file" onDragOver={handleDragOver} onDrop={handleDrop} className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-100">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                  </svg>

                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Selecione um documento</span> ou arraste e solte</p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">PDF, CSV, DOCX, TXT ou JSON</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept="application/pdf, text/plain, text/csv, application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                              </label>
                              :
                              <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg bg-white">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                  </svg>

                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">{selectedFile.name}</span></p>
                                  <button className="text-xs text-gray-500 dark:text-gray-400 cursor-pointer hover:underline" onClick={() => handleRemoveSelectedFile()}>Remover</button>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      :
                      <div className="w-full flex flex-col mt-8">
                        <div className="flex flex-row w-full items-center">
                          <label className="font-semibold leading-none mr-1">URL Documento</label>
                          <Link to={`https://s3.console.aws.amazon.com/s3/buckets/${document?.document_url.replace("s3://", "")}`} target="_blank" rel="noopener noreferrer">
                            <LaunchIcon fontSize="small" />
                          </Link>
                        </div>

                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-3 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                          value={document?.document_url}
                          disabled
                        />
                      </div>
                      
                    }

                    {isEditting ? <div className="mt-4">
                      <span className="text-sm opacity-50">Última atualização: {document?.updated_at ? formatDateTime(document?.updated_at!) : 'não teve atualização!'}</span>
                    </div> : null}

                    <div className="flex items-center justify-end w-full">
                      {isEditting ? <button onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none">Deletar</button> : null}

                      <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                        {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditting ?
        <>
          <ListEmbeddings document_id={document?.id} />
        </> : null}
    </>
  )
}


export default DocumentsForm;
