import React from 'react';

const getGreetingBasedOnTime = (): string => {
  const now = new Date();
  const brazilTime = new Date(now.valueOf() + now.getTimezoneOffset() * 60000 - (3 * 3600000));
  const hour = brazilTime.getHours();

  if (hour >= 6 && hour < 12) { return 'Bom dia'; }
  else if (hour >= 12 && hour < 18) { return 'Boa tarde'; }
  else { return 'Boa noite'; }
};

const GreetingMessage: React.FC = () => {
  const greeting = getGreetingBasedOnTime();

  return <div>{greeting}</div>;
};

export default GreetingMessage;
