import React, { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

interface CustomComboboxProps<T> {
  entity: string,
  list: T[] | null,
  selected: T | null;
  setSelected: React.Dispatch<React.SetStateAction<T | null>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  query: string;
  displayProperty?: string;
  emptyState?: boolean;
}

const CustomCombobox = <T,>({ entity, list, selected, setSelected, query, setQuery, displayProperty = 'name', emptyState = true }: CustomComboboxProps<T>) => {
  const filteredEntity =
    query === ''
      ? list
      : list?.filter((item: any) =>
        item && item[displayProperty]
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <div className={`relative w-full rounded border text-gray-900 border-gray-200 py-1 text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 shadow`}>
          <Combobox.Input
            className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 focus:outline-none`}
            displayValue={(item: any) => item && item[displayProperty]}
            placeholder={entity.charAt(0).toUpperCase() + entity.slice(1)}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredEntity?.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nenhum(a) {entity} encontrado(a).
              </div>
            ) : (
              <>
                {emptyState ? <Combobox.Option
                  key="nenhum-option"
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pr-3 pl-10 pr-4 ${active ? 'text-white bg-[#2c2c50]' : 'text-[#2c2c50]'}`
                  }
                  value={null}
                >
                  {({ active }) => (
                    <>
                      <span className={`block truncate font-normal`}>
                        Nenhum(a) {entity}
                      </span>
                    </>
                  )}
                </Combobox.Option> : null}

                {filteredEntity?.map((item: any, index: number) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pr-3 pl-10 pr-4 ${active ? 'text-white bg-[#2c2c50]' : 'text-[#2c2c50]'
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {item && item[displayProperty]}
                        </span>
                        {selected ? (
                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-[#2c2c50]'}`}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default CustomCombobox;
