import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/authContext";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { toast } from "react-toastify";
import { toastErrors } from '../../../utils/toastErrors';
import { LoadingDots } from '../../../components/LoadingDots';
import { Toggle } from "../../../components/Toogle";
import { ConfirmationModal, CustomListbox } from "../../../components";
import { ListDocuments, ListPendingDocuments } from "./documents";

import { CollectionInterface, ClientInterface, DocumentInterface } from "../../../utils/types";

import { CustomCombobox } from "../../../components";
import { formatDateTime } from "../../../utils/formatDate";
import FilterDocuments from "./FilterDocuments";
import { Alert } from "@mui/material";

interface CollectionsFormFormInterface {
  collection?: CollectionInterface,
  formName: string,
}

interface EnvInterface {
  id: string,
  name: string,
  value: string
}


const CollectionsForm = ({ collection, formName }: CollectionsFormFormInterface) => {
  const { token } = useAuth();

  const [isEditting] = useState<boolean>(formName === 'Editar');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let [name, setName] = useState(collection?.name);
  const [description, setDescription] = useState(collection?.description);
  const [enabled, setEnabled] = useState(isEditting ? collection!.enabled : true);

  const [clients, setClients] = useState<ClientInterface[] | null>(null);

  const [allDocuments, setAllDocuments] = useState<DocumentInterface[]>([]);
  const [enabledDocuments, setEnabledDocuments] = useState<DocumentInterface[]>([]);
  const [unabledDocuments, setUnabledDocuments] = useState<DocumentInterface[]>([]);

  const [allDocumentsCount, setAllDocumentsCount] = useState<number>(0);
  const [enabledDocumentsCount, setEnabledDocumentsCount] = useState<number>(0);
  const [unabledDocumentsCount, setUnabledDocumentsCount] = useState<number>(0);


  const [vectorsCount, setVectorsCount] = useState<number | null>(null);

  const [selectedClient, setSelectedClient] = useState(isEditting ? collection?.client : null);
  const [clientQuery, setClientQuery] = useState('');

  const [envList] = useState<EnvInterface[]>([{ id: '1', name: 'Desenvolvimento', value: 'development' }, { id: '2', name: 'Staging', value: 'staging' }, { id: '3', name: 'Produção', value: 'production' }]);
  const [selectedEnv, setSelectedEnv] = useState<EnvInterface | null>(
    collection?.env ? envList.find(envL => envL.value.toLowerCase() === collection?.env) || envList[0] : envList[0]
  );

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/collections">Collections</Link>,
    <Typography key="3" color="text.primary">{isEditting ? collection?.name : 'Cadastrar collection'}</Typography>
  ];

  const getAllDocuments = useCallback(async (offset = 0) => {
    try {
      setIsLoading(true);

      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document?collection_id=${collection?.id}&offset=${offset}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setAllDocuments(resJson.documents);
      setAllDocumentsCount(resJson.count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [collection?.id, token]);

  const getEnabledDocuments = useCallback(async (offset = 0) => {
    try {
      setIsLoading(true);

      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document?collection_id=${collection?.id}&enabled=true&offset=${offset}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setEnabledDocuments(resJson.documents);
      setEnabledDocumentsCount(resJson.count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [collection?.id, token]);

  const getUnabledDocuments = useCallback(async (offset = 0) => {
    try {
      setIsLoading(true);

      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document?collection_id=${collection?.id}&enabled=false&offset=${offset}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setUnabledDocuments(resJson.documents);
      setUnabledDocumentsCount(resJson.count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [collection?.id, token]);

  const getVectorsCount = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/collection/vectors-count/${collection?.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setVectorsCount(resJson.vector_count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [collection?.id, token]);

  useEffect(() => {
    if (!clients && token) getClients();
    if (isEditting && token) {
      getAllDocuments();
      getEnabledDocuments();
      getUnabledDocuments();
      getVectorsCount();
    }
  }, [clients, isEditting, token]);

  const getClients = async () => {
    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      })

      const resJson = await res.json();
      setClients(resJson.clients);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNamespaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\s+/g, '').toLowerCase(); // Remove spaces
    setName(value);
  };

  async function handleReset() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/collection/reset/${collection?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Resetado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete() {
    const toastId = toast.loading("Aguarde...");

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/collection/delete/${collection?.id}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
      });

      if (!res.ok) {
        toastErrors(toastId, res);
        setLoading(false);
      } else {
        toast.update(toastId, {
          render: 'Deletado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/collections');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    // Process the name
    let processedName = name || '';

    // Ensure it doesn't end with a special character
    while (/[^a-zA-Z0-9]$/.test(processedName)) {
      processedName = processedName.slice(0, -1);
    }

    // Ensure the name ends with _namespace
    if (!processedName.endsWith('_namespace')) processedName += '_namespace';

    const form = { name: processedName, description, enabled, env: selectedEnv?.value, client_id: selectedClient?.id };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/collection${isEditting ? `/${collection?.id}` : ''}`, {
        method: `${isEditting ? 'PUT' : 'POST'}`,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${isEditting ? 'Atualizado!' : 'Cadastrado!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate('/collections');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderAlert = () => {
    const env = collection?.env;

    if (env === 'staging') return <Alert severity={'warning'}>Essa collection está sendo utilizada em ambiente de teste.</Alert>;
    if (env === 'development') return <Alert severity={'warning'} color={'info'}>Essa collection está sendo utilizada em ambiente de desenvolvimento.</Alert>;
    return <Alert severity={'warning'} color={'error'}>Essa collection está sendo utilizada em ambiente de produção.</Alert>;
  };

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        {isEditting ? <div className="mt-4 -mb-1">
          {renderAlert()}
        </div> : null}

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center mt-3 justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{formName} collection</h3>
                <span className="text-base font-normal text-gray-500">Uma collection equivale a um <span className="text-base font-bold">namespace</span> em um index. Certifique-se de que todos os dados estão corretos antes de {formName.toLowerCase()}</span>
              </div>

              <div className="w-full mt-8 md:w-1/2 flex md:justify-end md:mt-0">
                <Toggle enabled={enabled} setEnabled={(newVal) => setEnabled(newVal)} />
              </div>
            </div>

            <div className="flex flex-col mt-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">

                    <ConfirmationModal action={action} entity="collection" open={open} setOpen={setOpen} handleAction={() => {
                      if (action === 'Resetar') {
                        handleReset();
                      } else if (action === 'Deletar') {
                        handleDelete();
                      } else if (action === 'Atualizar') {
                        handleForm();
                      }
                    }} />

                    <div className="md:flex items-center mt-3">
                      <div className="w-full lg:w-1/3 flex flex-col">
                        <label className="font-semibold leading-none">Nome (namespace)</label>
                        <div className="flex mt-4">
                          <input
                            type="text"
                            className={`w-full md:w-2/3 flex flex-col leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 ${isEditting && allDocuments!.length !== 0 ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} border rounded border-gray-200 rounded-r-none`}
                            value={!name ? '' : name.replace('_namespace', '')}
                            onChange={handleNamespaceChange}
                            required
                            disabled={isEditting && allDocuments!.length !== 0}
                          />
                          <input
                            type="text"
                            value="_namespace"
                            className="w-full md:w-1/3 flex flex-col leading-none text-gray-900 p-3 shadow bg-gray-200 cursor-not-allowed border rounded border-gray-200 rounded-l-none"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="w-full lg:w-1/3 flex flex-col md:ml-6 md:mt-0 mt-8">
                        <label className="font-semibold leading-none">Cliente</label>

                        {isEditting ?
                          <input
                            type="text"
                            className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                            value={collection?.client.name}
                            disabled
                          />
                          :
                          <div className="mt-3 z-10">
                            <CustomCombobox entity="cliente" list={clients} selected={selectedClient} setSelected={setSelectedClient} query={clientQuery} setQuery={setClientQuery} emptyState={false} />
                          </div>
                        }
                      </div>

                      <div className="w-full lg:w-1/3 flex flex-col md:ml-6 md:mt-0 mt-8">
                        <label className="font-semibold leading-none">Ambiente</label>

                        <div className="mt-3 z-20">
                          <CustomListbox list={envList} selected={selectedEnv} setSelected={setSelectedEnv} placeholder='Selecione ambiente' />
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex flex-col mt-8">
                      <label className="font-semibold leading-none">Descrição</label>
                      <textarea
                        className="h-40 text-base leading-none text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!description ? '' : description}
                        onChange={e => setDescription(e.target.value)}
                        required
                      />
                    </div>

                    {isEditting ? <div className="w-full flex flex-col mt-8">
                      <span>Número de vetores: {isLoading ? <LoadingDots color="black" dotStyle="small" /> : vectorsCount}</span>
                    </div> : null}

                    {isEditting ? <div className="mt-4">
                      <span className="text-sm opacity-50">Última atualização: {collection?.updated_at ? formatDateTime(collection?.updated_at!) : 'não teve atualização!'}</span>
                    </div> : null}

                    <div className="flex items-center justify-end w-full">
                      {isEditting ? <button onClick={() => { setOpen(true); setAction('Resetar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-orange-700 rounded hover:bg-orange-600 focus:ring-2 focus:ring-offset-2 focus:ring-orange-700 focus:outline-none">Resetar</button> : null}
                      {isEditting ? <button onClick={() => { setOpen(true); setAction('Deletar') }} className="mt-9 mr-4 font-normal leading-none text-white py-4 px-10 bg-red-700 rounded hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-700 focus:outline-none">Deletar</button> : null}

                      <button type="submit" onClick={() => { if (!isEditting) { handleForm(); } else { setOpen(true); setAction('Atualizar'); } }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                        {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : `${isEditting ? 'Atualizar' : formName}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditting ?
        <>
          <FilterDocuments documents={enabledDocuments!} getDocuments={getEnabledDocuments} documentsCount={enabledDocumentsCount} collection={collection} isLoading={isLoading} />
          <ListDocuments enabledDocuments={enabledDocuments!} getEnabledDocuments={getEnabledDocuments} enabledDocumentsCount={enabledDocumentsCount} unabledDocuments={unabledDocuments!} getUnabledDocuments={getUnabledDocuments} unabledDocumentsCount={unabledDocumentsCount} collection={collection} isLoading={isLoading} />
          <ListPendingDocuments collection_id={collection?.id} />
        </> : null}
    </>
  )
}

export default CollectionsForm;