import { useState } from "react";
import { Link } from "react-router-dom";

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from "@mui/material/Alert";
import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import EditNoteIcon from '@mui/icons-material/EditNote';

import { DocumentInterface, CollectionInterface } from "../../../../utils/types";

interface ListDocumentsInterface {
  enabledDocuments?: DocumentInterface[],
  getEnabledDocuments?: Function,
  enabledDocumentsCount?: number,
  unabledDocuments?: DocumentInterface[],
  getUnabledDocuments?: Function,
  unabledDocumentsCount?: number,
  collection?: CollectionInterface,
  isLoading?: boolean
}

const ListDocuments = ({ enabledDocuments, enabledDocumentsCount, getEnabledDocuments, unabledDocuments, getUnabledDocuments, unabledDocumentsCount, collection, isLoading }: ListDocumentsInterface) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const [enabledPage, setEnabledPage] = useState<number>(1);
  const enabledStartIndex: number = (enabledPage - 1) * 10 + 1;
  const enabledEndIndex: number = Math.min(enabledStartIndex + 10 - 1, enabledDocumentsCount!);

  const [unabledPage, setUnabledPage] = useState<number>(1);
  const unabledStartIndex: number = (unabledPage - 1) * 10 + 1;
  const unabledEndIndex: number = Math.min(unabledStartIndex + 10 - 1, unabledDocumentsCount!);

  const handleEnabledPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setEnabledPage(value);
    getEnabledDocuments!((value - 1) * 10);
  };

  const handleUnabledPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setUnabledPage(value);
    getUnabledDocuments!((value - 1) * 10);
  };

  return (
    <>
      <div className="my-4 px-4">
        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 flex flex-col justify-between items-start">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Documentos ativos</h3>
              <span className="text-base font-normal text-gray-500">Listagem dos documentos que estão ativos e pertencem a essa collection</span>
            </div>

            <Link to="/documents/create" state={{ collection: collection }} className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
              Cadastrar documento
              <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
              </svg>
            </Link>
            <div className="flex flex-col mt-4">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Id
                          </th>
                          <th scope="col" className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nome
                          </th>
                          <th scope="col" className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                            Habilitado
                          </th>
                          <th scope="col" className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                            Tipo
                          </th>
                          <th scope="col" className="tracking-wider" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {enabledDocuments?.length !== 0 ? enabledDocuments?.map((document, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {document.id}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal">
                              {document.name}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {document.enabled ? 'Sim' : 'Não'}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                              {document.type}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold">
                              <Link to={`/documents/${document.id}`} state={{ document: document, collection: collection }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                                <EditNoteIcon fontSize='medium' />
                              </Link>
                            </td>
                          </tr>
                        )) : <tr>
                          <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                            <p className="text-gray-700 font-medium text-lg text-center">Nenhum documento ativo cadastrado.</p>
                            <p className="text-gray-500 text-center">Cadastre um documento e habilite-o para que seus dados apareçam aqui.</p>
                            <Link to="/documents/create" className="flex items-center w-fit px-4 py-1.5 border border-gray-400 mt-4 text-gray-600 rounded text-sm hover:bg-[#11111f] hover:text-white mx-auto" state={{ collection: collection }}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-6 h-6  mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              Cadastrar documento
                            </Link>
                          </td>
                        </tr>}
                      </tbody>
                    </table> :
                      <div className="-mt-4">
                        <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                        <Skeleton variant="rounded" height={240} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? enabledDocuments?.length !== 0 ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
              <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="hidden sm:contents">
                  <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{enabledStartIndex}</span> até <span className="font-bold">{enabledEndIndex}</span> de <span className="font-bold">{enabledDocumentsCount}</span> resultados</p>
                </div>

                <Pagination count={Math.ceil(enabledDocumentsCount! / 10)} variant="outlined" shape="rounded" page={enabledPage} onChange={handleEnabledPagination} />
              </div>
            </div> : null : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

          </div>
        </div>
      </div>

      <div className="my-4 px-4">
        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-bold text-gray-900">Documentos inativos</h3>
              </div>
              <div>
                <IconButton onClick={handleCollapse}>
                  <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
                </IconButton>
              </div>
            </div>

            <Collapse in={openCollapse}>
              <span className="text-base font-normal text-gray-500">Listagem dos documentos que estão inativos e pertencem a essa collection</span>

              <div className="flex flex-col mt-8">
                <Alert severity={'info'} className="mb-2">Vetores de documentos inativos não entram no cálculo de número de vetores totais. Documentos inativos não são utilizados como base de conhecimento.</Alert>

                <div className="overflow-x-auto rounded-lg">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden sm:rounded-lg">
                      {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Id
                            </th>
                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Nome
                            </th>
                            <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                              Habilitado
                            </th>
                            <th scope="col" className="tracking-wider" />
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {unabledDocuments?.length !== 0 ? unabledDocuments?.map((document, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                              <td className="p-4 whitespace-nowrap text-sm font-normal">
                                {document.id}
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-normal">
                                {document.name}
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                                {document.enabled ? 'Sim' : 'Não'}
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-semibold">
                                <Link to={`/documents/${document.id}`} state={{ document: document, collection: collection }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                                  <EditNoteIcon fontSize='medium' />
                                </Link>
                              </td>
                            </tr>
                          )) : <tr>
                            <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                              <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                              <p className="text-gray-700 font-medium text-lg text-center">Nenhum documento inativo.</p>
                              <p className="text-gray-500 text-center">Os dados de documentos inativos aparecerão aqui.</p>
                            </td>
                          </tr>}
                        </tbody>
                      </table> :
                        <div className="-mt-4">
                          <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                          <Skeleton variant="rounded" height={240} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {!isLoading ? unabledDocuments?.length !== 0 ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
                <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div className="hidden sm:contents">
                    <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{unabledStartIndex}</span> até <span className="font-bold">{unabledEndIndex}</span> de <span className="font-bold">{unabledDocumentsCount}</span> resultados</p>
                  </div>

                  <Pagination count={Math.ceil(unabledDocumentsCount! / 10)} variant="outlined" shape="rounded" page={unabledPage} onChange={handleUnabledPagination} />
                </div>
              </div> : null : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListDocuments;