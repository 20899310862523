import { useAdmin } from '../../contexts/adminContext';
import { useAuth } from '../../contexts/authContext';

import Loading from './Loading';
import { LoginButton, LogoutButton } from '../../components';
import { GreetingMessage } from '../../components';
import { useArea } from '../../contexts/areaContext';

interface ProtectedPageProps {
  isAuthenticated: boolean,
  isLoading: boolean
}

const ProtectedPage = ({ isAuthenticated, isLoading }: ProtectedPageProps) => {
  const { idTokenClaims } = useAuth();
  const { isAdmin } = useAdmin();
  const { currentArea } = useArea();

  if (isLoading) return <Loading height={"min-h-screen"} />

  const getDynamicHeight = () => {
    if (window.innerWidth >= 768) return `calc(100vh - 64px)`;
    return `calc(100vh - 56px)`;
  };

  return (
    <div className="flex bg-white" style={{ height: getDynamicHeight() }}>
      <div className='w-full flex flex-contents md:flex-row'>
        <div className="hidden md:block w-full md:w-1/2 relative">
          <img src="/assets/initial-img.jpeg" alt="Cartoon" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-30" />
        </div>
        <div className="flex flex-col justify-center items-center bg-gray-100 p-10 text-center w-full md:w-1/2">
          <h1 className="flex text-2xl font-bold text-[#11111f] mb-3"><GreetingMessage /> <span className='ml-2'>&#58;&#41;</span></h1>
          {currentArea === 'backoffice' && isAuthenticated && !isAdmin ? <p className="text-gray-700 text-base">Para acessar o backoffice, é necessário permissão; <span className='font-bold'>contate o(a) administrador(a)</span> para liberar seu acesso.</p> : null}
          {currentArea === 'platform' && isAuthenticated && !idTokenClaims?.client_id ? <p className="text-gray-700 text-base">Para acessar a plataforma, é necessário permissão; <span className='font-bold'>contate o(a) administrador(a)</span> para liberar seu acesso.</p> : null}
          {!isAuthenticated ? <p className="text-gray-700 text-base">Para começar, faça login na sua conta ou cadastre-se!</p> : null}
          <div className="w-full flex justify-center mt-4">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedPage;
