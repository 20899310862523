import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import Datepicker from "react-tailwindcss-datepicker";

import { useAuth } from "../../../contexts/authContext";

import { formatStandardDate, formatDateTime } from "../../../utils/formatDate";
import { MessageRequest } from "../../../utils/types";

const ListConversations = () => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [messageRequests, setMessageRequets] = useState<MessageRequest[]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchField, setSearchField] = useState<string | null>(null);
  const [eraseSearch, setEraseSearch] = useState<boolean>(false);

  const [filteredDate, setFilteredDate] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  const handleFilteredDateChange = (newValue: any) => {
    setFilteredDate(newValue);
  };

  const convertDateForRequest = (dateStr: Date | string) => {
    if (typeof dateStr !== 'string') return formatStandardDate(dateStr);
    if (dateStr.toString().includes('-')) return formatStandardDate(dateStr + 'T00:00:00');
    return dateStr;
  };

  const buildUrlWithFilters = useCallback(async (offset: number, search: string | null) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/message-request?offset=${offset}&limit=10&start_date=${convertDateForRequest(filteredDate.startDate)}&end_date=${convertDateForRequest(filteredDate.endDate)}`;
    if (search) url = url + `&search=${search}`;
    return url;
  }, [filteredDate.startDate, filteredDate.endDate]);

  const onSearch = () => {
    getMessageRequests(undefined, !eraseSearch ? searchField : undefined);
    if (eraseSearch) setSearchField(null);
    if (searchField) setEraseSearch(!eraseSearch);
  };

  const onChangeSearch = (searchStr: string) => {
    if (!searchStr) {
      getMessageRequests(undefined, undefined);
      setEraseSearch(false);
    }
    setSearchField(searchStr);
  }

  const getMessageRequests = useCallback(async (offset = 0, search: string | null = null) => {
    if (token) {
      try {
        setIsLoading(true);

        if (filteredDate.startDate && filteredDate.endDate) {
          const res = await fetch(`${await buildUrlWithFilters(offset, search)}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
          })

          const resJson = await res.json();
          setMessageRequets(resJson.message_requests);
          setCount(resJson.count);
          setIsLoading(false);
        } else {
          setMessageRequets([]);
          setCount(0);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token, filteredDate.startDate, filteredDate.endDate]);

  useEffect(() => {
    getMessageRequests();
  }, [getMessageRequests]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getMessageRequests((value - 1) * 10, searchField ? searchField : null);
  };

  return (
    <div className="pt-6 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">

          <div className="mb-4 flex flex-col justify-between items-start">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Histórico de conversas</h3>
            <span className="text-base font-normal text-gray-500">Listagem com o histórico das conversas agrupadas pelo id da sessão referente a cada uma</span>
          </div>

          <div className="flex flex-col md:flex-row justify-end w-full mt-6">
            <div className="w-full md:w-1/2 xl:w-1/4">
              <Datepicker
                i18n={"pt-br"}
                inputClassName="w-full h-auto text-gray-900 py-3 focus:outline-none focus:border-[#11111f] bg-gray-50 border rounded-lg border-gray-300 text-sm cursor-pointer"
                primaryColor={"indigo"}
                placeholder={"Filtrar por data"}
                showShortcuts={true}
                useRange={false}
                displayFormat={"DD/MM/YYYY"}
                readOnly={true}
                value={filteredDate}
                onChange={handleFilteredDateChange}
                configs={{
                  shortcuts: {
                    today: "Hoje",
                    yesterday: "Ontem",
                    past: period => `Últimos ${period} dias`,
                    currentMonth: "Este mês",
                    pastMonth: "Último mês"
                  },
                }}
              />
            </div>

            <div className="relative w-full mt-4 md:mt-0 md:w-1/2 xl:w-1/3 md:ml-4">
              <input value={!searchField ? '' : searchField} onChange={(e) => onChangeSearch((e.target as HTMLInputElement).value)} type="search" id="search-dropdown" className="block p-3 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none" placeholder="Buscar user_input, output..." required />
              <button onClick={(e) => onSearch()} className="absolute top-0 end-0 p-3 text-sm font-medium h-full text-white bg-[#11111f] hover:bg-[#292c31] rounded-e-lg border border-[#11111f] focus:outline-none">
                {!eraseSearch ? <SearchIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
              </button>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          User input
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          LLM Output
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Created_at
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {filteredDate.endDate && messageRequests?.length !== 0 ? messageRequests?.map((messageRequest, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {messageRequest.id}
                          </td>
                          <td className="p-4 text-sm font-normal whitespace-pre-line w-[350px]">
                            <div className="p-4 bg-gray-200 border border-gray-400 truncate w-[350px]">
                              {messageRequest.user_input}
                            </div>
                          </td>
                          <td className="p-4 text-sm font-normal whitespace-pre-line w-[350px]">
                            <div className="p-4 bg-gray-200 border border-gray-400 truncate w-[350px]">
                              {messageRequest.output}
                            </div>
                          </td>
                          <td className="p-4 whitespace-nowrap text-xs text-center font-normal">
                            {formatDateTime(messageRequest.created_at)}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/bo-conversations/${messageRequest.session_id}`} state={{ messageRequest: messageRequest }} className="p-2 hover:bg-gray-200 border border-gray-300 rounded-md cursor-pointer">
                              <VisibilityOutlinedIcon fontSize='small' />
                            </Link>
                          </td>
                        </tr>
                      )) :
                        <tr>
                          <td className="w-full text-center mx-auto pb-12" colSpan={4}>
                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690257804/jjqw2hfv0t6karxdeq1s.svg" alt="empty states box" />
                            <p className="text-gray-700 font-medium text-lg text-center">Nenhuma mensagem encontrada!</p>
                            <p className="text-gray-500 text-center">Nenhuma mensagem encontrada para os filtros informados. Mude os filtros para visualizar outras mensagens!</p>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ?
            filteredDate.endDate && messageRequests?.length !== 0 ?
              <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
                <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div className="hidden sm:contents">
                    <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
                  </div>

                  <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
                </div>
              </div>
              : null
            : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          }

        </div>
      </div>
    </div >
  )
}

export default ListConversations;
