import React, { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface AdminContextProps {
  isAdmin: boolean;
}

const AdminContext = createContext<AdminContextProps | undefined>(undefined);

interface AdminProviderProps {
  children: ReactNode;
}

function AdminProvider({ children }: AdminProviderProps) {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        setIsAdmin(claims!['https://painel.arkspace.ai/roles']?.includes('admin') || false);
      });
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return <AdminContext.Provider value={{ isAdmin }}>{children}</AdminContext.Provider>;
}

function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }
  return context;
}

export { AdminProvider, useAdmin };