import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAdmin } from './adminContext';

type Area = 'backoffice' | 'platform';

interface AreaContextType {
  currentArea: Area;
  switchArea: (area: Area) => void;
}

const AreaContext = createContext<AreaContextType | undefined>(undefined);

export const useArea = () => {
  const context = useContext(AreaContext);
  if (context === undefined) {
    throw new Error('useArea must be used within an AreaProvider');
  }
  return context;
};

export const AreaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { isAdmin } = useAdmin();

  const [currentArea, setCurrentArea] = useState<Area>(() => {
    // Initial area setting without relying on isAdmin
    const storedArea = localStorage.getItem('currentArea');
    return storedArea ? (storedArea as Area) : 'platform';
  });

  useEffect(() => {
    if (isAdmin && !localStorage.getItem('currentArea')) {
      const areaToSet = 'backoffice';
      setCurrentArea(areaToSet);
      localStorage.setItem('currentArea', areaToSet);
    }
  }, [isAdmin]);

  const switchArea = (area: Area) => {
    if (!isAdmin && area === 'backoffice') {
      console.error('Access denied: Only admins can switch to the backoffice area.');
      return;
    }
    setCurrentArea(area);
    localStorage.setItem('currentArea', area);
    navigate('/');
  };

  return (
    <AreaContext.Provider value={{ currentArea, switchArea }}>
      {children}
    </AreaContext.Provider>
  );
};
