import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';

import { MessageTemplateInterface } from "./types";

export const showApprovalStatus = (messageTemplate: MessageTemplateInterface) => {
  const status = messageTemplate?.approval_status.whatsapp.status;

  if (status === 'approved') {
    return (
      <>
        <CheckCircleIcon fontSize='small' color='success' />
        <span className="text-sm font-normal ml-2 md:ml-1">Aprovado pelo WhatsApp</span>
      </>
    )
  }

  if (status === 'unsubmitted' || status === 'pending' || status === 'received') {
    return (
      <>
        <WatchLaterIcon fontSize='small' color='warning' />
        <span className="text-sm font-normal ml-1">Aguardando aprovação</span>
      </>
    )
  }

  if (status === 'rejected') {
    return (
      <>
        <ErrorIcon fontSize='small' color='error' />
        <span className="text-sm font-normal ml-1">Reprovado pelo WhatsApp</span>
      </>
    )
  }

  if (status === 'disabled') {
    return (
      <>
        <ErrorIcon fontSize='small' color='error' />
        <span className="text-sm font-normal ml-1">Desabilitado pelo WhatsApp</span>
      </>
    )
  }

  if (status === 'paused') {
    return (
      <>
        <ErrorIcon fontSize='small' color='error' />
        <span className="text-sm font-normal ml-1">Pausado por 6h ou 8h pelo WhatsApp</span>
      </>
    )
  }

  return null;
}