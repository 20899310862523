import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

interface DecodedToken {
  'https://painel.arkspace.ai/roles'?: string[];
  [key: string]: any;
}

const withRoleAccess = <P extends object>(WrappedComponent: React.ComponentType<P>, allowedRoles: string[]): React.FC<P> => {
  return (props: P) => {
    const { user } = useAuth0();

    const decodedToken: DecodedToken = user as DecodedToken;
    const userRoles: string[] = decodedToken['https://painel.arkspace.ai/roles'] || [];

    const isAuthorized = allowedRoles.some(role => userRoles.includes(role));

    if (!isAuthorized) {
      // Redirect to an unauthorized page or render a "not authorized" view
      return <Navigate to="/unauthorized" replace />;
    }

    // Render the wrapped component with all its passed props
    return <WrappedComponent {...props} />;
  };
};

export default withRoleAccess;
