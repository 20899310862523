import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';

import { MessageDetail } from './types';

export const messageStatus = (message: MessageDetail) => {
  const status = message.current_status;

  if (status === 'read') {
    return (
      <>
        <CheckCircleIcon fontSize='small' color='success' />
        <span className="text-sm font-normal ml-2 md:ml-1">Lida</span>
      </>
    )
  }

  if (status === 'processing') {
    return (
      <>
        <WatchLaterIcon fontSize='small' color='warning' />
        <span className="text-sm font-normal ml-1">Processando</span>
      </>
    )
  }

  if (status === 'sent') {
    return (
      <>
        <CheckCircleIcon fontSize='small' color='primary' />
        <span className="text-sm font-normal ml-1">Enviada</span>
      </>
    )
  }

  if (status === 'delivered') {
    return (
      <>
        <CheckCircleIcon fontSize='small' color='primary' />
        <span className="text-sm font-normal ml-1">Recebida</span>
      </>
    )
  }

  if (status === 'failed') {
    return (
      <>
        <ErrorIcon fontSize='small' color='error' />
        <span className="text-sm font-normal ml-1">Falhada</span>
      </>
    )
  }

  return null;
}