import * as Pages from '../pages';
import withRoleAccess from '../utils/withRoleAccess'

// Clients
export const ListClientsWithAccess = withRoleAccess(Pages.BackofficeArea.Clients.ListClients, ['admin']);
export const AddClientsWithAccess = withRoleAccess(Pages.BackofficeArea.Clients.AddClient, ['admin']);
export const EditClientsWithAccess = withRoleAccess(Pages.BackofficeArea.Clients.EditClient, ['admin']);

// Pipelines
export const ListPipelinesWithAccess = withRoleAccess(Pages.BackofficeArea.Pipelines.ListPipelines, ['admin']);
export const AddPipelinesWithAccess = withRoleAccess(Pages.BackofficeArea.Pipelines.AddPipeline, ['admin']);
export const EditPipelinesWithAccess = withRoleAccess(Pages.BackofficeArea.Pipelines.EditPipeline, ['admin']);

// Tasks
export const ListTasksWithAccess = withRoleAccess(Pages.BackofficeArea.Tasks.ListTasks, ['admin']);
export const AddTasksWithAccess = withRoleAccess(Pages.BackofficeArea.Tasks.AddTask, ['admin']);
export const EditTasksWithAccess = withRoleAccess(Pages.BackofficeArea.Tasks.EditTask, ['admin']);

// Collections
export const ListCollectionsWithAccess = withRoleAccess(Pages.BackofficeArea.Collections.ListCollections, ['admin']);
export const AddCollectionsWithAccess = withRoleAccess(Pages.BackofficeArea.Collections.AddCollections, ['admin']);
export const EditCollectionsWithAccess = withRoleAccess(Pages.BackofficeArea.Collections.EditCollections, ['admin']);

// Documents
export const ListDocumentsWithAccess = withRoleAccess(Pages.BackofficeArea.Documents.ListDocuments, ['admin']);
export const AddDocumentsWithAccess = withRoleAccess(Pages.BackofficeArea.Documents.AddDocument, ['admin']);
export const EditDocumentsWithAccess = withRoleAccess(Pages.BackofficeArea.Documents.EditDocument, ['admin']);

// Document Embeddings
export const EditEmbeddingsWithAccess = withRoleAccess(Pages.BackofficeArea.Embeddings.EditEmbedding, ['admin']);

// Conversations
export const ListConversationsWithAccess = withRoleAccess(Pages.BackofficeArea.Conversations.ListConversations, ['admin']);
export const ConversationDetailsWithAccess = withRoleAccess(Pages.BackofficeArea.Conversations.ConversationDetails, ['admin']);

// LLM Models
export const ListLLMModelsWithAccess = withRoleAccess(Pages.BackofficeArea.LLMModels.ListLLMModels, ['admin']);
export const AddLLMModelsWithAccess = withRoleAccess(Pages.BackofficeArea.LLMModels.AddLLMModel, ['admin']);
export const EditLLMModelsWithAccess = withRoleAccess(Pages.BackofficeArea.LLMModels.EditLLMModel, ['admin']);

// Pipeline Fixtures
export const ListPipelineFixturesWithAccess = withRoleAccess(Pages.BackofficeArea.PipelineFixtures.ListPipelineFixtures, ['admin']);
export const AddPipelineFixturesWithAccess = withRoleAccess(Pages.BackofficeArea.PipelineFixtures.AddPipelineFixture, ['admin']);
export const EditPipelineFixturesWithAccess = withRoleAccess(Pages.BackofficeArea.PipelineFixtures.EditPipelineFixture, ['admin']);

// Metrics
export const ListMetricsWithAccess = withRoleAccess(Pages.BackofficeArea.Metrics.ListMetrics, ['admin']);
export const AddMetricsWithAccess = withRoleAccess(Pages.BackofficeArea.Metrics.AddMetric, ['admin']);
export const EditMetricsWithAccess = withRoleAccess(Pages.BackofficeArea.Metrics.EditMetric, ['admin']);