import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { UUID } from "crypto";

import { MessageRequest } from "../../../utils/types";
import { formatTime } from "../../../utils/formatDate";

interface ViewConversationI {
  conversation: MessageRequest[];
  getTracelog: (messageRequestId: UUID) => void
  isLoading: boolean;
}

const ViewConversation = ({ conversation, getTracelog, isLoading }: ViewConversationI) => {
  const getObjectText = (obj: { [key: string]: any }): string | null => {
    return obj?.text ?? null;
  };

  const showUserInput = (message: MessageRequest) => {
    if (message.user_input.includes('"type":"image/') || message.user_input.includes('"type":"video/')) {
      const userInputObj = JSON.parse(message.user_input);
      const messageText = getObjectText(userInputObj);

      return (
        <li className="flex flex-col items-start justify-start">
          <div className="relative max-w-xl py-2 text-gray-500">
            <span className="text-xs block">{formatTime(message.created_at ? message.created_at : '')}</span>
          </div>
          <div className="relative max-w-xl p-4 text-gray-700 bg-zinc-200 rounded shadow-md">
            <span className="block">{messageText ? messageText : `Mensagem sem conteúdo textual, apenas mídia ${userInputObj.type}` }</span>
          </div>
          <div className="flex items-center mt-2 text-gray-500">
            <span className="block text-xs">Message type: {userInputObj.type}</span>
            <Link className='ml-2' to={userInputObj.uri} target="_blank" rel="noopener noreferrer">
              <OpenInNewIcon className='h-4 w-4' fontSize='inherit' />
            </Link>
          </div>
        </li>
      )
    }

    return (
      <li className="flex flex-col items-start justify-start">
        <div className="relative max-w-xl py-2 text-gray-500">
          <span className="text-xs block">{formatTime(message.created_at ? message.created_at : '')}</span>
        </div>
        <div className="relative max-w-xl p-4 text-gray-700 bg-zinc-200 rounded shadow-md">
          <span className="block">{message.user_input}</span>
        </div>
      </li>
    )
  }

  return (
    <div className="bg-white shadow border rounded-lg w-full">
      <div className={`${!conversation ? 'flex justify-center h-[39rem]' : 'relative h-[36.5rem]'} w-full p-6 overflow-y-auto`}>
        <ul className="space-y-4">
          {conversation.length && !isLoading ? conversation.map((message, index) =>
            <div className='bg-gray-50 p-4 rounded-md' key={index}>
              <div className="flex justify-end">
                <button className="cursor-pointer" onClick={() => getTracelog(message.id)}>
                  <TroubleshootOutlinedIcon fontSize='small' />
                </button>
              </div>
              {message.user_input ?
                showUserInput(message)
                : null}
              {message.output ?
                <li className="flex flex-col items-end justify-end py-2">
                  <div className="relative max-w-xl py-2 text-gray-500">
                    <span className="text-xs block">{formatTime(message.created_at ? message.created_at : '')}</span>
                  </div>
                  <div className={`relative max-w-xl p-4 text-gray-700 bg-sky-200 rounded shadow-md`}>
                    <span className="block">{message.output}</span>
                  </div>
                </li>
                : null}
            </div>
          ) :
            <>
              <div className='flex flex-col items-start'>
                <Skeleton variant="text" sx={{ width: '50%', fontSize: '2.5rem', marginBottom: '-0.5rem' }} />
                <Skeleton variant="text" sx={{ width: '60%', fontSize: '2.5rem', marginBottom: '-1rem' }} />
              </div>
              <div className='flex flex-col items-end'>
                <Skeleton variant="text" sx={{ width: '50%', fontSize: '2.5rem', marginBottom: '-0.5rem' }} />
                <Skeleton variant="text" sx={{ width: '60%', fontSize: '2.5rem', marginBottom: '-1rem' }} />
              </div>
              <div className='flex flex-col items-start'>
                <Skeleton variant="text" sx={{ width: '70%', fontSize: '2.5rem', marginBottom: '-1rem' }} />
              </div>
              <div className='flex flex-col items-end'>
                <Skeleton variant="text" sx={{ width: '40%', fontSize: '2.5rem', marginBottom: '-0.5rem' }} />
                <Skeleton variant="text" sx={{ width: '80%', fontSize: '2.5rem', marginBottom: '-0.5rem' }} />
                <Skeleton variant="text" sx={{ width: '30%', fontSize: '2.5rem', marginBottom: '-1rem' }} />
              </div>
            </>
          }
        </ul>
      </div>
    </div>
  );
}

export default ViewConversation;
