import { useLocation } from "react-router-dom";
import CollectionsForm from "./Form";

const EditCollections = () => {
  const location = useLocation();
  const { collection } = location.state;

  return (<CollectionsForm collection={collection} formName="Editar" />)
}

export default EditCollections;