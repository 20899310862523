import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const formatDateTime = (dateTimeStr: string): string => {
  const zonedDate = utcToZonedTime(dateTimeStr, 'America/Sao_Paulo');
  return format(zonedDate, 'dd/MM/yyyy - HH:mm');
}

export const formatTime = (dateTimeStr: string): string => {
  const zonedDate = utcToZonedTime(dateTimeStr, 'America/Sao_Paulo');
  return format(zonedDate, 'HH:mm');
}

export const formatStandardDate = (standardDateTime: Date | string): string => {
  if (typeof standardDateTime === 'string') return format(new Date(standardDateTime), 'dd/MM/yyyy');
  return format(standardDateTime, 'dd/MM/yyyy');
}
