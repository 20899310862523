import { useLocation } from "react-router-dom";
import PipelinesForm from "./Form";

const EditPipeline = () => {
  const location = useLocation();
  const { pipeline } = location.state;

  return (<PipelinesForm pipeline={pipeline} formName="Editar" />)
}

export default EditPipeline;