import { useLocation } from "react-router-dom";
import DocumentsForm from "./Form";

const EditDocument = () => {
  const location = useLocation();
  const { document, collection } = location.state;

  return(<DocumentsForm document={document} collection={collection} formName="Editar" />)
}

export default EditDocument;