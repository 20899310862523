import { useLocation } from "react-router-dom";
import MessageTemplateForm from "./Form";

const EditMessageTemplate = () => {
  const location = useLocation();
  const { messageTemplate } = location.state;

  return (<MessageTemplateForm formName="Editar" messageTemplate={messageTemplate} />)
}

export default EditMessageTemplate;