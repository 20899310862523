import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useAuth } from "../../../contexts/authContext";

import { ClientInterface } from "../../../utils/types";

const ListClients = () => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [clients, setClients] = useState<[ClientInterface]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  useEffect(() => {
    if (!clients) getClients();
  });

  const getClients = async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client?offset=${offset}&limit=10`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setClients(resJson.clients);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getClients((value - 1) * 10);
  };

  return (
    <div className="pt-6 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 flex flex-col justify-between items-start">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Clientes</h3>
            <span className="text-base font-normal text-gray-500">Listagem com todos as empresas clientes do Chatspace</span>
          </div>
          <Link to="/clients/create" className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
            Cadastrar cliente
            <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
            </svg>
          </Link>
          <div className="flex flex-col mt-4">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nome
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Descrição
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Habilitado
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {clients?.map((client, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            <span className="font-normal">{client.id}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            <span className="font-semibold">{client.name}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal truncate max-w-md">
                            <span className="font-normal">{client.description}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm text-center">
                            {client.enabled ? 'Sim' : 'Não'}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/clients/${client.id}`} state={{ client: client }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                              <EditNoteIcon fontSize='medium' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div>
          </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

        </div>
      </div>
    </div>
  )
}

export default ListClients;