import Skeleton from '@mui/material/Skeleton';

import { formatDateTime, formatStandardDate } from '../../../utils/formatDate';
import { formatPhoneNumber } from '../../../utils/formatPhonenumber';

import { TwilioEvent } from '../../../utils/types';

interface FilteredDateI {
  startDate: Date;
  endDate: Date;
}

interface ListContactsI {
  contacts: TwilioEvent[] | null;
  currentContact: TwilioEvent | null;
  getContact: (contact: TwilioEvent) => void;
  filteredDate: FilteredDateI;
  isLoading: boolean;
  isToday: boolean;
}

const ListContacts = ({ currentContact, contacts, getContact, filteredDate, isLoading, isToday }: ListContactsI) => {
  const showDate = () => {
    if (isToday) return 'Conversas de hoje';
    return `${formatStandardDate(filteredDate.startDate)} ~ ${formatStandardDate(filteredDate.endDate)}`;
  }

  return (
    <div className="bg-white border rounded-ss-lg border-r w-1/3">

      <div className="mx-4 my-5">
        <span className='text-xl font-bold'>{showDate()}</span>
      </div>

      <ul className="overflow-auto h-[36.5rem]">
        {!isLoading ? <li className='divide-y divide-gray-300'>
          {contacts?.length !== 0 ? contacts?.map((contact, index) => (
            <button
              key={index}
              onClick={() => getContact(contact)}
              className={`flex w-full items-center px-4 py-3 text-sm transition duration-150 ease-in-out cursor-pointer ${currentContact === contact ? 'bg-gray-100' : 'hover:bg-gray-100'} focus:outline-none`}>
              <img className="w-10 h-10" src="/assets/platform/anon-user.png" alt="user" />
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between">
                  <span className="block ml-2 font-semibold text-gray-600">{contact.author_name ? contact.author_name : formatPhoneNumber(contact.author.replace('whatsapp:', ''))}</span>
                  <span className="block ml-2 text-sm text-gray-600">{formatDateTime(contact.created_at ? contact.created_at : '')}</span>
                </div>
                {contact.author_name ? <span className="flex justify-start block ml-2 text-sm text-gray-600">{formatPhoneNumber(contact.author.replace('whatsapp:', ''))}</span> : null}
              </div>
            </button>
          )) : null}
        </li> :
          <>
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '4rem', marginX: '1rem', marginTop: '-2rem' }} />
          </>}
      </ul>
    </div>
  );
}

export default ListContacts;

