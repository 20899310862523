import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import { useAuth } from '../../../../contexts/authContext';

const IntegrationsSettings = () => {
  const { idTokenClaims, token } = useAuth();

  const [whatsappInfo, setWhatsappInfo] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!whatsappInfo) getWhatsAppInfo();
  });

  const getWhatsAppInfo = async () => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/phone-numbers/${idTokenClaims?.client_id}/phone-number`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setWhatsappInfo(resJson);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="pt-4 px-6">
      {!isLoading ?
        <>
          <div className="flex flex-col md:flex-row items-start lg:items-center justify-between bg-white rounded-lg border p-6">
            <div className="flex flex-col md:flex-row items-start md:items-center">
              <img className="rounded-md w-16 h-16 mr-4 mb-4 md:mb-0" src="/assets/avatar-female.png" alt="Profile" />
              <div className='flex flex-col'>
                <h2 className="text-base font-bold text-gray-800 mb-2">Está com dúvidas relacionadas às integrações?</h2>
                <p className="text-sm text-gray-600">Acesse o nosso portal de ajuda para saná-las!</p>
              </div>
            </div>
            <div className='mt-6 lg:mt-0'>
              <Link to='/help' className="text-white rounded px-4 py-2 bg-[#11111f] hover:bg-[#292c31]">Ajuda</Link>
            </div>
          </div>

          <div className="flex items-center justify-between pt-7">
            <div>
              <h2 className="text-base font-bold text-gray-900">Canais</h2>
              <span className="text-sm font-normal text-gray-600">Gerencie os canais que estão integrados à sua IA!</span>
            </div>
          </div>

          <div className="divide-y divide-gray-300 pt-3">
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center">
                <img className="w-8 h-8 mr-3" src="/assets/channels/whatsapp-logo.png" alt="Whatsapp" />
                <div className='flex flex-col pr-4'>
                  <p className="font-bold text-sm">WhastApp</p>
                  <span className="hidden md:block text-gray-600 text-sm mt-1">Converse com seus clientes no canal mais utilizado do mundo.</span>
                </div>
              </div>
              <div className="flex items-center">
                <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                  Ativo
                </span>
                <Link to={`/settings/integrations/whatsapp`} state={{ whatsappInfo: whatsappInfo }} className="px-2.5 pt-1 pb-2 bg-white hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                  <SettingsOutlinedIcon fontSize='small' />
                </Link>
              </div>
            </div>

            <div className="flex items-center justify-between py-4">
              <div className="flex items-center">
                <img className="w-8 h-8 mr-3" src="/assets/channels/instagram-logo.png" alt="Instagram" />
                <div className='flex flex-col pr-4'>
                  <p className="font-bold text-sm">Instagram</p>
                  <span className="hidden md:block text-gray-600 text-sm mt-1">Interaja diretamente com seus seguidores no app líder de comunicação visual.</span>
                </div>
              </div>
              <div className="flex items-center">
                <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                  Indisponível
                </span>
                <div className="px-2 pt-0.5 pb-2 bg-gray-300 border border-gray-300 rounded-md cursor-not-allowed">
                  <UnpublishedOutlinedIcon fontSize='small' />
                </div>
                {/* <div className="px-2.5 pt-1 pb-2 bg-gray-300 border border-gray-300 rounded-md cursor-not-allowed">
              <UnpublishedOutlinedIcon fontSize='small' />
            </div> */}
              </div>
            </div>

            <div className="flex items-center justify-between py-4">
              <div className="flex items-center">
                <img className="w-7 h-7 mr-3" src="/assets/channels/messenger-logo.png" alt="Instagram" />
                <div className='flex flex-col pr-4'>
                  <p className="font-bold text-sm">Messenger</p>
                  <span className="hidden md:block text-gray-600 text-sm mt-1">Converse com seus clientes pela plataforma de mensagens de alcance global.</span>
                </div>
              </div>
              <div className="flex items-center">
                <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                  Indisponível
                </span>
                <div className="px-2 pt-0.5 pb-2 bg-gray-300 border border-gray-300  rounded-md cursor-not-allowed">
                  <UnpublishedOutlinedIcon fontSize='small' />
                </div>
              </div>
            </div>

          </div>
        </> :
        <>
          <Skeleton variant="text" sx={{ fontSize: '8rem', marginTop: '-2.5rem' }} />

          <Skeleton variant="text" sx={{ fontSize: '1rem', marginTop: '-1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

          <Skeleton variant="text" sx={{ fontSize: '15rem', marginTop: '-3.5rem', marginBottom: '-3.5rem' }} />
        </>}
    </div>
  );
}

export default IntegrationsSettings;