const PasswordSettings = () => {
  return (
    <div className="mb-4 flex flex-col md:flex-row justify-between py-4 px-6">
      <div className='flex flex-col w-full md:w-1/3 pr-4'>
        <span className="text-base font-bold text-gray-900 mb-1">Mudar senha</span>
        <span className="text-sm font-normal text-gray-500">Atualize a senha associada a sua conta.</span>
      </div>

      <div className='flex flex-col w-full md:w-2/3 mt-8 md:mt-0'>

        <div className="flex flex-col">
          <div className="align-middle inline-block min-w-full">

            <div className="md:flex items-center">
              <div className="w-full flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Senha atual</label>
                  <input
                    type="text"
                    className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border-gray-200 border rounded`}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mt-6">
              <div className="w-full flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Nova senha</label>
                  <input
                    type="text"
                    className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border-gray-200 border rounded`}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mt-6">
              <div className="w-full flex flex-col mt-3 md:mt-0">
                <div className="flex flex-col">
                  <label className="font-semibold leading-none">Confirme nova senha</label>
                  <input
                    type="text"
                    className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-white border-gray-200 border rounded`}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>

            <button className="mt-6 font-normal leading-none text-white py-3 px-6 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none">
              Atualizar
            </button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordSettings;
