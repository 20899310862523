import { ChangeEvent } from "react";

import { CustomListbox } from "../../../../../components";

interface AudienceInterface {
  id: string,
  name: string
}

interface AudienceStepI {
  handleAudienceNext: React.MouseEventHandler<HTMLButtonElement>;
  audienceList: AudienceInterface[];
  selectedAudience: AudienceInterface | null;
  setSelectedAudience: React.Dispatch<React.SetStateAction<AudienceInterface | null>>;
  phoneNumber: string;
  selectedFile: File | null | undefined;
  handlePhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRemoveSelectedFile: Function;
  handleDrop: (event: React.DragEvent<HTMLLabelElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLLabelElement>) => void
}

const AudienceStep = ({ handleAudienceNext, audienceList, selectedAudience, setSelectedAudience, phoneNumber, handlePhoneChange, selectedFile, handleFileChange, handleRemoveSelectedFile, handleDrop, handleDragOver }: AudienceStepI) => {
  return (
    <>
      <div className="mb-4 md:flex items-center justify-between">
        <div className="w-full flex flex-col">
          <h3 className="text-xl font-bold text-gray-900 mb-2">Público</h3>
          <span className="text-base font-normal text-gray-500">Selecione o tipo de mensagem ativa que deseja enviar. Em seguida, informe qual seu público.</span>
        </div>
      </div>

      <div className="flex flex-col my-4">
        <div className="align-middle inline-block min-w-full">

          <div className="md:flex items-center">
            <div className={`w-full flex flex-col ${selectedAudience?.id === '2' ? 'md:w-1/2 mt-4 md:-mt-4' : 'mt-3'}`}>
              <CustomListbox list={audienceList} selected={selectedAudience} setSelected={setSelectedAudience} placeholder='Selecione o público' />
            </div>

            {selectedAudience?.id === '2' ?
              <div className="w-full md:w-1/2 flex flex-col md:ml-6 mt-3 md:mt-0">
                <div className="flex flex-col">
                  <div className="flex mt-4">
                    <input
                      type="text"
                      value="+55"
                      className="w-1/6 flex flex-col leading-none text-gray-900 p-3 shadow bg-gray-200 cursor-not-allowed border rounded border-gray-200 rounded-r-none"
                      disabled
                    />
                    <input
                      type="text"
                      className={`w-full md:w-5/6 flex flex-col leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 border rounded border-gray-200 rounded-l-none`}
                      value={phoneNumber.replace("+55", "")}
                      onChange={handlePhoneChange}
                      placeholder="(xx) 9xxxx-xxxx"
                      required
                    />
                  </div>
                  <div className="pt-2">
                    <span className="text-xs font-normal text-gray-500 mt-2">Número de telefone seguindo o formato acima.</span>
                  </div>
                </div>
              </div> : null}
          </div>

          {selectedAudience?.id === "1" ?
            <div className="w-full flex flex-col mt-3">
              <div className="flex flex-col">
                <div className="flex items-center justify-center w-full mt-4">
                  {!selectedFile ?
                    <label htmlFor="dropzone-file" onDragOver={handleDragOver} onDrop={handleDrop} className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-100">
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>

                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Selecione um documento</span> ou arraste e solte</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">CSV</p>
                      </div>
                      <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept="text/csv" />
                    </label>
                    :
                    <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg bg-white">
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>

                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">{selectedFile.name}</span></p>
                        <button className="text-xs text-gray-500 dark:text-gray-400 cursor-pointer hover:underline" onClick={() => handleRemoveSelectedFile()}>Remover</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="pt-2">
                <span className="text-xs font-normal text-gray-500 mt-2">
                  Para cadastar uma lista de números, existem alguns padrões a serem seguidos: <br />
                  <b>1.</b> Apenas documento no formato CSV é aceito; <br />
                  <b>2.</b> O formato do número de telefone disponível no CSV deve ser o seguinte: +5599999999999. Ex: +5531983005411; <br />
                  <b>2.1</b> Perceba que o número não contém caracteres especiais com excessão do "+" para identificar o código do país; <br />
                  <b>3.</b> O documento CSV deve conter obrigatoriamente duas colunas, a primeira que indica o nome do contato e a segunda contendo o número de telefone, respectivamente. <br />
                  <b>3.1</b> A coluna que identifica o nome deve se chamar <b><i>name</i></b> e a coluna que identifica o número deve se chamar <b><i>phone_number</i></b>. <br />
                  <b>3.2</b> No caso do template escolhido ter variáveis, deve conter colunas adicionais referentes a quantidade de variáveis com o nome: <b><i>variable_1</i></b>, <b><i>variable_2</i></b> e por ai vai.
                </span>
              </div>
            </div> : null}

        </div>
      </div>

      <div className="flex flex-row justify-end pt-4">
        <button className="font-normal leading-none text-white py-3 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" onClick={handleAudienceNext}>Próximo</button>
      </div>
    </>
  );
}

export default AudienceStep;
