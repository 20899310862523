interface VariableObject {
  id: number;
  name: string;
  value: string;
}

export const parseVariables = (content: string): VariableObject[] => {
  // Regular expression to find {{variable}} patterns
  const variableRegex = /{{(.*?)}}/g;
  let match: RegExpExecArray | null;
  const variablesArray: VariableObject[] = [];

  let id = 1;
  while ((match = variableRegex.exec(content)) !== null) {
    variablesArray.push({
      id: id++,
      name: match[1].trim(),
      value: ""
    });
  }
  return variablesArray;
};
