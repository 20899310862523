import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { UUID } from "crypto";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useAuth } from "../../../../../contexts/authContext";

import { DocumentEmbeddingInterface } from "../../../../../utils/types";

interface ListEmbeddingsI {
  document_id: UUID | undefined;
}

const ListEmbeddings = ({ document_id }: ListEmbeddingsI) => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [embeddings, setEmbeddings] = useState<DocumentEmbeddingInterface[]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filteredContent, setFilteredContent] = useState<string | null>(null);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/document-embedding/list/${document_id}?offset=${offset}&limit=10`;
    if (filteredContent) url = url + `&content=${filteredContent}`;
    return url;
  }, [filteredContent, document_id]);

  const getEmbeddings = useCallback(async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'X-Key': `${window.REACT_APP_HEADER_SECRET}` }
        })

        const resJson = await res.json();
        const resEmbeddings = resJson.documents_embeddings;
        setEmbeddings(resEmbeddings);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token]);

  useEffect(() => {
    getEmbeddings();
  }, [getEmbeddings]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getEmbeddings((value - 1) * 10);
  };

  return (
    <div className="my-4 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Embeddings</h3>
              <span className="text-base font-normal text-gray-500">Listagem com os vetores desse documento.</span>
            </div>
          </div>

          <div className="mt-8 flex flex-col justify-between items-center md:flex-row">
            <div className="flex flex-row w-full md:pr-1">
              <div className="relative w-full">
                <input
                  type="search"
                  className={`w-full leading-none text-gray-900 pl-11 py-3 shadow focus:outline-none focus:border-[#11111f] bg-white border rounded border-gray-200 font-medium`}
                  placeholder="Pesquisar por conteúdo..."
                  value={!filteredContent ? '' : filteredContent}
                  onChange={(e) => setFilteredContent(e.target.value)}
                />
                <div className="absolute top-0 left-0 inline-flex items-center p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                    strokeLinejoin="round">
                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Conteúdo
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {embeddings?.map((embedding, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal max-w-sm">
                            {embedding.id}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal truncate max-w-md">
                            {embedding.content}
                          </td>

                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/embeddings/${embedding.id}`} state={{ embedding: embedding }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                              <EditNoteIcon fontSize='medium' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div>
          </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

        </div>
      </div>
    </div>
  )
}

export default ListEmbeddings;
