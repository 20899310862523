import { useLocation } from "react-router-dom";
import TasksForm from "./Form";

const EditTask = () => {
  const location = useLocation();
  const { task, pipeline, client_id } = location.state;

  return (<TasksForm client_id={client_id} pipeline={pipeline} task={task} formName="Editar" />)
}

export default EditTask;