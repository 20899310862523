import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { toast } from "react-toastify";

import { useAuth } from "../../../../../contexts/authContext";

import { toastErrors } from '../../../../../utils/toastErrors';
import { LoadingDots } from '../../../../../components/LoadingDots';
import { ConfirmationModal } from "../../../../../components";

// import { formatDateTime } from "../../../../utils/formatDate";

const EditEmbedding = () => {
  const location = useLocation();
  const { embedding } = location.state;

  const { token } = useAuth();

  const [content, setContent] = useState(embedding.content);

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/collections">Collections</Link>,
    <Link className="hover:underline" key="3" color="inherit" to={`/collections/${embedding.document.collection?.id}`} state={{ collection: embedding.document.collection }}>{embedding.document.collection.name}</Link>,
    <Link className="hover:underline" key="2" color="inherit" to={`/documents/${embedding.document.id}`} state={{ document: embedding.document, collection: embedding.document.collection }}>{embedding.document.name}</Link>,
    <Typography key="3" color="text.primary">Editar embedding</Typography>
  ];

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const form = { content: content, document_id: embedding.document.id };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/document-embedding/${embedding?.id}`, {
        method: 'PUT',
        headers: { "Content-Type": "application/json", 'X-Key': `${window.REACT_APP_HEADER_SECRET}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: 'Atualizado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/documents/${embedding.document.id}`, { state: { collection: embedding.document.collection, document: embedding.document } });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center mt-3 justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Editar vetor</h3>
                <span className="text-base font-normal text-gray-500">Um vetor equivale a uma linha do "conhecimento" a ser passado para um modelo como base de conhecimento</span>
              </div>
            </div>

            <div className="flex flex-col mt-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">

                    <ConfirmationModal action={action} entity="collection" open={open} setOpen={setOpen} handleAction={handleForm} />

                    <div className="w-full flex flex-col mt-3">
                      <label className="font-semibold leading-none">Conteúdo</label>
                      <textarea
                        className="h-40 text-base text-gray-900 p-3 shadow focus:oultine-none focus:border-blue-700 mt-4 bg-white border rounded border-gray-200"
                        value={!content ? '' : content}
                        onChange={e => setContent(e.target.value)}
                        required
                      />
                    </div>

                    {/* <div className="mt-4">
                      <span className="text-sm opacity-50">Última atualização: {embedding?.updated_at ? formatDateTime(embedding?.updated_at!) : 'não teve atualização!'}</span>
                    </div> */}

                    <div className="flex items-center justify-end w-full">
                      <button type="submit" onClick={() => { setOpen(true); setAction('Atualizar'); }} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                        {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : 'Atualizar'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEmbedding;
