import React, { useEffect } from 'react';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';

import { useAuth } from '../../../../contexts/authContext';

import { MessageTemplateInterface } from '../../../../utils/types';
import { parseVariables } from '../../../../utils/parseVariables';

import { toastErrors } from '../../../../utils/toastErrors';
import { LoadingDots } from '../../../../components/LoadingDots';

import { AudienceStep, ContentStep, SendStep } from './steps';

interface AudienceInterface {
  id: string,
  name: string
}

interface VariableObject {
  id: number;
  name: string;
  value: string;
}

const MessageBroadcastForm = () => {
  const { token, idTokenClaims } = useAuth();

  const steps = ["Público", "Conteúdo", "Envio"];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  const [messageTemplates, setMessageTemplates] = useState<MessageTemplateInterface[] | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!messageTemplates) getMessageTemplates();
  });

  const getMessageTemplates = async () => {
    if (token) {
      try {
        setLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client-message-templates/${idTokenClaims?.client_id}?pageSize=1000`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();

        setMessageTemplates(resJson.contents);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  /** Audience states begin
   * All functions to be handled in the local context
   * */
  const [audienceList] = useState<AudienceInterface[]>([{ id: '1', name: 'Envio em massa' }, { id: '2', name: 'Envio individual' }]);
  const [selectedAudience, setSelectedAudience] = useState<AudienceInterface | null>(null);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>();

  const formatPhoneNumber = (input: string) => {
    // Remove all non-numeric characters
    let numbers = input.replace(/\D/g, '');

    // Apply formatting (xx) xxxx-xxxx
    let formatted = '';
    if (numbers.length >= 1) {
      formatted = `(${numbers.substring(0, 2)}`;
    }
    if (numbers.length > 2) {
      formatted += `) ${numbers.substring(2, 7)}`;
    }
    if (numbers.length > 7) {
      formatted += `-${numbers.substring(7, 11)}`;
    }
    return `${formatted.includes("+55") ? '' : '+55 '}${formatted}`;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedNumber);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    setSelectedFile(files![0]);
  };

  const handleRemoveSelectedFile = () => {
    setSelectedFile(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files.length === 0) return;

    const file = files[0];
    const acceptedFormats = ['text/csv'];

    if (acceptedFormats.includes(file.type)) {
      setSelectedFile(file);
    } else {
      // Handle invalid format (e.g., show a toast or an error message)
      toast.error("Formato do arquivo inválido. Apenas CSV é permitido.");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const isNumberFormatted = (phone: string): boolean => {
    const pattern = /^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/;
    return pattern.test(phone);
  }

  const handleAudienceNext: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!selectedAudience) return toast.error("Selecione o tipo de mensagem ativa que deseja enviar.");
    if (selectedAudience.id === "1" && !selectedFile) return toast.error("Selecione um aquivo CSV contendo as informações necessárias para continuar.");
    if (selectedAudience.id === "2" && (!phoneNumber || !isNumberFormatted(phoneNumber))) return toast.error("Preencha o número corretamente para realizar o envio.");

    handleComplete();
  };
  //** Audience states end */ 

  /** Content states begin
   * All functions to be handled in the local context
   * */
  const [templateSelected, setTemplateSelected] = useState<MessageTemplateInterface | null>(null);
  const [templateQuery, setTemplateQuery] = useState('');

  const [variables, setVariables] = useState<VariableObject[]>([]);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (!templateSelected) return;

    setVariables(parseVariables(templateSelected?.types['twilio/text'].body));
    setMessage(templateSelected?.types['twilio/text'].body);
  }, [templateSelected, setMessage, setVariables]);

  // Event handler for when variable values change
  const onVariableValueChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    // Create a new array of variables with the updated value for the changed variable
    const updatedVariables = variables.map(variable =>
      variable.id === id ? { ...variable, value: e.target.value } : variable
    );

    // Update the variables state with the new array
    setVariables(updatedVariables);

    // Instead of using regex, manually construct the message to debug
    let debugMessage = templateSelected?.types['twilio/text'].body || '';
    updatedVariables.forEach(variable => {
      debugMessage = debugMessage.split(`{{${variable.id}}}`).join(variable.value || `{{${variable.id}}}`);
    });

    // Update the message based on the new variable values
    setMessage(debugMessage);
  };

  const handleContentNext: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!templateSelected) return toast.error("Selecione o template de mensagem que deseja enviar.");
    if (selectedAudience?.id === "2" && variables.length !== 0) {
      const allVariablesCorrectly = variables.every(variable => {
        if (!variable.value) {
          toast.error(`Preencha a variável {{${variable.name}}} corretamente.`);

          return false;
        }

        return true;
      });

      if (allVariablesCorrectly) return handleComplete();
      else handleUncomplete();
    }
    if (selectedAudience?.id === "2" && variables.length === 0) return handleComplete();
    if (selectedAudience?.id === "1") return handleComplete();
  };
  //** Content states end */ 

  /** Send states begin
   * All functions to be handled in the local context
   * */
  const [campaignName, setCampaignName] = useState<string>('');
  const [scheduleDateTime, setScheduleDateTime] = useState<string | null>(null);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages">Disparo ativo</Link>,
    <Link className="hover:underline" key="3" color="inherit" to="/active-messages/message-broadcast">Campanhas de mensagens ativas</Link>,
    <Typography key="3" color="text.primary">Enviar mensagens ativas</Typography>
  ];

  const convertToTimestampTz = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString();  // Convert to UTC
  };

  const isWithinOneWeek = (scheduleUTCString: string) => {
    const scheduleUTC = new Date(scheduleUTCString);
    const nowUTC = new Date();
    const oneWeekLater = new Date(nowUTC.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days later
    return scheduleUTC <= oneWeekLater;
  };

  const isFutureSchedule = (scheduleUTCString: string) => {
    const scheduleUTC = new Date(scheduleUTCString);
    const nowUTC = new Date();  // Current time in UTC

    return scheduleUTC > nowUTC;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUncomplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted(newCompleted);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const renderContent = () => {
    if (activeStep === 0) return <AudienceStep handleAudienceNext={handleAudienceNext} audienceList={audienceList} selectedAudience={selectedAudience} setSelectedAudience={setSelectedAudience} phoneNumber={phoneNumber} handlePhoneChange={handlePhoneChange} selectedFile={selectedFile} handleFileChange={handleFileChange} handleRemoveSelectedFile={handleRemoveSelectedFile} handleDrop={handleDrop} handleDragOver={handleDragOver} />
    else if (activeStep === 1) return <ContentStep handleContentNext={handleContentNext} handleBack={handleBack} messageTemplates={messageTemplates!} templateSelected={templateSelected} setTemplateSelected={setTemplateSelected} templateQuery={templateQuery} setTemplateQuery={setTemplateQuery} selectedFile={selectedFile!} selectedAudience={selectedAudience!} variables={variables} setVariables={setVariables} message={message} setMessage={setMessage} onVariableValueChange={onVariableValueChange} />
    else if (activeStep === 2) return <SendStep selectedAudience={selectedAudience!} phoneNumber={phoneNumber} selectedFile={selectedFile!} templateName={templateSelected?.friendly_name} setActiveStep={setActiveStep} campaignName={campaignName} setCampaignName={setCampaignName} scheduleDateTime={scheduleDateTime} setScheduleDateTime={setScheduleDateTime} />
  }

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    let form;
    let scheduleDateFormatted = scheduleDateTime;

    if (scheduleDateTime) {
      scheduleDateFormatted = convertToTimestampTz(scheduleDateTime);

      if (!isFutureSchedule(scheduleDateFormatted)) {
        setLoading(false);
        return toast.update(toastId, {
          render: "Agendamento da mensagem não pode ser em uma data no passado.",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
      }

      if (!isWithinOneWeek(scheduleDateFormatted)) {
        setLoading(false);
        return toast.update(toastId, {
          render: "Agendamento da mensagem deve respeitar o limite de uma semana.",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
      }
    }

    if (selectedAudience?.id === "1") {
      form = new FormData();

      form.append('client_id', idTokenClaims?.client_id!);
      form.append('campaign_name', campaignName);
      form.append('campaign_type', 'broadcast');
      form.append('data', selectedFile!);
      form.append('meta_category', JSON.stringify(templateSelected?.approval_status.whatsapp.category));

      const schedule = JSON.stringify(scheduleDateFormatted);

      const message_template = JSON.stringify({
        content_sid: templateSelected?.sid,
        value: templateSelected?.types['twilio/text'].body,
      });

      form.append('schedule', schedule);
      form.append('message_template', message_template);
    } else if (selectedAudience?.id === "2") {
      headers.append('Content-Type', 'application/json');

      const variablesObject = variables.reduce<{ [key: string]: string }>((obj, item) => {
        obj[item.name] = item.value;
        return obj;
      }, {});

      form = JSON.stringify({
        client_id: idTokenClaims?.client_id,
        phone_number: phoneNumber.replace(/[^\d+]/g, ''),
        campaign_name: campaignName,
        campaign_type: 'individual',
        meta_category: templateSelected?.approval_status.whatsapp.category,
        schedule: scheduleDateFormatted,
        message: {
          content_sid: templateSelected?.sid,
          rendered: message,
          template: templateSelected?.types['twilio/text'].body,
          variables: variablesObject
        },
      });
    }

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/twilio/message/whatsapp/send/${selectedAudience?.id === "1" ? 'broadcast' : 'individual'}`, {
        method: 'POST',
        headers: headers,
        body: form,
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: `${selectedAudience?.id === "1" ? 'Mensagens enviadas!' : 'Mensagem enviada!'}`,
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);

        navigate(`/active-messages/message-broadcast`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="pt-6 px-4">

      <div className="pt-4 pb-4">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      <div className="py-4 md:flex items-center justify-between">
        <div className="w-full flex flex-col">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Enviar mensagens ativas</h2>
          <span className="text-base font-normal text-gray-500">Siga o passo a passo abaixo e envie mensagens para a sua lista de transmissão a partir de templates aprovados pelo WhatsApp!</span>
        </div>
      </div>

      {!loading ? <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-3 md:p-4 xl:p-6">

          <div className="flex flex-col">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="overflow-hidden sm:rounded-lg mt-4">
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepLabel
                          sx={{
                            '& .MuiStepIcon-root.Mui-active': { color: '#2c2c50' },
                            '& .MuiStepIcon-root.Mui-completed': { color: '#2c2c50' },
                            '& .MuiStepIcon-text': { fontFamily: 'Lexend', fontSize: '12px', color: 'white' },
                            '& .MuiStepLabel-label': { fontFamily: 'Lexend' }
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <Skeleton variant="text" sx={{ fontSize: '8rem', margin: '-1rem auto -1rem' }} />}

      {!loading ? <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">

          {renderContent()}

          {activeStep === steps.length - 1 ?
            <div className="flex flex-row justify-end pt-4">
              <button className="mr-2 font-normal leading-none text-black py-3 px-10 bg-transparent rounded hover:bg-gray-200 focus:outline-none" onClick={handleBack}>Voltar</button>
              <button className={`font-normal leading-none text-white py-3 px-10 bg-[#11111f] rounded focus:outline-none ${!campaignName || loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#292c31]'}`} onClick={!campaignName || loading ? undefined : handleForm}>
                {loading ? <LoadingDots color="white" dotStyle="small" /> : 'Enviar'}
              </button>
            </div>
            : null
          }

        </div>
      </div> : <Skeleton variant="rounded" height={340} />
      }
    </div >
  )
}

export default MessageBroadcastForm;
