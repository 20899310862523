import { toast } from "react-toastify";

export const toastErrors = (toastId: any, errors: any, status?: number) => {
  if (status === 422) {
    return toast.update(toastId, {
      render: "Preencha os campos corretamente.",
      type: "error",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true
    });
  } 

  if (errors.detail) {
    return toast.update(toastId, {
      render: errors.detail,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true
    });
  }

  toast.update(toastId, {
    render: "Ocorreu um erro.",
    type: "error",
    isLoading: false,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true
  });
}