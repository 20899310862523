import { useEffect, useState, Fragment, useRef } from "react";
import { toast } from "react-toastify";
import * as yaml from 'js-yaml';
import { Dialog, Transition } from '@headlessui/react';

import { useAuth } from "../../../contexts/authContext";

import ListRunningTests from "./ListRunningTests";
import { toastErrors } from "../../../utils/toastErrors";
import { LoadingDots } from "../../../components/LoadingDots";
import { CustomCombobox } from "../../../components";
import { Toggle } from "../../../components/Toogle";

import { PipelineFixtureInterface, TaskInterface } from "../../../utils/types";
import Loading from "../../auth/Loading";

interface TasksFormInterface {
  pipelineFixture: PipelineFixtureInterface
}

const RunTestForm = ({ pipelineFixture }: TasksFormInterface) => {
  const { token } = useAuth();

  const toggledRef = useRef(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [promptTemplates, setPromptTemplates] = useState<{ id: string; enabled: boolean }[]>([]);

  const [tasks, setTasks] = useState<any[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<(TaskInterface | null)[]>([]);

  const updateSelectedTask = (index: number, newSelectedTask: TaskInterface | null) => {
    setSelectedTasks(prevState => {
      const newSelectedTasks = [...prevState];
      newSelectedTasks![index] = newSelectedTask;
      return newSelectedTasks;
    });
  };

  const [taskQuery, setTaskQuery] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [taskModalData, setTaskModalData] = useState<TaskInterface | null>(null);

  useEffect(() => {
    try {
      const data: any = yaml.load(pipelineFixture.fixture);

      if (data && data.tasks) {
        const templates = data.tasks.map((task: any) => ({
          id: task?.prompt_template_id,
          enabled: true,
        })).filter((template: any) => template.id);

        setPromptTemplates(templates);
      }
    } catch (e) {
      console.log(e);
    }
  }, [pipelineFixture.fixture]);

  useEffect(() => {
    if (toggledRef.current) {
      toggledRef.current = false;
      return;
    }

    setTasks([]);
    const firstTasks: (TaskInterface | null)[] = [];

    const fetchTasks = async () => {
      for (const promptTemplate of promptTemplates) {
        setIsLoading(true);
        try {
          const response = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task/versions/${promptTemplate.id}?all=true`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
          });
          const data = await response.json();
          setTasks(prevTasks => [...prevTasks, data]);
          firstTasks.push(data.tasks[0] || null);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      }
      setSelectedTasks(firstTasks);
    };

    fetchTasks();
  }, [promptTemplates, pipelineFixture.fixture, token]);


  const togglePromptTemplateEnabled = (templateId: string) => {
    toggledRef.current = true;
    const updatedTemplates = promptTemplates.map(template =>
      template.id === templateId ? { ...template, enabled: !template.enabled } : template
    );

    setPromptTemplates(updatedTemplates);
  };

  async function handleForm() {
    setLoading(true);
    const toastId = toast.loading("Aguarde...");

    const tasksFixtures = selectedTasks.map(task => {
      if (task) {
        const template = promptTemplates.find(pt => pt.id === task.prompt_template_id);

        if (template && template.enabled) {
          return {
            prompt_template_id: task.prompt_template_id,
            version: task.version
          };
        }
      }
      return null;
    }).filter(task => task !== null);

    const form = {
      task_fixtures: tasksFixtures,
      pipeline_fixture_id: pipelineFixture?.id
    };

    try {
      const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/eval/run`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(form),
      });

      const resJson = await res.json();

      if (!res.ok) {
        toastErrors(toastId, resJson, res.status);
        setLoading(false);
        setError(resJson.errors);
      } else {
        toast.update(toastId, {
          render: 'Teste iniciado!',
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderModal = () => {
    return (
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative" style={{ zIndex: "1000" }} onClose={() => setShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto lg:mt-20">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full transform overflow-scroll rounded-2xl bg-white p-4 sm:p-6 xl:p-8 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {taskModalData?.name}
                  </Dialog.Title>

                  <div className="mt-8">
                    <p className="text-md text-bold">Prompt:</p>
                    <span className="text-md text-gray-500"><pre>{taskModalData?.prompt}</pre></span>
                  </div>

                  <div className="mt-8">
                    <p className="text-md text-bold">Function definition:</p>
                    <span className="text-md text-gray-500">{taskModalData?.function_definition}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Modelo:</span><span className="text-md text-gray-500"> {taskModalData?.llm_model.name}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Número de documentos:</span><span className="text-md text-gray-500"> {taskModalData?.k_contexts}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Habilitado:</span><span className="text-md text-gray-500"> {taskModalData?.enabled ? 'Sim' : 'Não'}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Versão:</span><span className="text-md text-gray-500"> {taskModalData?.version}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Collection:</span><span className="text-md text-gray-500"> {taskModalData?.collection?.name}</span>
                  </div>

                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-[#11111f] px-4 py-2 text-sm font-medium text-white hover:bg-[#292c31] focus:outline-none"
                      onClick={() => { setShowModal(false); setTaskModalData(null); }}
                    >
                      Fechar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return (
    <>
      <div className="my-4 px-4">
        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 md:flex items-center mt-3 justify-between">
              <div className="w-full md:w-1/2 flex flex-col">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Executar teste</h3>
                <span className="text-base font-normal text-gray-500">Escolha as versões das tarefas a serem testadas.</span>
              </div>
            </div>

            <div className="flex flex-col mt-8">
              <div className="align-middle inline-block min-w-full">

                {isLoading ? <Loading height={"min-h-[200px]"} /> : tasks.map((task, index) => {
                  // Find the corresponding template from promptTemplates
                  const template = promptTemplates.find(pt => pt.id === task?.tasks[0]?.prompt_template_id);

                  return (
                    <div className={`md:flex items-center ${index === 0 ? 'mt-3' : 'mt-8'}`} key={index}>
                      {renderModal()}

                      <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold leading-none">Task</label>
                        <input
                          type="text"
                          className={`leading-none text-gray-900 p-3 shadow focus:outline-none focus:border-blue-700 mt-4 bg-gray-200 cursor-not-allowed border rounded border-gray-200`}
                          value={task?.tasks[0]?.prompt_template_id}
                          disabled
                        />
                      </div>

                      <div className="flex flex-row w-auto items-center ml-0 mt-4 md:mt-0 md:ml-4" style={{ zIndex: tasks.length - index }}>
                        <div className="w-1/2 flex flex-col">
                          <label className="font-semibold leading-none mb-3">Versão</label>
                          {!isLoading ? <CustomCombobox entity="task" list={task.tasks} selected={selectedTasks[index] ? selectedTasks[index] : task.tasks[0]} setSelected={(selectedTask: any) => updateSelectedTask(index, selectedTask)} query={taskQuery} setQuery={setTaskQuery} displayProperty="version" emptyState={false} /> : null}
                        </div>

                        <div className="w-1/12 md:w-auto flex flex-col ml-4 md:ml-6">
                          {!isLoading ? <div className="mt-7">
                            <Toggle
                              enabled={template ? template.enabled : false}
                              setEnabled={() => togglePromptTemplateEnabled(template?.id!)}
                              showLabel={false}
                            />
                          </div> : null}
                        </div>

                        <div tabIndex={0} className="focus:outline-none relative -mt-9 ml-28 md:mt-7 md:ml-3 invisible md:visible">
                          <div className="cursor-pointer" onClick={() => { setTaskModalData(selectedTasks[index]); setShowModal(true) }}>
                            <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <circle cx="12" cy="12" r="9" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                              <polyline points="11 12 12 12 12 16 13 16" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="flex items-center justify-end w-full">
                  <button type="submit" onClick={handleForm} className="mt-9 font-normal leading-none text-white py-4 px-10 bg-[#11111f] rounded hover:bg-[#292c31] focus:outline-none" disabled={loading}>
                    {loading ? <span><LoadingDots color="white" dotStyle="small" /></span> : 'Executar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ListRunningTests pipeline_id={pipelineFixture.pipeline.id} />
    </>
  )
}

export default RunTestForm;