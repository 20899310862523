import { useCallback, useEffect, useState } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import Datepicker from "react-tailwindcss-datepicker";

import { useAuth } from '../../../contexts/authContext';

import ListContacts from './ListContacts';
import MessageDetails from './MessageDetails';

import { formatStandardDate } from '../../../utils/formatDate';
import { TwilioEvent } from '../../../utils/types';

interface NumberInfo {
  verified_name: string;
  display_phone_number: string;
  quality_rating: string;
}

interface ProfileInfo {
  profile_picture_url: string;
}

interface WhatsappInfo {
  number_info: NumberInfo;
  profile_info: ProfileInfo[]
}

const ListConversations = () => {
  const { idTokenClaims, token } = useAuth();

  const [whatsappInfo, setWhatsappInfo] = useState<WhatsappInfo>();

  const [contacts, setContacts] = useState<TwilioEvent[] | null>(null);
  const [count, setCount] = useState<number>(0);

  const [currentContact, setCurrentContact] = useState<TwilioEvent | null>(null);
  const [contactMessages, setContactMessages] = useState<TwilioEvent[] | null>(null);

  const [page, setPage] = useState<number>(1);
  const startIndex: number = (page - 1) * 9 + 1;
  const endIndex: number = Math.min(startIndex + 9 - 1, count);

  const [filteredName, setFilteredName] = useState<string | null>(null);
  const [filteredPhone, setFilteredPhone] = useState<string | null>(null);

  const [filteredDate, setFilteredDate] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isListContactsLoading, setIsContactsLoading] = useState<boolean>(false);
  const [isMessageDetailsLoading, setIsMessageDetailsLoading] = useState<boolean>(false);

  const handleFilteredDateChange = (newValue: any) => {
    setFilteredDate(newValue);
    setContactMessages(null);
    setCurrentContact(null);
  }

  const convertDateForRequest = (dateStr: Date | string) => {
    if (typeof dateStr !== 'string') return formatStandardDate(dateStr);
    if (dateStr.toString().includes('-')) return formatStandardDate(dateStr + 'T00:00:00');
    return dateStr;
  };

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/twilio/${idTokenClaims?.client_id}?offset=${offset}&limit=9`;
    // if (filteredName) url = url + `&name=${filteredName}`;
    if (filteredPhone) url = url + `&phone_number=${filteredPhone}`;
    if (filteredDate.startDate && filteredDate.endDate) url = url + `&start_date=${convertDateForRequest(filteredDate.startDate)}&end_date=${convertDateForRequest(filteredDate.endDate)}`
    return url;
  }, [filteredPhone, filteredDate.startDate, filteredDate.endDate, idTokenClaims?.client_id]);

  const getContacts = useCallback(async (offset = 0) => {
    if (token) {
      try {
        setIsContactsLoading(true);

        if (filteredDate.startDate && filteredDate.endDate) {
          const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
          })

          const resJson = await res.json();
          setContacts(resJson.twilio_events);
          setCount(resJson.count);
          setIsContactsLoading(false);
        } else {
          setContacts([]);
          setCount(0);
          setIsContactsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token, filteredDate.endDate, filteredDate.startDate]);

  const getOneContact = async (contact: TwilioEvent) => {
    if (token) {
      try {
        setIsMessageDetailsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/twilio/conversations/${contact.conversation_sid}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setCurrentContact(contact);
        setContactMessages(resJson);
        setIsMessageDetailsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getWhatsAppInfo = useCallback(async () => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/phone-numbers/${idTokenClaims?.client_id}/phone-number`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setWhatsappInfo(resJson);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [idTokenClaims?.client_id, token]);

  useEffect(() => {
    getContacts();
    if (!whatsappInfo) getWhatsAppInfo();
  }, [getContacts, filteredDate.startDate, filteredDate.endDate, getWhatsAppInfo, whatsappInfo]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentContact(currentContact);
    getContacts((value - 1) * 9);
  };

  const QualityRating = (quality: string | undefined) => {
    if (quality === 'GREEN') return 'bg-green-100 text-green-800';
    if (quality === 'YELLOW') return 'bg-yellow-100 text-yellow-800';
    if (quality === 'RED') return 'bg-red-100 text-red-800';
  }

  const QualityRatingText = (quality: string | undefined) => {
    if (quality === 'GREEN') return 'Alta';
    if (quality === 'YELLOW') return 'Média';
    if (quality === 'RED') return 'Baixa';
  }

  const convertDate = (dateStr: string): Date => {
    if (dateStr.includes('/')) {
      const parts = dateStr.split("/");
      return new Date(`${parts[2]}-${parts[1]}-${parts[0]}T00:00:00`);
    }

    return new Date(dateStr + 'T00:00:00');
  };

  const isToday = (date: string): boolean => {
    const today = new Date();

    const dateC = convertDate(date);

    return (
      dateC.getDate() === today.getDate() &&
      dateC.getMonth() === today.getMonth() &&
      dateC.getFullYear() === today.getFullYear()
    );
  }

  return (
    <div className="pt-6 pb-2 px-4">

      <div className="mb-4 flex items-center justify-between py-4">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-1">Histórico de conversas</h2>
          <span className="text-base font-normal text-gray-500">Acompanhe aqui o histórico das suas conversas!</span>
        </div>
      </div>

      {!isLoading ? <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-white rounded-lg border p-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center">
          <img className="rounded-md w-16 h-16 mr-4 mb-4 md:mb-0" src={whatsappInfo?.profile_info[0].profile_picture_url} alt="Profile" />
          <div className='flex flex-col'>
            <h2 className="text-base font-bold text-gray-800 mb-2">{whatsappInfo?.number_info.verified_name}</h2>
            <p className="text-sm text-gray-600">{whatsappInfo?.number_info.display_phone_number}</p>
          </div>
        </div>
        <div className='mt-6 sm:mt-0'>
          <div className='flex flex-col'>
            <p className="text-sm font-bold text-gray-800 mb-2">Qualidade do número</p>
            <div className='flex justify-start sm:justify-end'>
              <span className={`flex items-center w-fit ${QualityRating(whatsappInfo?.number_info.quality_rating)} text-xs font-medium px-2.5 py-0.5 rounded-full`}>
                <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
                {QualityRatingText(whatsappInfo?.number_info.quality_rating)}
              </span>
            </div>
          </div>
        </div>
      </div> : <Skeleton variant="text" sx={{ fontSize: '8rem', marginTop: '-2.5rem', marginBottom: '-2rem' }} />}

      <div className="flex flex-row justify-end w-full mt-6">
        <div className="relative w-1/4">
          <input
            type="search"
            className={`w-full leading-none text-gray-900 pl-11 py-3 focus:outline-none focus:border-[#11111f] bg-white border rounded-lg border-gray-200 font-medium`}
            placeholder="Filtrar por telefone"
            value={!filteredPhone ? '' : filteredPhone}
            onChange={(e) => setFilteredPhone(e.target.value)}
          />
          <div className="absolute top-0 left-0 inline-flex items-center p-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
              strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </div>
        </div>

        {/* <div className="relative w-1/4 ml-4">
            <input
              type="search"
              className={`w-full leading-none text-gray-900 pl-11 py-3 focus:outline-none focus:border-[#11111f] bg-white border rounded-lg border-gray-200 font-medium`}
              placeholder="Filtrar por nome"
              value={!filteredName ? '' : filteredName}
              onChange={(e) => setFilteredName(e.target.value)}
            />
            <div className="absolute top-0 left-0 inline-flex items-center p-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                strokeLinejoin="round">
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </div>
          </div> */}

        <div className="relative w-1/4 ml-4">
          <Datepicker
            i18n={"pt-br"}
            inputClassName="w-full leading-none text-gray-900 py-3 focus:outline-none focus:border-[#11111f] bg-white border rounded-lg border-gray-200 font-normal cursor-pointer"
            primaryColor={"indigo"}
            placeholder={"Filtrar por data"}
            showShortcuts={true}
            useRange={false}
            displayFormat={"DD/MM/YYYY"}
            readOnly={true}
            value={filteredDate}
            onChange={handleFilteredDateChange}
            configs={{
              shortcuts: {
                today: "Hoje",
                yesterday: "Ontem",
                past: period => `Últimos ${period} dias`,
                currentMonth: "Este mês",
                pastMonth: "Último mês"
              },
            }}
          />
        </div>
      </div>

      <div className="mt-2 lg:flex lg:flex-row w-full">
        {filteredDate.endDate ? contacts?.length !== 0 ?
          <>
            <ListContacts contacts={contacts} filteredDate={filteredDate} currentContact={currentContact} isLoading={isListContactsLoading} getContact={getOneContact} isToday={isToday(formatStandardDate(filteredDate.startDate)) && isToday(formatStandardDate(filteredDate.endDate))} />
            <MessageDetails currentContact={currentContact} contactMessages={contactMessages} isLoading={isMessageDetailsLoading} />
          </>
          :
          <div className="flex items-center justify-center bg-white border rounded-t-lg border-r w-full h-[36.5rem]">
            <div className="text-center">
              <MessageOutlinedIcon fontSize="large" className="mx-auto" />
              {isToday(formatStandardDate(filteredDate.startDate)) && isToday(formatStandardDate(filteredDate.endDate)) ?
                <>
                  <h1 className="mt-2 text-xl font-semibold text-gray-800">Nenhuma conversa iniciada hoje</h1>
                  <p className="mt-2 text-base text-gray-500">Não encontramos nenhuma conversa iniciada no dia de hoje. Tente filtrar por um outro período ou data!</p>
                </>
                :
                <>
                  <h1 className="mt-2 text-xl font-semibold text-gray-800">Nenhuma conversa encontrada &#58;&#40;</h1>
                  <p className="mt-2 text-base text-gray-500">Não encontramos nenhuma conversa com os filtros utilizados. Tente filtrar por um outro período, data ou número!</p>
                </>
              }
            </div>
          </div>
          :
          <div className="flex items-center justify-center bg-white border rounded-t-lg border-r w-full h-[36.5rem]">
            <div className="text-center">
              <MessageOutlinedIcon fontSize="large" className="mx-auto" />
              <h1 className="mt-2 text-xl font-semibold text-gray-800">Nenhuma data selecionada</h1>
              <p className="mt-2 text-base text-gray-500">Selecione uma data para saber o que seus clientes falaram naquela data ou período específico! &#58;&#41;</p>
            </div>
          </div>
        }
      </div>


      <div className="px-4 py-5 border border-1 bg-white border-t-transparent rounded-b-lg">
        {!isListContactsLoading ?
          <>
            {contacts?.length !== 0 ?
              <div className="w-full flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="hidden sm:contents">
                  <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
                </div>

                <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
              </div>
              : null}
          </>
          : <Skeleton variant="text" sx={{ fontSize: '2rem', marginY: '-0.5rem' }} />
        }
      </div>
    </div>
  );
}

export default ListConversations;
