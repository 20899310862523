import { LoadingDots } from "../../components/LoadingDots";

interface LoadingProp {
  height: string
}

const Loading = ({ height }: LoadingProp) => {
  return (
    <div className={`${height} flex items-center justify-center p-6`}>
      <LoadingDots color="black" dotStyle="large" />
    </div>
  )
}

export default Loading;