import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles/index.css';

import App from './App';

import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Auth0Provider } from '@auth0/auth0-react';

import { AppDrawer } from './components';
import { AdminProvider } from './contexts/adminContext';
import { AreaProvider } from './contexts/areaContext';
import { AuthProvider } from './contexts/authContext';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);


Sentry.init({
  dsn: "https://470ce94e9a8b380e605315272a9c9f12@o4505076839284736.ingest.sentry.io/4505754519535616",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://painel.arkspace.ai"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  enabled: window.REACT_APP_ENV === 'prod',
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={window.REACT_APP_AUTH0_DOMAIN!}
      clientId={window.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://chatspace-backoffice.us.auth0.com/api/v2/",

      }}
    >
      <BrowserRouter>
        <AdminProvider>
          <AuthProvider>
            <AreaProvider>
              <AppDrawer>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                  transition={Slide}
                />
                <App />
              </AppDrawer>
            </AreaProvider>
          </AuthProvider>
        </AdminProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
