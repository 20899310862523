import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

interface FilesI {
  name: string;
  path: string;
}

interface SidebarProps {
  structure: {
    path: string;
    name: string;
    files: FilesI[];
  }[];
}

const Sidebar: React.FC<SidebarProps> = ({ structure }) => {
  const { folderPath } = useParams<{ folderPath: string; filePath: string }>();

  const findFolder = useCallback(() => (
    folderPath ? structure.find(folder => folder.path === folderPath) ?? null : null
  ), [folderPath, structure]);

  const [folder, setFolder] = useState(findFolder());

  useEffect(() => {
    setFolder(findFolder());
  }, [folderPath, findFolder]);

  return (
    <div className="grid grid-cols-1 xl:gap-4 mt-4 h-fit">
      <div className="bg-white shadow rounded-lg py-6 pl-5 pr-10">
        <aside>
          <ul>
            <li key={folder?.path}>
              <div className='flex flex-row items-center'>
                <FolderOpenOutlinedIcon fontSize='small' sx={{ color: "gray", marginRight: "0.3rem" }} /> {folder?.name}
              </div>
              <ul>
                {folder?.files.map((file, index) => (
                  <li key={file.name} className={`ml-4 ${index === 0 ? 'mt-1' : 'mt-0.5'}`}>
                    <div className='flex flex-row items-center'>
                      <ArticleOutlinedIcon fontSize='small' sx={{ color: "gray", marginRight: "0.3rem" }} /> <Link to={`/help/content/${folder.path}/${file.path.replace('.md', '')}`}>{file.name}</Link>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
