import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import EditNoteIcon from '@mui/icons-material/EditNote';

import { useAuth } from "../../../../contexts/authContext";

import { TaskInterface, PipelineInterface } from "../../../../utils/types";

interface ListTasksInterface {
  pipeline?: PipelineInterface
}

const ListTasks = ({ pipeline }: ListTasksInterface) => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [tasks, setTasks] = useState<TaskInterface[]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  useEffect(() => {
    if (!tasks) getTasks();
  });

  const getTasks = async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task?pipeline_id=${pipeline?.id}&offset=${offset}&limit=10`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setTasks(resJson.tasks);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getTasks((value - 1) * 10);
  };

  return (
    <div className="my-4 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 flex flex-col justify-between items-start">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Tasks</h3>
            <span className="text-base font-normal text-gray-500">Listagem com as tasks que pertencem a essa pipeline</span>
          </div>

          <Link to="/tasks/create" state={{ pipeline: pipeline, client_id: pipeline?.client.id }} className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
            Cadastrar task
            <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
            </svg>
          </Link>

          <div className="flex flex-col mt-4">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nome
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Prompt template id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Collection
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Habilitado
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Versão
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {tasks?.length !== 0 ? tasks?.map((task, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {task.id}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {task.name}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {task.prompt_template_id}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            <span className="font-semibold">{task?.collection?.name ? task.collection.name : ''}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                            {task.enabled ? 'Sim' : 'Não'}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm text-center">
                            V{task.version}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/tasks/${task.id}`} state={{ task: task, pipeline: pipeline, client_id: pipeline?.client.id }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                              <EditNoteIcon fontSize='medium' />
                            </Link>
                          </td>
                        </tr>
                      )) : <tr>
                        <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                          <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                          <p className="text-gray-700 font-medium text-lg text-center">Nenhuma task cadastrada.</p>
                          <p className="text-gray-500 text-center">Cadastre uma task para que seus dados apareçam aqui.</p>
                          <Link to="/tasks/create" className="flex items-center w-fit px-4 py-1.5 border border-gray-400 mt-4 text-gray-600 rounded text-sm hover:bg-[#11111f] hover:text-white mx-auto" state={{ pipeline: pipeline, client_id: pipeline?.client.id }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                              stroke="currentColor" className="w-6 h-6  mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Cadatrar task
                          </Link>
                        </td>
                      </tr>}
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div>
          </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

        </div>
      </div>
    </div>
  )
}

export default ListTasks;
