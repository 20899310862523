import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.removeItem("currentArea");
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  return (
    <button className="flex m-auto sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] font-medium rounded-md text-sm px-3 py-2 text-center items-center" onClick={handleLogout}>
      <LogoutIcon fontSize="small" />
      <span className="ml-2">Sair</span>
    </button>
  );
};

export default LogoutButton;
