import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

interface CustomListboxProps<T extends { name?: string }> {
  list: T[] | null,
  selected: T | null;
  setSelected: React.Dispatch<React.SetStateAction<T | null>>;
  placeholder: string;
  className?: string;
}

const CustomListbox = <T extends { name?: string }>({ list, selected, setSelected, placeholder, className }: CustomListboxProps<T>) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className={`${className} relative mt-1 w-full rounded border text-gray-900 border-gray-200 py-1 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 shadow`}>
        <Listbox.Button className="w-full border-none py-2 pl-3 text-sm leading-5 text-gray-900 focus:ring-0 focus:outline-none text-left">
          <span className={`block truncate ${selected?.name ? '' : 'text-gray-500'}`}>{selected?.name ? selected?.name : placeholder}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-2.5 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {list?.map((item, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'text-white bg-[#2c2c50]' : 'text-[#2c2c50]'
                  }`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {item?.name}
                    </span>
                    {selected ? (
                      <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-[#2c2c50]'}`}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default CustomListbox;