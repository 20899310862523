import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

const ActiveMessages = () => {
  return (
    <div className="pt-6 pb-2 px-4">

      <div className="mb-4 flex items-center justify-between py-4">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-1">Disparo ativo</h2>
          <span className="text-base font-normal text-gray-500">Gerencie seus templates de mensagem e crie campanhas para enviar mensagens ativas!</span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:gap-4 my-4">
        <Card sx={{ maxWidth: 'full' }}>
          <Link to="/active-messages/message-template">
            <CardActionArea>
              <img
                style={{ width: '100%', height: '230px' }}
                src="/assets/platform/active-messages/create-phone-numbers.svg"
                alt="create-message-template"
              />
              <CardContent sx={{ padding: '1.4rem' }}>
                <h3 className="text-xl font-bold text-gray-900 mb-3">
                  Mensagem template
                </h3>
                <span className="text-base font-normal text-gray-500 mt-3">
                  Gerencie seus templates de mensagem para que você consiga enviar mensagens
                  ativas para sua lista de transmissão!
                </span>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>

        <Card className="mt-4 lg:ml-4 lg:mt-0" sx={{ maxWidth: 'full' }}>
          <Link to="/active-messages/message-broadcast">
            <CardActionArea>
              <img
                style={{ width: '100%', height: '230px' }}
                src="/assets/platform/active-messages/message-broadcast.svg"
                alt="broadcast-message"
              />
              <CardContent sx={{ padding: '1.4rem' }}>
                <h3 className="text-xl font-bold text-gray-900 mb-3">
                  Enviar mensagem
                </h3>
                <span className="text-base font-normal text-gray-500">
                  Gerencie suas campanhas, envie mensagens únicas ou em massa, a partir
                  das mensagens template!
                </span>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </div>
    </div>
  );
}

export default ActiveMessages;
