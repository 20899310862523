export const formatPhoneNumber = (input: string) => {
  let numbers = input.replace(/\D/g, '');
  let formatted = '';

  if (numbers.includes('whatsapp:')) {
    numbers = numbers.replace('whatsapp:', '');
  }

  if (numbers.includes('55')) {
    numbers = numbers.replace('55', '');
  } else if (numbers.includes('+55')) {
    numbers = numbers.replace('+55', '');
  }

  if (numbers.length > 0) {
    formatted = `(${numbers.substring(0, 2)}`; // Area code
  }
  if (numbers.length >= 3) {
    const remaining = numbers.substring(2);
    if (remaining.length <= 8) {
      // Format: (99) 9999-9999
      formatted += `) ${remaining.substring(0, 4)}`;
      if (remaining.length > 4) {
        formatted += `-${remaining.substring(4)}`;
      }
    } else {
      // Format: (99) 9 9999-9999
      formatted += `) ${remaining.substring(0, 1)} ${remaining.substring(1, 5)}`;
      if (remaining.length > 5) {
        formatted += `-${remaining.substring(5)}`;
      }
    }
  }
  return formatted;
};
