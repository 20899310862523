import { useEffect, useState, Fragment } from "react";
import { UUID } from "crypto";

import Pagination from '@mui/material/Pagination';
import Skeleton from "@mui/material/Skeleton";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Dialog, Transition } from '@headlessui/react';

import { useAuth } from "../../../../contexts/authContext";

import { TaskInterface } from "../../../../utils/types";

interface ListOldVersionsTasksInterface {
  prompt_template_id?: UUID
}

const ListOldVersionsTasks = ({ prompt_template_id }: ListOldVersionsTasksInterface) => {
  const { token } = useAuth();

  const [count, setCount] = useState<number>(0);
  const [oldTasks, setOldTasks] = useState<TaskInterface[]>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<TaskInterface | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  useEffect(() => {
    if (!oldTasks) getOldVersionsTasks();
  });

  const getOldVersionsTasks = async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/task/versions/${prompt_template_id}?offset=${offset}&limit=10`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        setOldTasks(resJson.tasks);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getOldVersionsTasks((value - 1) * 10);
  };

  const renderModal = () => {
    return (
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto lg:mt-20 ml-16">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full transform overflow-scroll rounded-2xl bg-white p-4 sm:p-6 xl:p-8 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {selectedTask?.name}
                  </Dialog.Title>

                  <div className="mt-8">
                    <p className="text-md text-bold">Prompt:</p>
                    <span className="text-md text-gray-500"><pre>{selectedTask?.prompt}</pre></span>
                  </div>

                  <div className="mt-8">
                    <p className="text-md text-bold">Function definition:</p>
                    <span className="text-md text-gray-500">{selectedTask?.function_definition}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Modelo:</span><span className="text-md text-gray-500"> {selectedTask?.llm_model.name}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Número de documentos:</span><span className="text-md text-gray-500"> {selectedTask?.k_contexts}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Habilitado:</span><span className="text-md text-gray-500"> {selectedTask?.enabled ? 'Sim' : 'Não'}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Versão:</span><span className="text-md text-gray-500"> {selectedTask?.version}</span>
                  </div>

                  <div className="mt-8">
                    <span className="text-md text-bold">Collection:</span><span className="text-md text-gray-500"> {selectedTask?.collection?.name}</span>
                  </div>

                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-[#11111f] px-4 py-2 text-sm font-medium text-white hover:bg-[#292c31] focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      Fechar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return (
    <div className="my-4 px-4">
      <div className="grid grid-cols-1 xl:gap-4 my-4">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Versões</h3>
              <span className="text-base font-normal text-gray-500">Listagem com as versões antigas dessa task</span>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {renderModal()}
                  {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Id
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nome
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Collection
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Habilitado
                        </th>
                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Versão
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {oldTasks?.length !== 0 ? oldTasks?.map((oldTask, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {oldTask.id}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            {oldTask.name}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal">
                            <span className="font-semibold">{oldTask?.collection?.name ? oldTask.collection.name : ''}</span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-center">
                            {oldTask.enabled ? 'Sim' : 'Não'}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm text-center">
                            V{oldTask.version}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <button className="px-2.5 p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer" onClick={() => { setSelectedTask(oldTask); setShowModal(true); }}>
                              <VisibilityOutlinedIcon fontSize='small' />
                            </button>
                          </td>
                        </tr>
                      )) : <tr>
                        <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                          <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690257804/jjqw2hfv0t6karxdeq1s.svg" alt="empty states" />
                          <p className="text-gray-700 font-medium text-lg text-center">Nenhuma versão antiga encontrada.</p>
                          <p className="text-gray-500 text-center">Versões antigas de uma task poderão ser visualizadas por aqui!</p>
                        </td>
                      </tr>}
                    </tbody>
                  </table> :
                    <div className="-mt-4">
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={340} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {!isLoading ? oldTasks?.length !== 0 ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            <div className="flex justify-center sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div>
          </div> : null : <Skeleton variant="text" sx={{ fontSize: '2rem' }} /> }

        </div>
      </div>
    </div>
  )
}

export default ListOldVersionsTasks;
