import { useEffect, useState } from 'react';

import 'dayjs/locale/pt-br';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface AudienceInterface {
  id: string,
  name: string
}

interface SendStepI {
  selectedAudience: AudienceInterface,
  selectedFile?: File,
  phoneNumber?: string;
  templateName: string | undefined;
  setActiveStep: Function;
  campaignName: string;
  setCampaignName: Function;
  scheduleDateTime: string | null;
  setScheduleDateTime: Function;
}

const SendStep = ({ selectedAudience, phoneNumber, selectedFile, templateName, setActiveStep, campaignName, setCampaignName, scheduleDateTime, setScheduleDateTime }: SendStepI) => {
  const [radioValue, setRadioValue] = useState('now');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (radioValue === 'now') setScheduleDateTime(null);
  });

  return (
    <>
      <div className="mb-4 md:flex items-center justify-between">
        <div className="w-full flex flex-col">
          <h3 className="text-xl font-bold text-gray-900 mb-2">Enviar</h3>
          <span className="text-base font-normal text-gray-500">Verifique se está tudo certo com suas configurações antes de realizar o envio. Caso deseja alterar alguma informação, volte os passos e realize as alterações necessárias.</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row my-4">
        <div className="align-middle inline-block min-w-full">

          <div className={`mt-2`}>
            <Alert severity={'info'}>A hora de envio seguirá o fuso horário de Brasília (GMT-3). O início do envio pode variar devido ao processamento das mensagens.</Alert>
          </div>

          <div className="lg:flex">

            <div className="w-full lg:w-1/2 my-2">
              <div className="bg-gray-100 p-8 mt-4 rounded-md">
                <div className="grid grid-cols-5">
                  <div className="flex flex-row items-center col-span-4">
                    <CheckCircleIcon fontSize='small' color='success' />
                    <div className="flex flex-col items-start ml-8 truncate">
                      <span className="text-sm font-semibold">Público: </span>
                      <span className="text-sm font-base mt-2">{selectedAudience.id === "1" ? selectedFile?.name : phoneNumber}</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-end col-span-1">
                    <div className="p-3 hover:bg-gray-300 border border-gray-300 rounded-md cursor-pointer" onClick={(_) => setActiveStep(0)}>
                      <EditNoteIcon fontSize='medium' />
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <Divider />
                </div>

                <div className="grid grid-cols-5">
                  <div className="flex flex-row items-center col-span-4">
                    <CheckCircleIcon fontSize='small' color='success' />
                    <div className="flex flex-col items-start ml-8 truncate">
                      <span className="text-sm font-semibold">Conteúdo: </span>
                      <span className="text-sm font-base mt-2">{templateName} - Português (BR)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-end col-span-1">
                    <div className="p-3 hover:bg-gray-300 border border-gray-300 rounded-md cursor-pointer" onClick={(_) => setActiveStep(1)}>
                      <EditNoteIcon fontSize='medium' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 flex items-center my-6 lg:ml-6">
              <div className="w-full md:flex flex-col">
                <div className="w-full flex flex-col">
                  <span className="text-xl font-bold text-gray-900">Dê um nome para a sua campanha!</span>
                  <span className="text-base font-normal text-gray-500">Escolha um nome claro e objetivo que resuma o propósito da campanha.</span>
                </div>
                <div className="w-full flex flex-col mt-4">
                  <input
                    className={`text-base leading-none text-gray-900 p-3 mt-4 shadow focus:oultine-none focus:border-blue-700 bg-white border-gray-200 border rounded`}
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    placeholder={`Nome da campanha`}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className='flex flex-col lg:flex-row lg:mt-4 lg:my-2'>
            <div className="w-full flex items-center">
              <div className="lg:mb-4 md:flex flex-col">
                <div className="w-full flex flex-col">
                  <span className="text-xl font-bold text-gray-900">Quando você quer enviar?</span>
                  <span className="text-base font-normal text-gray-500">Envie a campanha agora ou agende para depois.</span>
                </div>
                <div className="w-full flex flex-col md:flex-row mt-4">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="now" control={<Radio sx={{ color: "#2c2c50", '&.Mui-checked': { color: "#2c2c50" } }} />} label="Enviar agora" sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Lexend' } }} />
                      <FormControlLabel value="schedule" control={<Radio sx={{ color: "#2c2c50", '&.Mui-checked': { color: "#2c2c50" } }} />} label="Agendar envio" sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Lexend' } }} />
                    </RadioGroup>
                  </FormControl>

                  {radioValue !== 'now' ?
                    <div className="w-full md:w-1/2 mt-3 md:ml-10">
                      <div className='flex flex-col'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                          <DateTimePicker className='justify-center focus:outline-none' value={scheduleDateTime} onChange={(newValue) => setScheduleDateTime(newValue!)} disableOpenPicker={true} />
                        </LocalizationProvider>
                      </div>
                      <div className="pt-2">
                        <span className="text-xs font-normal text-gray-500 mt-2">A data de agendamento deve ser futura e dentro do limite de uma semana.</span>
                      </div>
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default SendStep;
