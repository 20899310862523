import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Alert, Breadcrumbs, Pagination, Skeleton, Typography } from "@mui/material";

import { useAuth } from "../../../../contexts/authContext";
import CustomListbox from "../../../../components/CustomListbox";

import { CampaignMessagesInterface } from "../../../../utils/types";
import { messageStatus } from "../../../../utils/messageStatus";
import { formatPhoneNumber } from "../../../../utils/formatPhonenumber";
import { formatDateTime } from "../../../../utils/formatDate";

interface StatusInterface {
  id: string,
  name: string,
  value: string
}

const Visualize = () => {
  const { token } = useAuth();

  const location = useLocation();
  const { campaign } = location.state;

  const [messages, setMessages] = useState<CampaignMessagesInterface[]>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count);

  const [filteredPhonenumber, setFilteredPhonenumber] = useState<string | null>(null);

  const [statusList] = useState<StatusInterface[]>([{ id: '1', name: 'Processando', value: 'processing' }, { id: '2', name: 'Enviado', value: 'sent' }, { id: '2', name: 'Recebido', value: 'delivered' }, { id: '2', name: 'Lido', value: 'read' }, { id: '2', name: 'Falhado', value: 'failed' }]);
  const [selectedStatus, setSelectedStatus] = useState<StatusInterface | null>(null);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages">Disparo ativo</Link>,
    <Link className="hover:underline" key="3" color="inherit" to="/active-messages/message-broadcast">Campanhas de mensagens ativas</Link>,
    <Typography key="3" color="text.primary">{`Visualizar campanha - ${campaign.name}`}</Typography>
  ];

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getMessages((value - 1) * 10);
  };

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/campaign/${campaign.id}/messages?&offset=${offset}&limit=10`;
    if (selectedStatus) url = url + `&status=${selectedStatus?.value}`;
    if (filteredPhonenumber) url = url + `&phone_number=${filteredPhonenumber}`;
    return url;
  }, [campaign.id, selectedStatus, filteredPhonenumber]);

  const getMessages = useCallback(async (offset = 0) => {
    if (token) {
      try {
        setIsLoading(true);

        const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();
        const resMessages = resJson.messages;

        setMessages(resMessages);
        setCount(resJson.count);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="py-4 md:flex items-center justify-between">
          <div className="w-full flex flex-col">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Campanha - {campaign.name}</h2>
            <span className="text-base font-normal text-gray-500">Visualize com mais detalhes a mensagens envidas na sua campanha.</span>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">

          <div className="grid grid-cols-1 md:grid-cols-3 xl:gap-4">
            <div className="bg-white shadow rounded-lg my-2 md:my-0 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Mensagens totais</h3>
                  <h3 className="text-base lg:text-xl font-normal text-gray-900 mt-3">{campaign.audience_count}</h3>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg md:ml-3 my-2 md:my-0 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Envio agendado</h3>
                  <h3 className="text-base lg:text-xl font-normal text-gray-900 mt-3">{campaign.schedule ? 'Sim' : 'Não'}</h3>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg md:ml-3 my-2 md:my-0 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Hora início</h3>
                  <h3 className="text-base lg:text-xl font-normal text-gray-900 mt-3">{campaign.schedule ? formatDateTime(campaign.schedule) : formatDateTime(campaign.created_at)}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:gap-4">
            <div className="bg-white shadow rounded-lg p-2 md:mr-3 my-3 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base font-bold text-gray-900">Enviadas</h3>
                  <h3 className="text-base font-normal text-gray-900 mt-3">{campaign.status_counts.sent_count}</h3>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-2 md:ml-3 lg:mx-3 my-3 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base font-bold text-gray-900">Recebidas</h3>
                  <h3 className="text-base font-normal text-gray-900 mt-3">{campaign.status_counts.delivered_count}</h3>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-2 md:mr-3 lg:mx-3 my-3 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base font-bold text-gray-900">Lidas</h3>
                  <h3 className="text-base font-normal text-gray-900 mt-3">{campaign.status_counts.read_count}</h3>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-2 md:ml-3 my-3 p-6 xl:p-8">
              <div className="md:flex items-center justify-between">
                <div className="w-full flex flex-col items-center">
                  <h3 className="text-base font-bold text-gray-900">Falhadas</h3>
                  <h3 className="text-base font-normal text-gray-900 mt-3">{campaign.status_counts.failed_count}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 mt-4 lg:mt-0">
            <div className="md:flex items-center justify-between">
              <div className="w-full flex flex-col">
                <div className="mb-4 flex flex-col justify-between items-start">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">Mensagens enviadas</h3>
                  <span className="text-base font-normal text-gray-500">Veja abaixo as mensagens que foram enviadas e seus respectivos status!</span>
                </div>

                <div className="mt-4">
                  <Alert severity={'info'}>Caso o usuário não tenha o "visto por último" habilitado, o WhatsApp <b>não atualiza o status da mensagem para "Lido"</b>.</Alert>
                </div>

                <div className="mt-2 flex flex-col justify-between items-center md:flex-row">
                  <div className="flex flex-row w-full md:w-1/2 md:pr-4 lg:w-1/3">
                    <div className="relative w-full">
                      <input
                        type="search"
                        className={`w-full leading-none text-gray-900 pl-11 py-3 shadow focus:outline-none focus:border-[#11111f] bg-white border rounded border-gray-200 font-medium`}
                        placeholder="Filtrar por telefone..."
                        value={!filteredPhonenumber ? '' : filteredPhonenumber}
                        onChange={(e) => setFilteredPhonenumber(e.target.value)}
                      />
                      <div className="absolute top-0 left-0 inline-flex items-center p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                          strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                          strokeLinejoin="round">
                          <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                          <circle cx="10" cy="10" r="7" />
                          <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-end w-full mt-4 md:w-1/2 md:mt-0">
                    <div className="w-full md:w-4/12 z-10">
                      <CustomListbox list={statusList} selected={selectedStatus} setSelected={setSelectedStatus} placeholder="Status" />
                    </div>

                    {selectedStatus ? <div className="ml-2 mt-2 flex-shrink-0">
                      <button onClick={() => { setSelectedStatus(null); }} className="text-sm font-medium text-[#292c31] hover:bg-gray-100 rounded-lg p-2">Limpar</button>
                    </div> : null}
                  </div>
                </div>

                <div className="flex flex-col mt-4 mb-8">
                  <div className="overflow-x-auto rounded-lg">
                    <div className="align-middle inline-block min-w-full">
                      <div className="shadow overflow-hidden sm:rounded-lg">
                        {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                              </th>
                              <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Mensagem
                              </th>
                              <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Número
                              </th>
                              <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Hora envio
                              </th>
                              <th scope="col" className="tracking-wider" />
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {messages?.length !== 0 ? messages?.map((message: any, index: any) => (
                              <tr key={index} className="hover:bg-gray-100">
                                <td className="p-4 text-sm font-normal text-gray-900">
                                  <div className="w-[200px] truncate">
                                    <span className="font-semibold">{messageStatus(message)}</span>
                                  </div>
                                </td>
                                <td className="p-4 text-sm font-normal whitespace-pre-line">
                                  <div className="p-4 bg-gray-200 border border-gray-400 w-[500px]">
                                    {message.message_body}
                                  </div>
                                </td>
                                <td className="p-4 whitespace-nowrap text-sm text-gray-900">
                                  {formatPhoneNumber(message.attributes.to)}
                                </td>
                                <td className="p-4 whitespace-nowrap text-sm text-gray-900">
                                  {formatDateTime(message.created_at)}
                                </td>
                              </tr>
                            )) : <tr>
                              <td className="w-full text-center mx-auto pb-12" colSpan={4}>
                                <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                                <p className="text-gray-700 font-medium text-lg text-center">{campaign.audience_count === 1 ? 'Mensagem' : 'Mensagens'} ainda não {campaign.audience_count === 1 ? 'foi enviada.' : 'foram enviadas.'}</p>
                                <p className="text-gray-500 text-center">
                                  {campaign.schedule ? 'Os dados estarão disponíveis assim que o agendamento for satisfeito e as mensagens forem processadas' : 'Os dados estarão disponíveis assim que as mensagens forem processadas.'}<br />
                                </p>
                              </td>
                            </tr>
                            }
                          </tbody>
                        </table> :
                          <>
                            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                            <Skeleton variant="rounded" height={340} />
                          </>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
              {messages?.length !== 0 ? <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="hidden sm:contents">
                  <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
                </div>

                <Pagination count={Math.ceil(count / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
              </div> : null}
            </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

          </div>
        </div>
      </div>
    </>
  );
}

export default Visualize;
