import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Skeleton from "@mui/material/Skeleton";

import { useAuth } from "../../../contexts/authContext";

import { DocumentInterface, CollectionInterface, DocumentEmbeddingInterface } from "../../../utils/types";

import CustomCombobox from "../../../components/CustomCombobox";

interface ListDocumentsInterface {
  documents?: DocumentInterface[],
  getDocuments?: Function,
  documentsCount?: number,
  collection?: CollectionInterface,
  isLoading?: boolean
}

const FilterDocuments = ({ documents, collection }: ListDocumentsInterface) => {
  const { token } = useAuth();

  const [docEmbeddings, setDocEmbeddings] = useState<DocumentEmbeddingInterface[]>([])
  const [modifiedDocuments, setModifiedDocuments] = useState<DocumentInterface[]>([])

  const [filteredContent, setFilteredContent] = useState<string | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<DocumentInterface | null>(null);
  const [query, setQuery] = useState('');

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const [openCollapse, setOpenCollapse] = useState(false);

  const startIndex: number = (page - 1) * 10 + 1;
  const endIndex: number = Math.min(startIndex + 10 - 1, count!);

  const buildUrlWithFilters = useCallback(async (offset: number) => {
    let url = `${window.REACT_APP_API_ENDPOINT}/api/document-embedding/search/${collection?.id}?offset=${offset}&limit=10`;
    if (selectedDocument) url = url + `&document_id=${selectedDocument?.id}`;
    if (filteredContent) url = url + `&content=${filteredContent}`;
    return url;
  }, [selectedDocument, filteredContent, collection?.id]);

  const getDocEmbeddings = useCallback(async (offset = 0) => {
    if (token && openCollapse) {
      try {
        setFilterLoading(true);

        const res = await fetch(`${await buildUrlWithFilters(offset)}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'X-Key': `${window.REACT_APP_HEADER_SECRET}` }
        })

        const resJson = await res.json();
        setDocEmbeddings(resJson.documents_embeddings);
        setCount(resJson.count);
        setFilterLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [buildUrlWithFilters, token, openCollapse]);

  useEffect(() => {
    if ((filteredContent && filteredContent.trim() !== "") || selectedDocument) {
      setPage(1);
      getDocEmbeddings(0);
    }
  }, [filteredContent, selectedDocument, getDocEmbeddings]);

  useEffect(() => {
    if (!filteredContent) {
      setDocEmbeddings([]);
      setCount(0);
    }
  }, [filteredContent, selectedDocument, getDocEmbeddings]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getDocEmbeddings!((value - 1) * 10);
  };

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const removeUUID = (name: string): string => {
    // Regular expression to match UUID pattern
    const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-/;
    return name.replace(uuidPattern, '').trim(); // Remove UUID and trim spaces
  };

  useEffect(() => {
    if (documents) {
      const updatedDocuments = documents.map(doc => ({
        ...doc,
        name: removeUUID(doc.name),
      }));

      setModifiedDocuments(updatedDocuments);
    }
  }, [documents]);

  return (<div className="my-4 px-4">
    <div className="grid grid-cols-1 xl:gap-4 my-4">
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-xl font-bold text-gray-900">Filtrar conteúdo dos documentos</h3>
          </div>
          <div>
            <IconButton onClick={handleCollapse}>
              <ExpandMoreIcon className={openCollapse ? 'transform rotate-180' : ''} />
            </IconButton>
          </div>
        </div>
        <Collapse in={openCollapse}>
          <span className="text-base font-normal text-gray-500">Filtre e descubra em qual documento está um conteúdo. Ou filtre diretamente em algum documento específico.</span>

          <div className="mt-8 flex flex-col justify-between items-center md:flex-row">
            <div className="flex flex-row w-full md:w-1/2 md:pr-4">
              <div className="relative w-full">
                <input
                  type="search"
                  className={`w-full leading-none text-gray-900 pl-11 py-3 shadow focus:outline-none focus:border-[#11111f] bg-white border rounded border-gray-200 font-medium`}
                  placeholder="Pesquisar conteúdo..."
                  value={!filteredContent ? '' : filteredContent}
                  onChange={(e) => setFilteredContent(e.target.value)}
                />
                <div className="absolute top-0 left-0 inline-flex items-center p-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" viewBox="0 0 24 24"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                    strokeLinejoin="round">
                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-end w-full mt-4 md:w-1/2 md:mt-0">
              <div className="w-full md:w-full lg:w-1/2 -mt-1">
                <CustomCombobox entity="documento" list={modifiedDocuments} selected={selectedDocument} setSelected={setSelectedDocument} query={query} setQuery={setQuery} emptyState={false} />
              </div>

              {selectedDocument ? <div className="ml-2 mt-1 flex-shrink-0">
                <button onClick={() => { setSelectedDocument(null) }} className="text-sm font-medium text-[#292c31] hover:bg-gray-100 rounded-lg p-2">Limpar</button>
              </div> : null}
            </div>
          </div>

          <div className="flex flex-col mt-3">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {!filterLoading ? <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Documento
                        </th>
                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Conteúdo
                        </th>
                        <th scope="col" className="tracking-wider" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {docEmbeddings?.length !== 0 ? docEmbeddings?.map((embedding, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <Link to={`/documents/${embedding.document.id}`} state={{ collection: collection }} target="_blank" rel="noopener noreferrer" className="hover:text-[#2c2c50] hover:underline">{embedding.document.name}</Link>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500 truncate max-w-md">
                            {embedding.content}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            <Link to={`/embeddings/${embedding.id}`} state={{ embedding: embedding }} className="p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                              <EditNoteIcon fontSize='medium' />
                            </Link>
                          </td>
                        </tr>
                      )) : <tr>
                        <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                          <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                          <p className="text-gray-700 font-medium text-lg text-center">Nenhum documento encontrado.</p>
                          <p className="text-gray-500 text-center">Filtre por conteúdo para que as informações apareçam aqui.</p>
                        </td>
                      </tr>}
                    </tbody>
                  </table> :
                    <>
                      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                      <Skeleton variant="rounded" height={400} />
                    </>}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
            {docEmbeddings?.length !== 0 ? < div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div className="hidden sm:contents">
                <p className="text-sm text-gray-700">Mostrando de <span className="font-bold">{startIndex}</span> até <span className="font-bold">{endIndex}</span> de <span className="font-bold">{count}</span> resultados</p>
              </div>

              <Pagination count={Math.ceil(count! / 10)} variant="outlined" shape="rounded" page={page} onChange={handlePagination} />
            </div> : null}
          </div>
        </Collapse>

      </div>
    </div>
  </div>
  );
}

export default FilterDocuments;