import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import Sidebar from './Sidebar';
import structure from './structure.json';

const MarkdownContent: React.FC = () => {
  const navigate = useNavigate();

  const [content, setContent] = useState('');
  const { folderPath, filePath } = useParams<{ folderPath: string; filePath: string }>();

  useEffect(() => {
    const fullPath = `/contents/${folderPath}/${filePath}.md`;

    fetch(fullPath)
      .then((response) => response.text())
      .then((text) => {
        if (text.startsWith('<!DOCTYPE html>')) { navigate('/help') } else { setContent(text) }
      })
      .catch((error) => console.error('Error fetching markdown:', error));
  }, [folderPath, filePath, navigate]);

  return (
    <div className='flex flex-col lg:flex-row'>
      <Sidebar structure={structure} />

      <div className='flex-1'>
        <div className="lg:pl-4">
          <div className="grid grid-cols-1 xl:gap-4 my-4">
            <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
              <ReactMarkdown className='max-w-full prose'>{content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkdownContent;
