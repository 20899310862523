import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { useAuth } from "../../../../contexts/authContext";

import Loading from "../../../auth/Loading";

import { MessageTemplateInterface } from "../../../../utils/types";
import { showApprovalStatus } from "../../../../utils/showApprovalStatus";
import Skeleton from "@mui/material/Skeleton";

interface MetaObjectI {
  first_page_url: string;
  next_page_url: string;
  previous_page_url: string;
}

const ListMessageTemplates = () => {
  const { token, idTokenClaims } = useAuth();

  const [messageTemplates, setMessageTemplates] = useState<MessageTemplateInterface[]>();
  const [page, setPage] = useState<number>(0);
  const [meta, setMeta] = useState<MetaObjectI>();
  const [pageToken, setPageToken] = useState<string>('');
  const [pageTokens, setPageTokens] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const breadcrumbs = [
    <Link className="hover:underline" key="1" color="inherit" to="/">Home</Link>,
    <Link className="hover:underline" key="2" color="inherit" to="/active-messages">Disparo ativo</Link>,
    <Typography key="3" color="text.primary">Mensagem template</Typography>
  ];

  useEffect(() => {
    if (!messageTemplates) getMessageTemplates();
  });

  const getMessageTemplates = async (page = 0) => {
    if (token) {
      const currentPageToken = getPageToken(meta!, page);

      try {
        setIsLoading(true);

        const res = await fetch(`${window.REACT_APP_API_ENDPOINT}/api/client-message-templates/${idTokenClaims?.client_id}?page=${page}&pageToken=${currentPageToken}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })

        const resJson = await res.json();

        setMessageTemplates(resJson.contents);
        setMeta(resJson.meta);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPageToken = (meta: MetaObjectI, currentPage: number) => {
    if (currentPage > page) {
      const nextPageToken = extractPageToken(meta?.next_page_url);
      if (nextPageToken) {
        setPageTokens([...pageTokens, pageToken]);
        setPageToken(nextPageToken);
        return nextPageToken;
      }
    }

    if (currentPage < page && pageTokens.length > 0) {
      const previousPageToken = pageTokens.pop() || '';
      setPageTokens([...pageTokens]);
      setPageToken(previousPageToken);
      return previousPageToken;
    }

    return pageToken;
  };

  const extractPageToken = (url: string) => {
    if (!url) return null;
    const urlObj = new URL(url);
    return new URLSearchParams(urlObj.search).get('PageToken');
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getMessageTemplates(value);
  };

  return (
    <>
      <div className="pt-6 px-4">

        <div className="pt-4 pb-4">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <div className="grid grid-cols-1 xl:gap-4 my-4">
          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div className="mb-4 flex flex-col justify-between items-start">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Templates de mensagens WhatsApp</h3>
              <span className="text-base font-normal text-gray-500">Para começar uma conversa com usuários do WhatsApp sua mensagem outbound deve seguir uma mensagem template aprovada.</span>
            </div>
            <Link to="/active-messages/message-template/create" className="flex w-fit sm:inline-flex text-white bg-[#11111f] hover:bg-[#292c31] focus:ring-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center items-center">
              Cadastrar mensagem template
              <svg className="flex-shrink-0 -ml-1 ml-2 h-3.5 w-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
              </svg>
            </Link>
            <div className="flex flex-col mt-4 mb-8">
              <div className="overflow-x-auto rounded-lg">
                <div className="align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden sm:rounded-lg">
                    {!isLoading ? <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nome do template
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Mensagem
                          </th>
                          <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="tracking-wider" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {messageTemplates?.length !== 0 ? messageTemplates?.map((messageTemplate, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="p-4 text-sm font-normal text-gray-900">
                              <div className="w-[200px] truncate">
                                <span className="font-semibold">{messageTemplate.friendly_name}</span>
                              </div>
                            </td>
                            <td className="p-4 text-sm font-normal whitespace-pre-line">
                              <div className="p-4 bg-gray-200 border border-gray-400 w-[500px]">
                                {messageTemplate.types["twilio/text"].body}
                              </div>
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm text-gray-900">
                              {showApprovalStatus(messageTemplate!)}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-center">
                              <Link to={`/active-messages/message-template/${messageTemplate.sid}`} state={{ messageTemplate: messageTemplate }} className="px-2.5 p-2 hover:bg-gray-200 border border-gray-200 rounded-md cursor-pointer">
                                <VisibilityOutlinedIcon fontSize='small' />
                              </Link>
                            </td>
                          </tr>
                        )) : <tr>
                          <td className="w-full text-center mx-auto pb-12" colSpan={5}>
                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="empty states" />
                            <p className="text-gray-700 font-medium text-lg text-center">Nenhuma mensagem template cadastrada.</p>
                            <p className="text-gray-500 text-center">Cadastre uma mensagem template e utilize-o para enviar mensagens para seus contatos!</p>
                            <Link to="/active-messages/message-template/create" className="flex items-center w-fit px-4 py-1.5 border border-gray-400 mt-4 text-gray-600 rounded text-sm hover:bg-[#11111f] hover:text-white mx-auto">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-6 h-6  mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              Cadastrar mensagem template
                            </Link>
                          </td>
                        </tr>}
                      </tbody>
                    </table> :
                      <>
                        <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                        <Skeleton variant="rounded" height={340} />
                      </>}
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? <div className="flex flex-1 justify-end border-t border-gray-200 bg-white pt-8 pb-3 sm:px-6">
              <div className="flex justify-end sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="hidden sm:contents">
                  <p className="text-sm text-gray-700">Mostrando <span className="font-bold">{messageTemplates?.length}</span> resultados</p>
                </div>

                <Pagination
                  page={page}
                  onChange={handlePagination}
                  renderItem={(item) => {
                    if (item.type === 'next') {
                      return (
                        <PaginationItem
                          {...item}
                          disabled={!meta?.next_page_url}
                        />
                      );
                    }
                    if (item.type === 'previous') {
                      const enablePrevious = pageTokens.length > 0;
                      return (
                        <PaginationItem
                          {...item}
                          disabled={!enablePrevious}
                        />
                      );
                    }
                    return null; // Hide page numbers
                  }}
                />
              </div>
            </div> : <Skeleton variant="text" sx={{ fontSize: '2rem' }} />}

          </div>
        </div>
      </div>
    </>
  )
}

export default ListMessageTemplates;
